import { Button, FlexboxGrid,Col } from 'rsuite';
import styled from 'styled-components';

export const ButtonAdd = styled(Button)`
  width: 100%;
  background-color: ${props => props.theme.colors.red};
  padding: 10px 20px;
  border-radius: 100px;

  span:first-child {
    color: ${props => props.theme.colors.white};
    padding-right: 10px;
    font-weight: bolder;
  }
`;

export const FollowersContainer = styled.div`
  @media ${props => props.theme.breakpoints.maxMedium} {
    margin-top: 20px;
  }
`;

export const PostsContainer = styled.div`
  max-height: 800px;
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 2px solid ${props => props.theme.colors.grey};
  padding: 15px 0;
  padding-left: 15px;

  @media ${props => props.theme.breakpoints.maxMedium} {
    display: none;
  }

  .image-header {
    margin-left: 55px;
  }

  .text-header {
    margin-left: 105px;
    flex-grow: 1;
  }

  .posted-header {
    margin-right: 225px;

    @media screen and (max-width: 1170px) {
      margin-right: 215px;
    }
  }
`;

export const Test = styled.div`
  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const HeaderFlexbox = styled(FlexboxGrid)`
  background-color: ${props => props.theme.colors.white};
  border-bottom: 2px solid ${props => props.theme.colors.grey};
  padding: 15px 0;
  padding-left: 15px;
`;
export const ResponsiveCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @media (min-width: 576px) {
    flex-direction: row;
  }
`;