import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  StepHeaderContainer,
  CreateAccountTitle,
  StepIndicator,
  StepCircle,
  MobileImage,
  MobileImageContent,
  CancelButton,
} from './dinabitesignup.style';
import {
  Container,
  LeftPane,
  RightPane,
  ImageContainer,
  StyledImage,
  DinabiteText,
} from '../DinabiteSignupPanel/globaldinabite.style';

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import welcomeImage from '../../assets/images/Illustration (2).png';
import welcomeImage2 from '../../assets/images/5492288.png';
import toolsimage from '../../assets/images/333.png';
import well from '../../assets/images/wellcome.png';
import well2 from '../../assets/images/naee.png';
import { getTitleForStep, getTextForStep } from '../steputils/steputils';
import { useAuth } from '../../context/AuthContext';
import useUser from '../../hooks/useUser';
import { createUserSocial } from '../../services/createUser';
import {
  INITIAL_VALUES_PROVIDER,
  LOGIN_PROVIDER_KEY,
} from '../../lib/globalValues';
import { notificationWithNodes } from '../../lib/notification';

import Step0 from './step0';

interface FormValues {
  email: string;
  password: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  country?: string;
  name?: string;
  accountType?: string;
}

const DinabiteLogin = () => {
  const { loginSocial, login2 } = useUser();
  // const { loginSocial } = useUser();
  const history = useHistory();
  const { setUser, loginProvider, setLoginProvider } = useAuth();
  const [currentStep, setCurrentStep] = useState(0);
  const [gmailAccount, setGmailAccount] = useState(null);
  // const { email } = loginProvider.data||'';
  const { provider, userID, tokenID } = loginProvider;
  const [selectedCountry, setSelectedCountry] = useState<string>('');

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    companyName: '',
    country: '',
    name: '',
    accountType: 'EMAIL',
  });
  const [isLoading, setIsLoading] = useState(false);
  // const { name, email, company, country } = loginProvider.data;

  useEffect(() => {
    const userEmail = loginProvider?.data?.email;
    // console.log('User email from loginProvider:', userEmail);

    if (!userEmail) {
      const loginProviderKey = localStorage.getItem(LOGIN_PROVIDER_KEY);
      localStorage.removeItem(LOGIN_PROVIDER_KEY);
      if (loginProviderKey) {
        try {
          const retrievedLoginProvider = JSON.parse(loginProviderKey);
          setLoginProvider(retrievedLoginProvider);
        } catch (error) {
          // console.error('Error parsing loginProviderKey:', error);
        }
      } else {
        // console.log('No loginProviderKey found in localStorage.');
      }
    } else {
      // If login provider data is already present, adjust step based on state or URL.
      const stepFromURL = parseInt(
        new URLSearchParams(window.location.search).get('step') || '2',
        10,
      );
      if (stepFromURL) {
        setCurrentStep(stepFromURL);
      }
    }
  }, [loginProvider, setLoginProvider]);
  const handleCountryChange = (country: string) => {
    setSelectedCountry(country);
    // Perform additional actions if needed
  };
  const handleNextStep = async (values: FormValues) => {
    const latestValues = { ...formValues, ...values };
    setFormValues(latestValues);
    // console.log('All values from Step 2:', latestValues);

    // Move to step 1 if on step 0
    if (currentStep === 0) {
      setCurrentStep(1);
      return; // Return here to prevent further execution
    }

    // Proceed with existing logic
    if (currentStep === 1) {
      setCurrentStep(2);
      return; // Return here to prevent further execution
    }

    if (currentStep === 2) {
      // console.log('step 2 start');
      
      const { socialProvider } = loginProvider; // Fetch socialProvider
      // console.log('social provider', socialProvider);

      if (socialProvider === 'google' || socialProvider === 'facebook') {
        if (loginProvider && loginProvider.data) {
          // const [firstName = '', lastName = ''] = name ? name.split(' ') : [];
          const {
            email,
            password,
            firstName,
            lastName,
            companyName,
            country,
            name,
            accountType,
          } = latestValues;
        //   console.log(`
        //   Email: ${email}
        //   Password: ${password}
        //   First Name: ${firstName}
        //   Last Name: ${lastName}
        //   Company Name: ${companyName}
        //   Country: ${country}
        //   Name: ${name}
        //   Account Type: ${accountType}
        // `);
          if (loginProvider?.data) {
            // console.log('step 2 login provider start');

            setIsLoading(true);
            const response = await createUserSocial(latestValues, () => {
                  // notificationWithNodes({
                  //   type: 'success',
                  //   title: 'Success',
                  //   description: (
                  //     <>
                  //       <p>Welcome to dinabite!</p>
                  //       <p>Please connect your tools</p>
                  //     </>
                  //   ),
                  // });
                // });
            });
            setIsLoading(false);

            if (response.data) {
              // console.log('response data is ', response.data);
              if (!values.password) {
                if (userID) {
                  await loginSocial(tokenID, 'facebook', userID);
                } else {
                  await loginSocial(tokenID, 'google');
                }
              }
              notificationWithNodes({
                type: 'success',
                title: 'Success',
                description: (
                  <>
                    <p>
                      Your Account has been created successfully, Please Login
                      now!
                    </p>
                  </>
                ),
              });
              setLoginProvider(INITIAL_VALUES_PROVIDER);
              if (response?.data?.users[0]?.password !== '') {
                history.push('/login');
              }
              if (response?.data?.users[0]?.password === '') {
                setCurrentStep(3);
                // history.push('/user-panel/welcome');
              }
            }
          }
        }
      } else {
        // console.log('step 2 elseeeee');
        try {
          const {
            email,
            password,
            firstName,
            lastName,
            companyName,
            country,
            name,
            accountType,
          } = latestValues;
          // console.log(`
          //   Email: ${email}
          //   Password: ${password}
          //   First Name: ${firstName}
          //   Last Name: ${lastName}
          //   Company Name: ${companyName}
          //   Country: ${selectedCountry}
          //   Name: ${name}
          //   Account Type: ${accountType}
          // `);
          // console.log("countryyyy noo ",selectedCountry)
          setIsLoading(true); // Start loading
          const response2 = await createUserSocial(
            {
              email,
              password,
              firstName: firstName || '',
              lastName: lastName || '',
              name: name || companyName || '',
              country: selectedCountry || '',
              accountType: 'email',
            },
            () => {
              // Success callback - You can include notifications here if needed
            },
          );

          if (response2.data) {
            const loginResponse = await login2(email, password);
            if (loginResponse) {
              setUser(loginResponse.user);

              localStorage.setItem('TOKEN', loginResponse.access_token);
              localStorage.setItem('USER_ID', loginResponse.user.id); // Store user ID
              setCurrentStep(3);
            } else {
              // console.error('Login failed after signup');
            }
          }
        } catch (error) {
          // console.error('Signup failed', error);
        } finally {
          setIsLoading(false); // Stop loading
        }
      }
      return; // Return here to prevent further execution
    }
    // Handle step 4 separately
    if (currentStep === 4) {
      history.push('/user-panel/dashboard'); // Redirect to the dashboard
      return; // Return here to prevent further execution
    }

    // Default case to move to the next step
    setCurrentStep(prevStep => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  const handleCancel = () => {
    // console.log('cancel is clicked');
    setLoginProvider({
      tokenID: '',
      provider: '',
      userID: '',
      socialProvider: '',
      data: {
        email: '',
        name: '',
        socialId: '',
        register: false, // Ensure this is a boolean, not a string
      },
    });

    // localStorage.removeItem(TOKEN);
    setCurrentStep(0);
    history.push('/'); // Redirect to the main page or another route
  };

  return (
    <>
      <Container>
        <LeftPane>
          <StepHeaderContainer>
            <CreateAccountTitle
              style={{ display: currentStep === 0 ? 'none' : 'flex' }}>
              {getTitleForStep(currentStep)}
            </CreateAccountTitle>
            <StepIndicator
              style={{ display: currentStep === 0 ? 'none' : 'flex' }}>
              {[1, 2, 3, 4].map(step => (
                <StepCircle key={step} active={currentStep === step} />
              ))}
            </StepIndicator>

            {/* Conditionally render the cancel button for steps 1 and 2 */}
            {(currentStep === 1 || currentStep === 2) && (
              <CancelButton onClick={handleCancel}>Cancel</CancelButton>
            )}
          </StepHeaderContainer>
          <MobileImage step={currentStep}>
            {currentStep === 0 && (
              <MobileImageContent src={welcomeImage} alt="Step 0 Image" />
            )}
            {currentStep === 1 && (
              <MobileImageContent src={welcomeImage} alt="Step 1 Image" />
            )}
            {currentStep === 2 && (
              <MobileImageContent src={welcomeImage2} alt="Step 2 Image" />
            )}
            {currentStep === 3 && (
              <MobileImageContent src={well2} alt="Step 3 Image" />
            )}
            {currentStep === 4 && (
              <MobileImageContent src={well} alt="Step 4 Image" />
            )}
          </MobileImage>
          {currentStep === 0 && (
            <Step0
              onNext={handleNextStep}
              onBack={handlePreviousStep}
              onCancel={handleCancel}
              setCurrentStep={setCurrentStep}
            />
          )}
          {currentStep === 1 && (
            <Step1
              onNext={handleNextStep}
              onBack={handlePreviousStep}
              onCancel={handleCancel}
              initialValues={formValues}
            />
          )}
          {currentStep === 2 && (
            <Step2
              onNext={handleNextStep}
              onBack={handlePreviousStep}
              onCancel={handleCancel}
              initialValues={formValues}
              loginProvider={loginProvider}
              onCountryChange={handleCountryChange} // Pass the callback

            />
          )}
          {currentStep === 3 && (
            <Step3
              onNext={() => handleNextStep(formValues)}
              onBack={handlePreviousStep}
              onCancel={handleCancel}
              firstName={formValues.firstName} // Pass the firstName to Step3
              lastName={formValues.lastName} // Pass the lastName to Step3
            />
          )}
          {currentStep === 4 && (
            <Step4
            // onNext={() => handleNextStep(formValues)} // Pass to redirect
            // onBack={handlePreviousStep}
            // onCancel={handleCancel}
            />
          )}
        </LeftPane>

        <RightPane>
          <ImageContainer>
            {currentStep === 0 && (
              <StyledImage src={welcomeImage} alt="Illustration" />
            )}
            {currentStep === 1 && (
              <StyledImage src={welcomeImage} alt="Illustration" />
            )}
            {currentStep === 2 && (
              <StyledImage src={welcomeImage2} alt="Illustration" />
            )}
            {currentStep === 3 && (
              <StyledImage src={toolsimage} alt="Illustration" />
            )}
            {currentStep === 4 && <StyledImage src={well} alt="Illustration" />}
            <DinabiteText>{getTextForStep(currentStep)}</DinabiteText>
          </ImageContainer>
        </RightPane>
      </Container>
    </>
  );
};

export default DinabiteLogin;
