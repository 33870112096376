import { Link } from 'react-router-dom';
import { Divider, FlexboxGrid,Content } from 'rsuite';
import styled from 'styled-components';

export const IconLink = styled(Link)`
  color: ${props => props.theme.colors.grey};
`;

export const FlexboxGridMb = styled(FlexboxGrid)`
  margin-bottom: 1em;

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    margin: 2em 0em;
  }
`;
export const StyledFlexboxGrid = styled(FlexboxGrid)`
  /* Your custom styles here */
  position:fixed;height60%
  padding: 20px;
  background-color: #f8f8f8;
`;
export const StyledFlexboxGridItem = styled(FlexboxGrid.Item)`
  height: 50rem; /* Default height */
  overflow-y: auto;

  @media (max-width: 768px) {
    height: 500px; /* Adjust height for smaller screens */
  }

  @media (max-width: 480px) {
    height: 400px; /* Adjust height for mobile devices */
  }
`;

export const Contentt = styled(Content)`
`;
export const TitleAside = styled.p`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.bigSubtitle};
  font-weight: bolder;
`;

export const HeaderP = styled.p`
  padding: 10px 0px 30px;
`;

export const SubtitleAside = styled.p`
  color: ${props => props.theme.colors.pinkRed};
  font-size: ${props => props.theme.fonts.paragraph};
  padding: 10px 0px;
`;

export const DividerAside = styled(Divider)`
  margin: 40px 10%;
  background-color: ${props => props.theme.colors.grey};
`;

export const Title = styled.p`
  color: ${props => props.theme.colors.greyLetter};
  font-size: ${props => props.theme.fonts.bigSubtitle};
  font-weight: 550;
`;

export const Button = styled.button`
color: #ffffff !important;
background-color: #E74138 !important;
font-size: 1.3em;
padding: 5px;
font-weight: bolder;
border-radius: 20px;
margin-right: 15px;
`;

export const ToolImage = styled.img`
  margin-right: 10px;
`;

export const ToolsContainer = styled.div`
  @media ${props => props.theme.breakpoints.maxMedium} {
    margin: 10px;
  }
`;

export const TitleContent = styled.p`
  font-size: ${props => props.theme.fonts.subtitle};
  font-weight: bolder;
  color: ${props => props.theme.colors.black};
  margin-top: 20px;
  margin-bottom: 10px;
  //  @media (max-width: 768px) {
  //   margin-top: 0px;
  // }

  //  @media (max-width: 480px) {
  //   margin-top: 0px;
  // }
`;  
