export const TYPES_TOOLS = {
  ACTIVATE_FACEBOOK: 'ACTIVATE_FACEBOOK',
  CONNECT_AD_ACCOUNT: 'CONNECT_AD_ACCOUNT',
  DEACTIVATE_FACEBOOK: 'DEACTIVATE_FACEBOOK',
  ACTIVATE_GOOGLE: 'ACTIVATE_GOOGLE',
  DEACTIVATE_GOOGLE: 'DEACTIVATE_GOOGLE',
  ACTIVATE_INSTAGRAM: 'ACTIVATE_INSTAGRAM',
  DEACTIVATE_INSTAGRAM: 'DEACTIVATE_INSTAGRAM',
  ACTIVATE_TWITTER: 'ACTIVATE_TWITTER',
  DEACTIVATE_TWITTER: 'DEACTIVATE_TWITTER',
  ACTIVATE_UBER_EATS: 'ACTIVATE_UBER_EATS',
  DEACTIVATE_UBER_EATS: 'DEACTIVATE_UBER_EATS',
  INIT_TOOLS: 'INIT_TOOLS',
  RESET_TOOLS: 'RESET_TOOLS',
};
