import { useEffect, useState } from 'react';
import axios from '../../lib/axios';
import {
  ButtonContainer,
  FlexContainer,
  Frame,
  FrameBehind,
  FrameFront,
  HeadingContainer,
  Rectangle,
  TriangleContainer,
  TriangleLeft,
  TriangleRight,
} from './PremiumPlan.styles';
import CheckCircleIcon from '../../assets/icons/checkCircle.svg';
import RectangleButton from '../../components/rectangleButton/RectangleButton';
import { useAuth } from '../../context/AuthContext';

const PremiumPlan = () => {
  const { user, companySelected } = useAuth();
  const [displayPremiumPlan, setDisplayPremiumPlan] = useState<boolean>(false);

  useEffect(() => {
    const { subscriptionStatus, invoiceStatus } =
      user?.companies?.[0]?.payment ?? {};
    if (subscriptionStatus === 'active' && invoiceStatus === 'paid') {
      setDisplayPremiumPlan(true);
    } else {
      setDisplayPremiumPlan(false);
    }
  }, [user]);

  const handleCheckoutSubmit = async () => {
    try {
      if (companySelected?.id) {
        const checkoutURL = await axios.post(`stripe/create-checkout-session`, {
          companyId: companySelected.id,
        });

        window.location.href = checkoutURL.data;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  return (
    <>
      <TriangleContainer>
        <TriangleLeft />
        <TriangleRight />
      </TriangleContainer>
      <Rectangle>
        <span>
          <h3>$12.40/month - cancel any time </h3>
        </span>
        {/* <p>Cancel any time </p> */}
      </Rectangle>

      <FrameBehind />

      <Frame>
        <h2>Premium Plan</h2>
      </Frame>

      <FrameFront>
        <HeadingContainer>
          <p
            style={{
              fontWeight: 'bold',
              padding: '0 10px 10px 10px',
              marginTop: 80,
              marginBottom: 5,
            }}
          >
            All the features on the Basic plan plus:
          </p>

          <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p> AI suggested posts</p>
          </FlexContainer>

          <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p>AI recommended hashtags</p>
          </FlexContainer>

          <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p> Add emojis to posts</p>
          </FlexContainer>

          <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p>Add emojis to messages</p>
          </FlexContainer>

          <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p> AI suggested messages</p>
          </FlexContainer>

          <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p>Respond to messages</p>
          </FlexContainer>

          <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p>Launch & manage promotions</p>
          </FlexContainer>

          {/* <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p>Advanced analytics </p>
          </FlexContainer>   */}
        </HeadingContainer>
        {!displayPremiumPlan && (
          <ButtonContainer>
            <RectangleButton title="Upgrade" onClick={handleCheckoutSubmit} />
          </ButtonContainer>
        )}
        {displayPremiumPlan && (
          <ButtonContainer>
            <RectangleButton
              disabled
              title="Current Plan"
              onClick={handleCheckoutSubmit}
            />
          </ButtonContainer>
        )}
      </FrameFront>
    </>
  );
};

export default PremiumPlan;
