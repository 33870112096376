/* eslint-disable no-plusplus */
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Col, FlexboxGrid, Loader, Row } from 'rsuite';
import { useAuth } from '../../../context/AuthContext';
import useUser from '../../../hooks/useUser';
import { notificationWithNodes } from '../../../lib/notification';
import { createUserSocial } from '../../../services/createUser';
import { FormContainer } from '../Forms.styles';
import FieldInput from './fieldInput/FieldInput';
import {
  HiddenDiv,
  HiddenGroup,
  SignUpFormContainer,
  SignUpParagraph,
} from './FormSignUp.styles';
import SelectCountry from './selectCountry/SelectCountry';
import { validationSocialSchemas } from './validation';
import { ButtonContainer, ButtonSign } from '../newLoginForm/LoginForm.style';
import PasswordInput from '../../inputs/PasswordInput/PasswordInput';
import ForwordIcon from '../../../assets/images/arrow_forward.png';
import RegisterButtons from '../../registerButtons/RegisterButtons';
import { INITIAL_VALUES_PROVIDER } from '../../../lib/globalValues';

interface Props {
  page: number;
  setPage: (value: React.SetStateAction<number>) => void;
  width?: number | undefined;
}

interface ValuesForm {
  firstName: string;
  lastName: string;
  name: string;
  country: string;
  password?: string;
  email?: string;
}

const FormSocialSignUp = ({ page, setPage, width }: Props) => {
  const { loginProvider, setLoginProvider } = useAuth();
  const { loginSocial } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(2);

  // below code is incase the user signups from dinabite company site, so get data from localstorage
  const userEmail = loginProvider?.data?.email;
  if (!userEmail) {
    const loginProviderKey = localStorage.getItem('loginProviderKey');

    localStorage.removeItem('loginProviderKey');

    if (loginProviderKey) {
      const retrievedLoginProvider = JSON.parse(loginProviderKey);
      setLoginProvider(retrievedLoginProvider);
    }
  }

  const name = loginProvider?.data?.name?.split(' ');
  const googleEmail = loginProvider?.data?.email;
  const loginWithGoogle = loginProvider?.data?.register;

  const initialValues = {
    firstName: name ? name[0] : '',
    lastName: name ? name[1] : '',
    name: '',
    country: '',
    password: '',
    email: googleEmail ?? '',
  };

  const history = useHistory();

  const createUser = async (values: ValuesForm, resetForm: () => void) => {
    if (loginProvider?.data) {
      const { email } = loginProvider.data;
      const { provider, userID, tokenID } = loginProvider;
      const data = { email, accountType: provider, ...values };
      setIsLoading(true);

      const response = await createUserSocial(data, () => {
        // notificationWithNodes({
        //   type: 'success',
        //   title: 'Success',
        //   description: (
        //     <>
        //       <p>Welcome to dinabite!</p>
        //       <p>Please connect your tools</p>
        //     </>
        //   ),
        // });
      });
      setIsLoading(false);
      if (response.data) {
        if (!values.password) {
          if (userID) {
            await loginSocial(tokenID, 'facebook', userID);
          } else {
            await loginSocial(tokenID, 'google');
          }
        }
        notificationWithNodes({
          type: 'success',
          title: 'Success',
          description: (
            <>
              <p>
                Your Account has been created successfully, Please Login now!
              </p>
            </>
          ),
        });
        setPage(0);
        resetForm();
        setLoginProvider(INITIAL_VALUES_PROVIDER);
        if (response?.data?.users[0]?.password !== '') {
          history.push('/login');
        }
        if (response?.data?.users[0]?.password === '') {
          history.push('/user-panel/welcome');
        }
      }
    }
  };

  useEffect(() => {
    if (loginProvider?.data && googleEmail !== '') {
      setPage(1);
      setTimeout(() => {
        setPage(1);
      }, 10);
    }
  }, [loginProvider?.data]);

  const handleClearValues = () => {
    setLoginProvider(INITIAL_VALUES_PROVIDER);
  };
const handleCountryChange =()=>{
  // ldfkl
}
  return (
    <SignUpFormContainer>
      <span className="form-title">Create Your account</span>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSocialSchemas[page]}
        enableReinitialize
        onSubmit={(values, { resetForm }) => createUser(values, resetForm)}
        validateOnMount>
        {({
          touched,
          values,
          errors,
          isValid,
          validateForm,
          setFieldValue,
          setFieldTouched,
        }) => (
          <>
            <FormContainer>
              <HiddenDiv page={page !== 0}>
                <FieldInput
                  placeholder="enter your email"
                  type="text"
                  name="email"
                  page={page !== 0}
                  touched={touched}
                  errors={errors}
                  label="Email"
                />
                {loginProvider?.data?.email ? null : (
                  <PasswordInput
                    signUp={false}
                    name="password"
                    error={touched.password && errors.password}
                    label="Password"
                    placeholder="enter your password"
                  />
                )}
              </HiddenDiv>

              <HiddenGroup inside page={page !== 1 ? 1 : 0}>
                <Row>
                  <Col xs={24} sm={12}>
                    <FieldInput
                      placeholder={initialValues.firstName || 'Type here'}
                      type="text"
                      name="firstName"
                      page={page !== 1}
                      touched={touched}
                      errors={errors}
                      label="First Name"
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldInput
                      placeholder={initialValues.lastName || 'Type here'}
                      type="text"
                      name="lastName"
                      page={page !== 1}
                      touched={touched}
                      errors={errors}
                      label="Last Name"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={12}>
                    <FieldInput
                      placeholder={initialValues.name || 'Type here'}
                      type="text"
                      name="name"
                      page={page !== 1}
                      touched={touched}
                      errors={errors}
                      label="Business Name"
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <SelectCountry
                      name="country"
                      page={page !== 1}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      menuPlacement={false}
                      onCountryChange={handleCountryChange} // Pass callback


                    />
                  </Col>
                </Row>
              </HiddenGroup>

              <FlexboxGrid align="middle" justify="space-between">
                {page !== 0 && (
                  <ButtonContainer>
                    <ButtonContainer>
                      <ButtonContainer>
                        <ButtonSign
                          block
                          type="submit"
                          next={
                            (page === 1 && isValid) || loginWithGoogle
                              ? 'true'
                              : ''
                          }
                          disabled={isLoading}
                          onClick={async () => {
                            validateForm();
                          }}>
                          {isLoading ? (
                            <Loader content="Loading..." />
                          ) : (
                            <>
                              <span>Confirm</span>

                              {width && width > 768 && (
                                <img src={ForwordIcon} alt="Forward Icon" />
                              )}
                            </>
                          )}
                        </ButtonSign>
                      </ButtonContainer>
                    </ButtonContainer>

                    {width && width > 768 && (
                      <SignUpParagraph to="/login" onClick={handleClearValues}>
                        <span>Already have an account?</span>
                        <span>SIGN IN</span>
                      </SignUpParagraph>
                    )}
                  </ButtonContainer>
                )}
                {page !== 1 && (
                  <ButtonContainer>
                    <ButtonSign
                      block
                      next="true"
                      disabled={
                        !values.email ||
                        !values.password ||
                        (errors.email && touched.email) ||
                        (errors.password && touched.password)
                      }
                      onClick={() => {
                        if (page !== 2) {
                          setPage((oldPage: number) => oldPage + 1);
                          validateForm();
                        }
                      }}>
                      <span>Next</span>

                      {width && width > 768 && (
                        <img src={ForwordIcon} alt="Forword Icon" />
                      )}
                    </ButtonSign>
                    {width && width > 768 && (
                      <SignUpParagraph to="/login">
                        <span>Already have an account?</span>
                        <span>SIGN IN</span>
                      </SignUpParagraph>
                    )}
                  </ButtonContainer>
                )}
              </FlexboxGrid>
            </FormContainer>
            {page !== 1 && (
              <ButtonContainer>
                <RegisterButtons
                  signUP
                  facebookButtonText="Continue with Facebook"
                  googleButtonText="Continue with Google"
                  setCurrentStep={setCurrentStep} // Passing setCurrentStep function as a prop

                />
              </ButtonContainer>
            )}
          </>
        )}
      </Formik>
    </SignUpFormContainer>
  );
};

export default FormSocialSignUp;
