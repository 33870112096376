import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../../lib/axios';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const queryString = require('query-string');

const Twitter = () => {
  const getToken = async () => {
    try {
      const { state, code, error } = queryString.parse(params.search);

      // If there's an error "access_denied", close the window and exit the function.
      if (error === 'access_denied') {
        localStorage.setItem(
          'twitterError',
          JSON.stringify({ access_denied: true }),
        );
        window.close();
        return error; // Returning the error here
      }

      // If state and code are available, make the API call.
      if (state && code) {
        const { data } = await axios.get('tools/twitterCallback', {
          params: { state, code },
        });

        const twitterData = {
          accessToken: data.access_token,
          userId: data?.userId,
        };
        localStorage.removeItem('twitterError');
        localStorage.setItem('twitter', JSON.stringify(twitterData));
        window.close();
        return data; // You can choose what to return here. For this example, returning the data.
      }

      // If no conditions are met
      return null; // Return null or any other default value.
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error occurred:', err);

      // You can add any additional error handling here, if needed.
      window.close();
      return err; // Return the caught error.
    }
  };

  useEffect(() => {
    getToken();
  }, []);
  const params = useLocation();

  return (
    <div>
      <h1>Loading...</h1>
    </div>
  );
};

export default Twitter;
