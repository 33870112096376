/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { useState } from 'react';
import { Button, Col, FlexboxGrid } from 'rsuite';
import { useTheme } from 'styled-components';
import { TYPES_TOOLS } from '../../../actions/toolsActions';
import { TYPES_OF_SOCIAL_NETWORKS } from '../../../lib/globalValues';
import { notificationError } from '../../../lib/notification';
import { assignTool } from '../../../services/assignTool';
import { disableTool } from '../../../services/disableTool';
import { facebookConnect } from '../../../services/facebookTools';
import { getToolsCompanyObj } from '../../../services/getToolsCompany';
import {
  instagramLogin,
  instagramPageSelected,
} from '../../../services/instagramTools';
import ModalTools from '../../modals/modalTools/ModalTools';
import {
  ButtonAdd,
  ButtonContainer,
  CardPage,
  Container,
} from '../Toogle.styles';

interface Props {
  companyId?: number;
  dispatchTool: any;
  stateTool: any;
  invalidateQuery?: () => void;
  signup?: boolean;

}

const InstagramToogle = ({
  companyId,
  stateTool,
  dispatchTool,
  invalidateQuery,
  signup=false,
}: Props) => {
  const theme = useTheme();

  const [dataModal, setDataModal] = useState<FacebookData | null>(null);
  const [dataModalArray, setDataModalArray] = useState<string[]>([]);
  const [switchData, setSwitchData] = useState<[] | null>(null);
  const [step, setStep] = useState<'page' | 'switch'>('page');

  const activated =
    stateTool[TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM]?.enabled === true;

  const handleLoginInstagram = async () => {
    try {
      const facebookAcoount = await instagramLogin();
      const { data } = await facebookConnect(
        facebookAcoount as fb.StatusResponse,
      );

      if (data) {
        setDataModal(data);
      }
    } catch (err) {
      notificationError({
        title: 'Error',
        description: `Connecting Instagram, was canceled`,
      });
    }
  };

  const handleAddSelectedPagesClick = async (page: FacebookPage) => {
    setDataModalArray(prev => [...prev, page.id]);

    const instaPageArray: FacebookPage[] = [page];

    if (dataModal) {
      await handleSelectPages(instaPageArray);
    }
  };
  const handleSelectPages = async (page: FacebookPage[]) => {
    const instagramPages = await instagramPageSelected(page);
    const transformedPages: Tool[] = instagramPages.data.map(
      (selectedPage: any) => ({
        type: TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM,
        accessToken: selectedPage.access_token,
        pageId: selectedPage.id,
        pageName: `${selectedPage.name} - ${selectedPage.category}`,
        enabled: dataModalArray.length === 0,
        userId: selectedPage.userId,
      }),
    );

    if (companyId) {
      const { data } = await assignTool({
        companyId,
        tool: transformedPages,
      });

      if (data && data[0] && dataModalArray.length === 0) {
        dispatchTool({
          type: TYPES_TOOLS.ACTIVATE_INSTAGRAM,
          payload: data[0],
        });
      }
    } else {
      dispatchTool({
        type: TYPES_TOOLS.ACTIVATE_INSTAGRAM,
        payload: {
          // enabled: true,
          ...transformedPages[0],
        },
      });
    }

    invalidateQuery?.();
    // setDataModal(null);
  };

  const handleShowPages = async () => {
    const { data: dataTools } = await getToolsCompanyObj(companyId);
    setSwitchData(dataTools.instagram);
    setStep('switch');
  };

  const handleSelectPage = async (page: any) => {
    const defaultSelectContent = [
      {
        type: TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM,
        accessToken: page.accessToken,
        pageId: page.pageId,
        // pageName: `${name} - ${category}`,
        userId: page.userId,
        // adAccountId: null,
        enabled: true,
        id: page.id,
      },
    ];

    if (companyId) {
      const { data } = await assignTool({
        companyId,
        tool: defaultSelectContent,
      });

      dispatchTool({
        type: TYPES_TOOLS.ACTIVATE_INSTAGRAM,
        payload: data[0],
      });
      setSwitchData(null);
    }
  };

  return (
    <>
      {dataModal && step === 'page' && (
        <ModalTools
          setDataModal={setDataModal}
          dataModal={dataModal}
          title="Connect Instagram Business profiles"
          description="Select the Instagram business profiles"
          setDataModalArray={setDataModalArray}
        >
          {dataModal?.pages && (
            <div>
              {dataModal.pages.map(page => {
                const isDisabled = dataModalArray.includes(page.id);
                return (
                  <CardPage align="middle" justify="space-around" key={page.id}>
                    <FlexboxGrid.Item xs={20} componentClass={Col}>
                      <span>
                        {page.name} - {page.category}
                      </span>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item xs={4} componentClass={Col}>
                      <Button
                        disabled={isDisabled}
                        onClick={() => handleAddSelectedPagesClick(page)}
                      >
                        Add Page
                      </Button>
                    </FlexboxGrid.Item>
                  </CardPage>
                );
              })}
            </div>
          )}
        </ModalTools>
      )}
      {switchData && step === 'switch' && (
        <ModalTools
          setDataModal={setSwitchData}
          dataModal={switchData}
          title="Switch Instagram page"
          description="Select the Instagram page to switch to"
          setStep={setStep}
          step="page"
        >
          <div>
            {switchData?.map((page: any) => {
              return (
                <CardPage align="middle" key={page.pageId}>
                  <FlexboxGrid.Item xs={20} componentClass={Col}>
                    <span>{page.pageName}</span>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item xs={4} componentClass={Col}>
                    <Button
                      disabled={page.enabled}
                      onClick={() => handleSelectPage(page)}
                    >
                      {page.enabled ? 'Activated' : 'Activate'}
                    </Button>
                    {/* <input type='checkbox'
                      onChange={(e) => handlePageCheck(page, e.target.checked)}
                      checked={selectedPages.some(p => p.id === page.id)}
                      /> */}
                  </FlexboxGrid.Item>
                </CardPage>
              );
            })}
          </div>
        </ModalTools>
      )}

{signup ? (

      <>
        {activated && (
          <Container
          style={{
            // display: 'flex',
            // flexDirection: signup ? 'column' : 'row', 
            justifyContent: signup ? 'end' : 'space-between',
            // alignItems: 'center',
            // padding: signup ? '20px' : '10px',
            // borderRadius: signup ? '10px' : '0',
            // width: signup ? '20%' : '100%', 
            // margin: '0 auto',
            // gap: signup ? '10px' : '0', 
          }}
          >
            {/* <ButtonAdd 
              bgColor={signup ? 'black' : theme.colors.crimson}
              color={signup ? 'black' : theme.colors.crimson}
              width={signup ? '30px' : 'auto'}
              onClick={handleShowPages}
              style={{
                width: signup ? '120px' : 'auto',
                marginBottom: signup ? '5px' : '0', // Add margin between buttons in column layout
              }}  >
              Switch
            </ButtonAdd> */}
            {/* <TextContainer>
              <ParagraphColor status={1}>Connected</ParagraphColor>
            </TextContainer> */}
            <ButtonAdd
                // bgColor={signup ? 'black' : theme.colors.red}
              // color={signup ? 'black' : theme.colors.crimson}
              width={signup ? '30px' : 'auto'}       
                     onClick={() => {
                      
                disableTool(
                  stateTool[TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM].id,
                ).then(() => {
                  dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_INSTAGRAM });
                  invalidateQuery?.();
                });
                // .catch(error => {
                //   console.log('Error:', error);
                // });
              }}
              style={{
                width: signup ? '120px' : 'auto',
                color: signup ? '#64E000' : 'inherit',
                fontFamily: signup ? "'Inter', sans-serif" : 'inherit', // Set the font family
               
              }}
            >
              Connected
            </ButtonAdd>
            {/* <ButtonAdd bgColor={theme.colors.green} disabled>
              Connected
            </ButtonAdd> */}
          </Container>
        )}
        {!activated && (
          <ButtonContainer        
             signup={signup}
            >
            <ButtonAdd
              bgColor={theme.colors.red}
              onClick={handleLoginInstagram}
              signup={signup}

            >
              Connect
            </ButtonAdd>
          </ButtonContainer>
        )}
      </>
      ) : (   
                // When signup is false, show Switch and Disconnect
        <>
        {activated && (
          <Container
          style={{
            display: 'flex',
            flexDirection: signup ? 'column' : 'row', // Stack buttons vertically if signup is true
            justifyContent: signup ? 'center' : 'space-between',
            alignItems: 'center',
            padding: signup ? '20px' : '10px',
            borderRadius: signup ? '10px' : '0',
            width: signup ? '20%' : '100%', // Set width to 40% when signup is true
            margin: '0 auto', // Center the container
            gap: signup ? '10px' : '0', // Add gap between buttons in column layout
          }}>
            <ButtonAdd 
              bgColor={signup ? 'black' : theme.colors.crimson}
              color={signup ? 'black' : theme.colors.crimson}
              width={signup ? '30px' : 'auto'}
              onClick={handleShowPages}
              style={{
                width: signup ? '120px' : 'auto',
                marginBottom: signup ? '5px' : '0', // Add margin between buttons in column layout
              }}  >
              Switch
            </ButtonAdd>
            {/* <TextContainer>
              <ParagraphColor status={1}>Connected</ParagraphColor>
            </TextContainer> */}
            <ButtonAdd
                bgColor={signup ? 'black' : theme.colors.red}
              color={signup ? 'black' : theme.colors.crimson}
              width={signup ? '30px' : 'auto'}       
                     onClick={() => {
                      
                disableTool(
                  stateTool[TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM].id,
                ).then(() => {
                  dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_INSTAGRAM });
                  invalidateQuery?.();
                });
                // .catch(error => {
                //   console.log('Error:', error);
                // });
              }}
              style={{
                width: signup ? '120px' : 'auto',
              }}
            >
              Disconnect
            </ButtonAdd>
            {/* <ButtonAdd bgColor={theme.colors.green} disabled>
              Connected
            </ButtonAdd> */}
          </Container>
        )}
        {!activated && (
          <ButtonContainer        
             signup={signup}
            >
            <ButtonAdd
              bgColor={theme.colors.red}
              onClick={handleLoginInstagram}
              signup={signup}

            >
              Connect
            </ButtonAdd>
          </ButtonContainer>
        )}
      </>
      )}
      {/* <Toggle
        checked={activated}
        onChange={(check: boolean) =>
          check
            ? handleLoginInstagram()
            : disableTool(
                stateTool[TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM].id,
              ).then(() => {
                dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_INSTAGRAM });
                invalidateQuery?.();
              })
        }
      /> */}
    </>
  );
};

export default InstagramToogle;
