import { useQuery } from 'react-query';
import { fetchPosts } from '../services/posts';

const usePosts = () => {
  return useQuery('POSTS', fetchPosts, {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
  });
};

export default usePosts;
