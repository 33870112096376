/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import { Divider, Drawer } from 'rsuite';
import { useEffect, useState } from 'react';
import {
  DrawerBorder,
  SocialLogo,
  Image,
  PreviewWrapper,
  PageImageWrapper,
  PageName,
  PostText,
  ImageContainer,
  PostImage,
  BottomSection,
  LikesSection,
  ImageWrapper,
  CountWrapper,
  LikeWrapper,
  SocialWrapper,
  CommentWrapper,
  ShareWrapper,
  TimeWrapper,
  Button,
  CommentContainer,
  DisplayCommentSection,
  CommentCardWrapper,
  SingleCommentWrapper,
  InputCommentSection,
  InputSection,
  IconsWrapper,
  Input,
  SelectIconSection,
  Icon,
  ImageTextContainer,
  ButtonsContainer,
  LikesButton,
  ReplyButton,
} from './FaceBookPreview.style';
import { formatTimeDifference, formatDate } from '../../../lib/format';
import { MEDIA_TYPE } from '../../../lib/globalValues';
import FaceBookAroundImage from '../../../assets/icons/facebook-round.svg';
import DefaultImage from '../../../assets/images/default.jpg';
import FacebookLikesImage from '../../../assets/icons/fb-likes.svg';
import FacebookUnLikeThumbImage from '../../../assets/icons/fb-gray.svg';
import FacebookLikedThumbImage from '../../../assets/icons/fb-liked.svg';
import FacebookCommentImage from '../../../assets/icons/fb-comment.svg';
import FacebookShareImage from '../../../assets/icons/fb-share.svg';
import {
  checkPostLikedByMe,
  getPostCommentsById,
  likeCommentBycommentId,
  sendCommentInCommentById,
  sendCommentInPostById,
  unLikeCommentBycommentId,
} from '../../../services/facebookComments';
import FacebookCommentModal from './FacebookCommentModal';
import { likePostById, unLikePostById } from '../../../services/facebookLike';
import { notification } from '../../../lib/notification';
import { useCurrentPostStore } from '../../../store';

// type CommentType = {
//   id: number;
//   text: string;
//   // Add other fields as needed
// };

// type CommentsData = {
//   data: CommentType[];
// };

const Comment = ({
  postId,
  pageImage,
  pageName,
  setModalOpen,
  setUpdate,
}: any) => {
  const { comments } = useCurrentPostStore();
  const [userInput, setUserInput] = useState('');
  const [userCommentInput, setUserCommentInput] = useState('');
  const [isCommentId, setIsCommentId] = useState('');
  const [replyComments, setReplyComments] = useState<{
    [key: string]: boolean;
  }>({});

  const icons = [
    
    'icon-6',
  ];

  const handleLikeClick = async (commentId: string, likesValue: any) => {
    if (!likesValue.summary.has_liked) {
      const res = await likeCommentBycommentId(commentId);
      if (res) {
        setUpdate((prev: any) => prev + 1);
        notification({
          type: 'success',
          title: 'FaceBook Comment',
          description: 'Comment Liked Successfully.',
        });
      }
    } else {
      const res = await unLikeCommentBycommentId(commentId);
      if (res) {
        setUpdate((prev: any) => prev + 1);
        notification({
          type: 'success',
          title: 'FaceBook Comment',
          description: 'Comment UnLiked Successfully.',
        });
      }
    }
  };

  const handleReplyClick = async (commentId: string) => {
    setIsCommentId(commentId);
    setReplyComments(prevReplyComments => ({
      ...prevReplyComments,
      [commentId]: !prevReplyComments[commentId],
    }));
  };

  const handleOpenModel = () => {
    setModalOpen(true);
  };

  const handleInput = (e: any) => {
    const { value } = e.target;
    setUserInput(value);
  };

  const handleSubInput = (e: any) => {
    const { value } = e.target;
    setUserCommentInput(value);
  };

  const handleSubIconClick = (iconClass: string) => {
    if (iconClass === 'icon-6') {
      handleSendSubComment();
    } else {
      // Handle other icons' actions
      console.log(`${iconClass} clicked`);
    }
  };

  const handleSendSubComment = async () => {
    if (userCommentInput === '') {
      notification({
        type: 'info',
        title: 'FaceBook Post Comment',
        description: 'Type Something...!',
      });
    }
    if (userCommentInput !== '') {
      const res = await sendCommentInCommentById(isCommentId, userCommentInput);
      if (res) {
        setUserCommentInput('');
        setUpdate((prev: any) => prev + 1);
        notification({
          type: 'success',
          title: 'FaceBook Post Comment',
          description: 'Comment Posted Successfully.',
        });
      }
    }
  };

  const handleIconClick = (iconClass: string) => {
    if (iconClass === 'icon-6') {
      handleSendComment();
    } else {
      // Handle other icons' actions
      console.log(`${iconClass} clicked`);
    }
  };

  const handleSendComment = async () => {
    if (userInput === '') {
      notification({
        type: 'info',
        title: 'FaceBook Post Comment',
        description: 'Type Something...!',
      });
    }
    if (userInput !== '') {
      const res = await sendCommentInPostById(postId, userInput);
      if (res) {
        setUserInput('');
        setUpdate((prev: any) => prev + 1);
        notification({
          type: 'success',
          title: 'FaceBook Post Comment',
          description: 'Comment Posted Successfully.',
        });
      }
    }
  };

  return (
    <CommentContainer>
      {(comments?.length ?? 0)  >= 2 && (
        <Button onClick={handleOpenModel}>View more comments</Button>
      )}
      <DisplayCommentSection>
        {comments
          ?.slice(0, 1)
          ?.map(({ from, message, id, likes, created_time }: any) => (
            <CommentCardWrapper key={from?.id}>
              <img src={from?.picture?.data?.url} alt={from?.name} />
              <ImageTextContainer>
                <SingleCommentWrapper>
                  <span>{from?.name}</span>
                  <p>{message}</p>
                </SingleCommentWrapper>
                <ButtonsContainer>
                  <span>{formatTimeDifference(created_time)}</span>
                  <LikesButton
                    liked={likes.summary.total_count > 0}
                    onClick={() => handleLikeClick(id, likes)}>
                    Like
                  </LikesButton>
                  <ReplyButton onClick={() => handleReplyClick(id)}>
                    Reply
                  </ReplyButton>
                  {likes.summary.total_count > 0 && (
                    <img src={FacebookLikesImage} alt="Like Icon" />
                  )}
                </ButtonsContainer>
                {replyComments[id] && (
                  <InputCommentSection>
                    <img src={pageImage} alt="page" />
                    <InputSection>
                      <Input
                        value={userCommentInput}
                        placeholder={`Comment as ${pageName}`}
                        onChange={e => handleSubInput(e)}
                      />
                      <IconsWrapper>
                        <SelectIconSection>
                          {icons.map(iconClass => (
                            <Icon
                              key={iconClass}
                              className={iconClass}
                              onClick={() => handleSubIconClick(iconClass)}
                            />
                          ))}
                        </SelectIconSection>
                      </IconsWrapper>
                    </InputSection>
                  </InputCommentSection>
                )}
              </ImageTextContainer>
            </CommentCardWrapper>
          ))}
      </DisplayCommentSection>
      <InputCommentSection>
        <img src={pageImage} alt="page" />
        <InputSection>
          <Input
            value={userInput}
            placeholder={`Comment as ${pageName}`}
            onChange={e => handleInput(e)}
          />
          
          <IconsWrapper>
            <SelectIconSection>
              {icons.map(iconClass => (
                <Icon
                  key={iconClass}
                  className={iconClass}
                  onClick={() => handleIconClick(iconClass)}
                />
              ))}
            </SelectIconSection>
          </IconsWrapper>
        </InputSection>
      </InputCommentSection>
    </CommentContainer>
  );
};

const FaceBookPreview = ({
  show,
  size,
  showDrawer,
  onHide,
  setUpdatePost,
}: any) => {
  const { comments, setComments, likedByMe, setLikeByMe, post, setLikes } = useCurrentPostStore();
  const [isComment, setIsComment] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
    const [update, setUpdate] = useState(0);


  const getCommentsDataByPostId = async () => {
    const { data } = await getPostCommentsById(showDrawer.id);
    setComments(data?.data ?? [])
  };


  const checkPosDataLikedByMe = async () => {
    const  likedbyme  = await checkPostLikedByMe(showDrawer.id);
    setLikeByMe(likedbyme === true)
  };


  useEffect(() => {
    getCommentsDataByPostId();
    checkPosDataLikedByMe();
  }, [showDrawer.id, update]);

  const handleModalClose = () => setModalOpen(false);

  const handleSubmitLike = async (id: string) => {
    if (!likedByMe) {
      setLikeByMe(true);
      const { data } = await likePostById(id);
      if (data) {
        setLikes((post?.totalLikes ?? 0) + 1)
        setUpdatePost((prev: number) => prev + 1);
        notification({
          type: 'success',
          title: 'FaceBook Post',
          description: 'Post Liked successfully.',
        });
        // onHide(null);
      } else {
        setLikeByMe(false)
      }
    } else {
      setLikeByMe(false);
      const { data } = await unLikePostById(id);
      if (data) {
        setLikes((post?.totalLikes ?? 1) - 1)
        setUpdatePost((prev: number) => prev + 1);

        notification({
          type: 'success',
          title: 'FaceBook Post',
          description: 'Post UnLiked successfully.',
        });
        // onHide(null);
      } else {
        setLikeByMe(true)  
      }
    }
  };

  return (
    <DrawerBorder
      show={show}
      size={size}
      onHide={onHide}
      increaseHei={isComment}>
      <SocialLogo>
        <Image src={FaceBookAroundImage} alt="icon" />
      </SocialLogo>
      <PreviewWrapper>
        <DrawerBorder.Header>
          <PageImageWrapper>
            <Image src={showDrawer?.pageProfilePicture} alt="Page Image" />
            <TimeWrapper>
              <PageName>{showDrawer?.pageName || ''}</PageName>
              <span>{formatDate(showDrawer?.publishedAt)}</span>
            </TimeWrapper>
          </PageImageWrapper>
        </DrawerBorder.Header>
        <Drawer.Body>
          <PostText>{showDrawer?.text}</PostText>
          <ImageContainer>
            {showDrawer?.media?.type === MEDIA_TYPE.IMAGE && (
              <PostImage src={showDrawer.media.image} alt="Post" />
            )}
            {showDrawer?.media?.type === MEDIA_TYPE.VIDEO && (
              <video controls autoPlay style={{ width: '100%' }}>
                <source src={showDrawer.media.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            {showDrawer?.media?.type === MEDIA_TYPE.TEXT && (
              <PostImage src={DefaultImage} alt="Post" />
            )}
          </ImageContainer>
          <BottomSection>
            <LikesSection>
              <ImageWrapper>
                <img src={FacebookLikesImage} alt="Like Icon" />
                <span>{post?.totalLikes ?? 0}</span>
              </ImageWrapper>
              <CountWrapper>
                <span>{comments.length } Comments</span>
              </CountWrapper>
            </LikesSection>
            <Divider />
            <SocialWrapper>
              <Button onClick={() => handleSubmitLike(showDrawer?.id)}>
                <LikeWrapper color={likedByMe ? '#0284c7' : ''}>
                  <img
                    src={
                      likedByMe
                        ? FacebookLikedThumbImage
                        : FacebookUnLikeThumbImage
                    }
                    alt="like"
                  />
                  <span>Like</span>
                </LikeWrapper>
              </Button>
              <Button onClick={() => setIsComment(!isComment)}>
                <CommentWrapper>
                  <img src={FacebookCommentImage} alt="Comment" />
                  <span>Comment</span>
                </CommentWrapper>
              </Button>
              <ShareWrapper>
                <img src={FacebookShareImage} alt="Share" />
                <span>Share</span>
              </ShareWrapper>
            </SocialWrapper>
            <Divider />
            {isComment && (
              <Comment
                postId={showDrawer.id}
                pageImage={showDrawer?.pageProfilePicture}
                pageName={showDrawer?.pageName}
                setModalOpen={setModalOpen}
                setUpdate={setUpdate}
              />
            )}
          </BottomSection>
          <FacebookCommentModal
            show={modalOpen}
            onHide={handleModalClose}
            showDrawer={showDrawer}
            setUpdate={setUpdate}
          />
        </Drawer.Body>
      </PreviewWrapper>
    </DrawerBorder>
  );
};

export default FaceBookPreview;
