import { Col } from 'rsuite';
import { formatNumber } from '../../../lib/helpers';
import theme from '../../../styles/theme';
import { Card } from '../Cards.styles';
import {
  Container,
  FooterP,
  NumberContainer,
  RoundedFlexbox,
  SocialIcon,
  TitleNumber,
} from './SocialCard.styles';

interface Props {
  data: Followers;
}

const colors = {
  facebook: theme.colors.facebook,
  twitter: theme.colors.twitter,
  instagram: theme.colors.instagram,
};

const SocialCard = ({ data }: Props) => {
  if (!data.followers) {
    return null;
  }

  return (
    <Col xs={12} lg={24}>
      <Card>
        <Container justify="space-around">
          <RoundedFlexbox colspan={7} color={colors[data.type]}>
            <SocialIcon icon={data.type} size="2x" />
          </RoundedFlexbox>
          <NumberContainer colspan={17}>
            <TitleNumber>{formatNumber(data.followers)}</TitleNumber>
            <FooterP>
              {data.type === 'facebook' ? 'Likes' : 'Followers'}
            </FooterP>
          </NumberContainer>
        </Container>
      </Card>
    </Col>
  );
};

export default SocialCard;
