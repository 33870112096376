import { Button, FlexboxGrid, Col, InputGroup } from 'rsuite';
import styled from 'styled-components';
import Select from 'react-select';

export const Container = styled.div`
  padding: 0 4rem 0 3rem;
  padding-bottom: 120px;

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    padding: 2rem 1rem;
  }
`;

export const HeaderContainer = styled(FlexboxGrid)`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justify};
  align-items: center;
  color: ${props => props.theme.colors.greyLetter};

  h1 {
    font-size: ${props => props.theme.fonts.bigSubtitle};
    color: ${props => props.theme.colors.black};
  }

  span {
    text-align: right;
    font-size: ${props => props.theme.fonts.paragraph};
    font-family: 'Poppins';

    @media ${props => props.theme.breakpoints.xs},
      ${props => props.theme.breakpoints.sm} {
      display: none;
    }
  }
`;

export const SaveButton = styled(Button)`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.green};
  margin-right: 25px;
  padding: 15px 30px;
  border-radius: 35px;
  font-family: 'Poppins';
  font-weight: 500;
`;

export const CardRight = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 100%;
`;
export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 100px;
  width: 100%;
  border-radius: 15px;
  @media ${props => props.theme.breakpoints.xs} {
    //  width: 100%;
    // float: none;
  }
`;

export const Card = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border-radius: 15px;
  padding: 30px;
  margin-top: 25px;

  h2 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.greyLetter};
    font-size: ${props => props.theme.fonts.subtitle};
    font-family: 'Poppins';
  }

  h1 {
    color: ${props => props.theme.colors.black};
    font-size: 20px;
    margin-bottom: 35px;
    font-family: 'Poppins';
    font-weight: 600;
  }

  form {
    width: 100%;
  }

  @media ${props => props.theme.breakpoints.xs} {
    width: 100%;
  }
`;
export const SelectStyle = styled(Select)`
  width: 100%;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.paragraph};
  font-family: 'Poppins';
  margin: 0px 35px 10px 10px;

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    margin-right: 0px;
  }
`;

export const ColSelect = styled(Col)`
  margin-bottom: 40px;
  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    margin-bottom: 0px;
  }
`;

export const IconSelect = styled(InputGroup.Addon)`
  background-color: transparent;
  padding: 0px;
  margin: 0px;
`;

export const GroupSelect = styled(InputGroup)`
  border: 0px !important;
  padding: 0px;
  margin: 0px;
`;

export const PError = styled.p`
  text-align: right;
  color: ${props => props.theme.colors.red};
  margin-right: 35px;
  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    margin-right: 0px;
  }
`;

export const BillingContainer = styled(FlexboxGrid)`
  border-radius: 15px;
  padding: 30px;
  margin-top: 25px;
  background-color: ${props => props.theme.colors.white};

  h2 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.greyLetter};
    font-size: ${props => props.theme.fonts.subtitle};
    font-family: 'Poppins';
  }
  h3 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.greyLetter};
    font-size: ${props => props.theme.fonts.subtitle};
    font-family: 'Poppins';
  }
  @media ${props => props.theme.breakpoints.xs} {
    padding: 0px;
  }
`;
