export const POSITION = {
  CENTER: 'CENTER',
  BOT: 'BOT',
  TOP: 'TOP',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export const COLOR_TYPES = {
  BACKGROUND: 'BACKGROUND',
  COLOR_TITLE: 'COLOR_TITLE',
  COLOR_SUBTITLE: 'COLOR_SUBTITLE',
};

export const TYPES_OF_SOCIAL_NETWORKS: {
  FACEBOOK: 'facebook';
  INSTAGRAM: 'instagram';
  TWITTER: 'twitter';
  TRIPADVISOR: 'tripadvisor';
  GOOGLE: 'google';
  JUST_EAT: 'justEat';
  UBER_EATS: 'uberEats';
  TIKTOK: 'tiktok';
} = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  TRIPADVISOR: 'tripadvisor',
  GOOGLE: 'google',
  JUST_EAT: 'justEat',
  UBER_EATS: 'uberEats',
  TIKTOK: 'tiktok',
};

export const PROVIDER = {
  EMAIL: 'EMAIL',
  SOCIAL: 'SOCIAL',
};

export const INITIAL_VALUES_PROVIDER = {
  provider: PROVIDER.EMAIL,
  userID: null,
  tokenID: '',
  socialProvider: '',
  data: { email: '', name: '', socialId: '' },
};

export const MEDIA_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
  REEL: 'reel',
  TEXT: 'text',
};

export const TOKEN = 'token';

export const LOGIN_PROVIDER_KEY = 'loginProviderKey';

export const STATUS_PROMOTIONS = {
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  PENDING_REVIEW: 'PENDING_REVIEW',
  CAMPAIGN_PAUSED: 'CAMPAIGN_PAUSED',
  DISAPPROVED: 'DISAPPROVED',
  WITH_ISSUES: 'WITH_ISSUES',
  IN_PROCESS: 'IN_PROCESS',
};

export const PASSWORD_ERROR_MESSAGE =
  'Password must be between 8 and 32 characters and it requires a capital letter, a lower case letter and a number in any order';
