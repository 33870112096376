import { useQuery } from 'react-query';
import { fetchReels } from '../services/reels';

const useReels = () => {
  return useQuery('REELS', fetchReels, {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
  });
};

export default useReels;
