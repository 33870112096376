import { Container } from './RectangleButton.styles';

interface Props {
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

const RectangleButton = ({ title, disabled, onClick }: Props) => {
  return (
    <Container disabled={disabled} onClick={onClick}>
      {title}
    </Container>
  );
};

export default RectangleButton;
