import React,{ useEffect, useState }  from 'react';
// import from 'react';
import { useHistory } from 'react-router-dom';
import {
  BackButtondiv,
  NextButtonContainer,
  Button,
  BackButtonnext,
  // socialcontainers,
} from './step0.styes';
 import {
  ContentContainer,
  Title,
  Subtitle,
  Titlembile,
  Subtitlemobile,
  SocialButton,
  SocialText,
  FlexboxGrid,
  FlexboxGridItem,
  // LinkForm,
  LinkForm2,
 } from './globaldinabite.style'
import RegisterButtons from '../../components/registerButtons/RegisterButtons';
import iconemail from '../../assets/icons/username.png';
import { useAuth } from '../../context/AuthContext';
import useUser from '../../hooks/useUser';

interface Step0Props {
  onNext: () => void;
}

const Step0: React.FC<Step0Props> = ({ onNext }: Step0Props) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(2);


  const handleSignInWithEmail = () => {
    history.push('/login');
  };

  const submit = () => {
    history.push('/sign-up');
  };

  return (

    <>
      <ContentContainer>
        <Title>Welcome to Dinabite</Title>
        <Subtitle>
          Dinabite is your ultimate marketing sidekick, combining
          cutting-edge AI with essential tools to help you achieve your
          marketing goals.
        </Subtitle>
        <Titlembile>Welcome to Dinabite</Titlembile>
        <Subtitlemobile>
          Dinabite is your ultimate marketing sidekick, combining
          cutting-edge AI with essential tools to help you achieve your
          marketing goals.
        </Subtitlemobile>
          <SocialButton onClick={handleSignInWithEmail}>
            <img
              src={iconemail}
              alt="Sign in with Username"
              style={{ width: '24px', height: '24px' }} // Adjust as needed
            />{' '}
            <SocialText>Sign in with Email</SocialText>
          </SocialButton>

          <RegisterButtons
            googleButtonText="Sign in with Google"
            facebookButtonText="Sign in with Facebook"
            signUP
            setCurrentStep={setCurrentStep} // Passing setCurrentStep function as a prop

          />

        <FlexboxGrid align="middle" justify="end">
          {/* <FlexboxGridItem>
            <LinkForm to="/forget-pass">Forgot Password?</LinkForm>
          </FlexboxGridItem> */}
          <FlexboxGridItem>
            <LinkForm2 to="/sign-up">Don&apos;t have an account?</LinkForm2>
          </FlexboxGridItem>
        </FlexboxGrid>
        <NextButtonContainer>
          <Button onClick={submit}>Sign Up</Button>
        </NextButtonContainer>
      </ContentContainer>

      {/* <DesktopBackButtonContainer>
      </DesktopBackButtonContainer> */} 
       <BackButtondiv>
        <BackButtonnext onClick={submit}>Sign Up</BackButtonnext>
      </BackButtondiv>
    </>
  );
};

export default Step0;
