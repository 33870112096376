// eslint-disable-next-line no-console

import { Formik } from 'formik';
import { useMemo ,useState} from 'react';
import { FlexboxGrid, InputGroup } from 'rsuite';
import ForwordIcon from '../../../assets/images/arrow_forward.png';
import useUser from '../../../hooks/useUser';
import PasswordInput from '../../inputs/PasswordInput/PasswordInput';

import {
  InputContainer,
  Label,
} from '../formSignUp/fieldInput/FieldInput.styles';
import {
  ButtonSign,
  FormContainer,
  InputIcon,
  LoginFormContainer,
  ErrorMessage,
  ButtonContainer,
  LinkForm,
  LoginParagraph,
} from './LoginForm.style';
import RegisterButtons from '../../registerButtons/RegisterButtons';
import { loginPageSchema } from '../formSignUp/validation';

interface LoginFormProps {
  width: number | undefined;
}

const LoginForm = ({ width }: LoginFormProps) => {
  const initialValues = useMemo(
    () => ({
      email: '',
      password: '',
    }),
    [],
  );

  const { login } = useUser();
  const [currentStep, setCurrentStep] = useState(2);

  const handleLogin = async (values: { email: string; password: string }) => {
    const { email, password } = values;
    await login(email, password);
  };

  return (
    <LoginFormContainer>
      <span className="form-title">Login to your account</span>
      <Formik
        initialValues={initialValues}
        validationSchema={loginPageSchema}
        onSubmit={handleLogin}
        validateOnMount>
        {({ touched, errors }) => {
          return (
            <>
              <FormContainer>
                <InputContainer>
                  <Label htmlFor="email">Email</Label>
                  <InputGroup inside>
                    <InputIcon
                      type="email"
                      name="email"
                      placeholder="enter your email"
                      error={touched.email && errors.email}
                    />
                  </InputGroup>

                  {touched.email && errors.email && (
                    <ErrorMessage>{errors.email}</ErrorMessage>
                  )}
                </InputContainer>

                <PasswordInput
                  signUp={false}
                  name="password"
                  error={touched.password && errors.password}
                  label="Password"
                  placeholder="enter your password"
                />

                <FlexboxGrid align="middle" justify="end">
                  <LinkForm to="/forget-pass">Forgot Password?</LinkForm>
                </FlexboxGrid>
                <ButtonContainer>
                  <ButtonSign block type="submit">
                    <span>Login</span>

                    {width && width > 768 && (
                      <img src={ForwordIcon} alt="Forword Icon" />
                    )}
                  </ButtonSign>
                  {width && width > 768 && (
                    <LoginParagraph to="/">
                      <span>Don&apos;t have an account yet?</span>
                      <span>SIGN UP</span>
                    </LoginParagraph>
                  )}
                </ButtonContainer>
              </FormContainer>
              <ButtonContainer>
                <RegisterButtons
                signUP
                  facebookButtonText="Continue with Facebook"
                  googleButtonText="Continue with Google"
                  setCurrentStep={setCurrentStep} // Passing setCurrentStep function as a prop

                />
              </ButtonContainer>
            </>
          );
        }}
      </Formik>
    </LoginFormContainer>
  );
};

export default LoginForm;
