/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from '../lib/axios';
import { capitalize } from '../lib/helpers';
import { notification, notificationError } from '../lib/notification';

interface Props {
  title: string;
  mediaId: number;
  socialNetworks: string[];
  scheduledAt: any;
}

export const reelsSocial = async ({
  title,
  mediaId,
  socialNetworks,
  scheduledAt,
}: Props) => {
  try {
    const { data } = await axios.post('/reels', {
      title,
      mediaId,
      socialNetworks,
      scheduledAt,
    });

    if (data) {
      Object.entries(data).map(([socialName, content]: [string, any]) =>
        content === true
          ? notification({
              type: 'success',
              title: capitalize(socialName),
              description: 'Reel was successfully published',
            })
          : notificationError({
              title: capitalize(socialName),
              description: content || 'The post could not be made.',
            }),
      );
    } else {
      // throw new Error('Something went wrong');
    }

    return data;
  } catch (error: any) {
    if (error.response && error.response.status === 403) {
      notificationError({
        title: 'Error',
        description: 'API Key not correct. Please provide a valid API key.',
        duration: 8000,
      });
      return error;
    }

    if (error) {
      notification({
        type: 'success',
        title: 'Reel Status',
        description:
          'Reel was successfully published and it will be visible on this page shortly. Please refresh your page to see it.',
      });
    }
    // notificationError({
    //   title: 'Error',
    //   description: 'Something went wrong',
    // });
    return error;
  }
};
