import { isMobile } from 'react-device-detect';
import { useQuery } from 'react-query';
import { Button, Loader, Modal } from 'rsuite';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import VideoIcon from '../../../assets/icons/video.svg';
import DefaultImage from '../../../assets/images/default.jpg';
import axios from '../../../lib/axios';
import { formatDate } from '../../../lib/format';
import {
  ButtonNotification,
  Container,
  CtaContainer,
  DateContainer,
  ImageContainer,
  ImageTextContainer,
  PostDate,
  PostImage,
  PostText,
  TextContainer,
  VideoImage,
} from '../../post/Post.styles';
import {
  ModalPosition,
  ModalSubtitle,
  ModalTitle,
} from '../modalTools/ModalTools.styles';
import { useAuth } from '../../../context/AuthContext';
import { notificationWithNodes } from '../../../lib/notification';

interface Props {
  show: boolean;
  onClose: () => void;
}

const fetchBoostablePosts = async (): Promise<Post[]> => {
  try {
    const { data } = await axios.get(`/posts/facebook/boostable`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

const BoostableModal = ({ show, onClose }: Props) => {
  const { user } = useAuth();
  const [displayPremiumPlan, setDisplayPremiumPlan] = useState<boolean>(false);
  const history = useHistory();
  const {
    isLoading,
    isError,
    data: posts,
  } = useQuery(`BOOSTABLE-POSTS`, fetchBoostablePosts, {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const { subscriptionStatus, invoiceStatus } =
      user?.companies?.[0]?.payment ?? {};

    if (subscriptionStatus === 'active' && invoiceStatus === 'paid') {
      setDisplayPremiumPlan(true);
    } else {
      setDisplayPremiumPlan(false);
    }
  }, [user]);

  const handleBoost = (id: string) => {
    if (!displayPremiumPlan) {
      notificationWithNodes({
        type: 'info',
        title: 'Upgrade your plan',
        description: (
          <>
            <p>
              You&apos;ll need to upgrade to a premium plan to activate this
              feature.
            </p>
            <ButtonNotification
              onClick={() => history.push('/user-panel/account')}
            >
              See plans
            </ButtonNotification>
          </>
        ),
        duration: 10000,
      });
    } else {
      // reload page, to be able to show embeded FB post
      window.location.href = `${window.location.origin}/user-panel/promotions/new/${id}`;
    }
  };

  return (
    <ModalPosition show={show} onHide={onClose} full={!isMobile}>
      <Modal.Header>
        <ModalTitle>Promote a post</ModalTitle>
        <ModalSubtitle>Select the post you want to promote</ModalSubtitle>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <Loader center content="loading" />}
        {!isLoading && !isError && posts && (
          <>
            {posts.map(post => (
              <Container>
                <ImageTextContainer>
                  {post?.media.image ? (
                    <ImageContainer onClick={() => handleBoost(post.id)}>
                      <PostImage src={post.media.image} alt="Post" />
                      {post.media.type === 'video' && (
                        <VideoImage
                          src={VideoIcon}
                          alt="Video"
                          style={{ position: 'absolute' }}
                        />
                      )}
                    </ImageContainer>
                  ) : (
                    <PostImage src={DefaultImage} alt="Post" />
                  )}
                  <TextContainer>
                    <PostText>{post.text}</PostText>
                  </TextContainer>
                </ImageTextContainer>
                <DateContainer>
                  <PostDate>{formatDate(post.publishedAt)}</PostDate>
                </DateContainer>

                <CtaContainer>
                  {post.isBoostable && (
                    <button type="button" onClick={() => handleBoost(post.id)}>
                      Boost
                    </button>
                  )}
                </CtaContainer>
              </Container>
            ))}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="subtle">
          Close
        </Button>
      </Modal.Footer>
    </ModalPosition>
  );
};

export default BoostableModal;
