import React, { useState } from 'react';
// import { InputGroup } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
// import * as Yup from 'yup';
import {
  StepHeaderContainer,
  CreateAccountTitle,
  // StepIndicator,
  // StepCircle,
  DesktopBackButtonContainer,
  NextButtonContainer,
  BackCancelContainer,
  Button,
  ContentContainer,
  BackButtondiv,
  DesktopBackButton,
  BackButton,
  BackButtonnext,
  StyledErrorMessage,
} from './dinabitesignin.styles';
import {
  Container,
  LeftPane,
  RightPane,
  ImageContainer,
  StyledImage,
  DinabiteText,
  Title,
  Subtitle,
  ErrorMessage,
  LinkForm,
  FlexboxGrid,
  FlexboxGridItem,
  MobileImageContent,
  Titlembile,
  Subtitlemobile,
  StyledInput,
} from '../DinabiteSignupPanel/globaldinabite.style';

import signupImage from '../../assets/images/Illustration (2).png';
import PasswordInput from '../../components/inputs/PasswordInput/PasswordInput';
import useUser from '../../hooks/useUser';
import { loginPageSchema } from '../../components/forms/formSignUp/validation';

const DinabiteSignin = () => {
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: '',
    password: '',
  };

  const { login } = useUser();
  const history = useHistory();

  const handleCancel = () => {
    history.push('/'); // Redirect to the home page
  };

  const handleLogin = async (values: { email: string; password: string }) => {
    const { email, password } = values;
    setIsLoading(true);
    await login(email, password);
    setIsLoading(false);
  };

  const title = 'Sign In';

  return (
    <>
      <Container>
        <LeftPane>
          <StepHeaderContainer>
            <CreateAccountTitle>{title}</CreateAccountTitle>
            {/* <StepIndicator>
              {[0, 1, 2, 3, 4].map(step => (
                <StepCircle key={step} active={step === 1} />
              ))}
            </StepIndicator> */}
          </StepHeaderContainer>
          <MobileImageContent src={signupImage} alt="Step 1 Image" />

          <Formik
            initialValues={initialValues}
            validationSchema={loginPageSchema}
            onSubmit={handleLogin}
            validateOnMount>
            {({
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <ContentContainer>
                  <Title>Welcome back</Title>
                  <Subtitle>Hey there! Pease Sign in below!</Subtitle>
                  <Titlembile>Sign In</Titlembile>
                  <Subtitlemobile>
                    Please enter your Username and password
                  </Subtitlemobile>

                  <StyledInput
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isError={!!(touched.email && errors.email)}
                  />
                  <StyledErrorMessage>
                    {(touched.email && errors.email)}
                  </StyledErrorMessage>
                 

                  <PasswordInput
                    signUp
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // error={
                    //   touched.password && errors.password
                    //     ? errors.password
                    //     : undefined
                    // }
                  />
                     <StyledErrorMessage>
                    {touched.password && errors.password
                      ? errors.password
                      : undefined}
                  </StyledErrorMessage>
                  <FlexboxGrid align="middle" justify="end">
                    <FlexboxGridItem>
                      <LinkForm to="/forget-pass">Forgot Password?</LinkForm>
                    </FlexboxGridItem>
                    {/* <FlexboxGridItem>
                    <LinkForm to="/sign-up">Don&apos;t have account?</LinkForm>
                    </FlexboxGridItem> */}
                  </FlexboxGrid>

                  <DesktopBackButtonContainer>
                    <NextButtonContainer>
                      <Button
                        type="submit"
                        disabled={isLoading || isSubmitting}>
                        {isLoading ? 'Loading...' : 'Sign In'}
                      </Button>
                    </NextButtonContainer>
                    <BackCancelContainer>
                      <DesktopBackButton onClick={handleCancel}>
                        Back
                      </DesktopBackButton>
                    </BackCancelContainer>
                  </DesktopBackButtonContainer>

                  <BackButtondiv>
                    <BackButton onClick={handleCancel}>Back</BackButton>
                    <BackButtonnext
                      type="submit"
                      disabled={isLoading || isSubmitting}>
                      {isLoading ? 'Loading...' : 'Sign In'}
                    </BackButtonnext>
                  </BackButtondiv>
                </ContentContainer>
              </form>
            )}
          </Formik>
        </LeftPane>

        <RightPane>
          <ImageContainer>
            <StyledImage src={signupImage} alt="Illustration" />
            <DinabiteText>
              Exciting times ahead! With Dinabite you will be posting, managing
              messages, reviews, and promotions on all social media and
              marketing platforms. All very easily, and like a pro.
              <br />
              Let’s get started!
            </DinabiteText>
          </ImageContainer>
        </RightPane>
      </Container>

      {/* Mobile View */}
    </>
  );
};

export default DinabiteSignin;
