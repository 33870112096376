/* eslint-disable @typescript-eslint/no-explicit-any */
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useHistory } from 'react-router-dom';
import FacebookIcon from '../../assets/images/facebook-round.png';
import GoogleIcon from '../../assets/images/google-round.png';
import useUser from '../../hooks/useUser';
import { PRIVILEGIES_SCOPE } from '../../lib/initFacebookSdk';
import { SCOPE_BUSINESS } from '../../lib/initGoogle';
import { notification } from '../../lib/notification';
import { SocialButton } from '../forms/newLoginForm/LoginForm.style';

interface IProps {
  signUP: boolean;
  googleButtonText: string;
  facebookButtonText: string;
  setCurrentStep: (step: number) => void; // Add this prop
}

const RegisterButtons = ({
  // showEmailButton,
  signUP=false,
  facebookButtonText,
  googleButtonText,
  setCurrentStep, // Use this function from props

}: IProps) => {
  const { loginSocial } = useUser();
  const history = useHistory();

  const responseSuccessGoogle = async (credentialResponse: any) => {
    // console.log("success hva ha juu ")
    try {
      const parentUrl =
        window.location.ancestorOrigins && window.location.ancestorOrigins[0];
      const tokenId = credentialResponse.access_token;
      // console.log("token id",tokenId)
      // console.log("parentUrl",parentUrl)
      let gmailAccount;
      if (parentUrl) {
        // console.log("called loginsocial 1")
        gmailAccount = await loginSocial(tokenId, 'google', undefined, true);

      } else {
        // console.log("called loginsocial 2")
        gmailAccount = await loginSocial(tokenId, 'google');

      }

      // console.log("after parent url")
      // const parentDomain = 'https://localhost:3000/';
      const parentDomain = 'https://dinabite.ai';
      const appDomain = process?.env?.REACT_APP_APP_DOMAIN || '';
      // console.log("parenttDomain",parentDomain);
      // console.log("parentUrl",parentUrl);
      if (parentUrl && parentUrl === parentDomain) {
        // If the current URL is outside the parent domain, use postMessage to communicate with the parent page
        /* eslint-disable */
        if (gmailAccount.data?.register) {
          setCurrentStep(2);
          // console.log("routing reached here")
          window.parent.postMessage(
            { message: 'loadNextPage', url: '/sign-up' },
            parentDomain,
          );
        } else {
          window.parent.postMessage(
            { message: 'loadNextPage', url: '/user-panel/dashboard' },
            parentDomain,
          );
        }
        /* eslint-enable */
      } else if (window.location.href.startsWith(appDomain)) {
        // if (gmailAccount.data?.register) {
        //   console.log("heeyyy")

        //   history.push('/');
        if (gmailAccount.data?.register) {
          // console.log("setCurrentstep",setCurrentStep)
          // console.log("routing reached here")
          // window.parent.postMessage(
          //   { message: 'loadNextPage', url: '/sign-up' },
          //   parentDomain,
          // );
          history.push('/sign-up', { step: 2 });
          setCurrentStep(2);

        } else {
          // console.log("hoooooooo")

          history.push('/user-panel/dashboard');
        }
      }
    } catch (error) {

      console.log("error happened catch ")
      notification({
        type: 'info',
        title: 'Info',
        description: 'Could not sign in google.',
      });
    }
  };

  const googleSignIn = useGoogleLogin({
    onSuccess: responseSuccessGoogle,
    flow: 'implicit',
    scope: SCOPE_BUSINESS,
  });

  const responseFacebook = async ({
    accessToken,
    userID,
  }: {
    accessToken: string;
    userID?: string | undefined;
  }) => {
    const parentUrl =
      window.location.ancestorOrigins && window.location.ancestorOrigins[0];
    let facebookAccount;

    if (parentUrl) {
      facebookAccount = await loginSocial(
        accessToken,
        'facebook',
        userID,
        true,
      );
    } else {
      facebookAccount = await loginSocial(accessToken, 'facebook', userID);
    }

    const parentDomain = 'https://dinabite.ai';
    const appDomain = process?.env?.REACT_APP_APP_DOMAIN || '';

    if (parentUrl === parentDomain) {
      // If the current URL is outside the parent domain, use postMessage to communicate with the parent page
      /* eslint-disable */
      if (facebookAccount.data?.register) {
        window.parent.postMessage(
          // { message: 'loadNextPage', url: '/' },
          { message: 'loadNextPage', url: '/sign-up' },

          parentDomain,
        );
        setCurrentStep(2);

      } else {
        window.parent.postMessage(
          { message: 'loadNextPage', url: '/user-panel/dashboard' },
          parentDomain,
        );

      }
      /* eslint-enable */
    } else if (window.location.href.startsWith(appDomain)) {
      // If the current URL is within the parent domain, use history.push to navigate
      if (facebookAccount.data?.register) {
        // history.push('/sign-up');
        history.push('/sign-up', { step: 2 });

        setCurrentStep(2);
      } else {
        history.push('/user-panel/dashboard');
      }
    }
  };

  return (
    <>
      <SocialButton onClick={() => googleSignIn()} signUP={signUP}>
        <img src={GoogleIcon} alt={googleButtonText} />
        <span className="button-title"
         style={{ 
          fontFamily: signUP ? 'Inter, sans-serif' : 'inherit',
          textAlign: 'left',
          marginLeft: signUP ? '10px' : 'inherit',
          // flex: 1,
        }}
        > {` ${googleButtonText}`}</span>
      </SocialButton>
      <FacebookLogin
        appId={process?.env?.REACT_APP_API_FACEBOOK || ''}
        autoLoad={false}
        callback={responseFacebook}
        fields="email,id"
        scope={PRIVILEGIES_SCOPE}
        render={renderProps => (
          <SocialButton
            onClick={() => renderProps.onClick && renderProps.onClick()}
            disabled={!process?.env?.REACT_APP_API_FACEBOOK}signUP={signUP}>
            <img src={FacebookIcon} alt={facebookButtonText} />
            <span className="button-title"
             style={{ 
              fontFamily: signUP ? 'Inter, sans-serif' : 'inherit',
              textAlign: 'center',
              marginRight: signUP ? '-5px' : 'inherit',

              // flex: 1,
            }}> {` ${facebookButtonText}`}</span>
          </SocialButton>
        )}
      />
    </>
  );
};

export default RegisterButtons;
