/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from '../lib/axios';
import { notificationError } from '../lib/notification';

interface User {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
}

interface ValuesForm extends User {
  name: string;
  country: string;
  accountType?: string;
}

interface ValuesFormEmail extends User {
  password: string;
}

interface CreateCompanyWithUserId {
  userId: number;
  name: string;
  country: string;
  accountType?: string;
}

export const createUser = async (user: ValuesFormEmail) => {
  try {
    const { firstName, lastName, email, password } = user;
    // console.log("create user data is ");
    // console.log("firstName",firstName);
    // console.log("lastName",lastName);
    // console.log("email",email)
    // console.log("password",password)
    const response = await axios.post('users', {
      firstName,
      lastName,
      email,
      password,
    });

    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: error.response.data.message,
    });
    return error;
  }
};

export const createUserEmail = async (
  values: CreateCompanyWithUserId,
  onSuccess: any,
) => {
  try {
    const { name, country, userId } = values;
    const response = await axios.post('companies/company-user', {
      name,
      country,
      userId,
    });
    onSuccess();
    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: error.response.data.message,
    });
    return error;
  }
};

export const createUserSocial = async (values: ValuesForm, onSuccess: any) => {
  try {
    const { firstName, lastName, email, name, country, accountType, password } =
      values;
      // console.log("Signup Values for api call")
      // console.log("firstname",firstName)
      // console.log("lastName",lastName)
      // console.log("email",email)
      // console.log("name",name)
      // console.log("country",country)
      // console.log("password",password)
    const response = await axios.post('companies/company-user', {
      firstName,
      lastName,
      name,
      email,
      country,
      accountType,
      password,
    });
    onSuccess();
    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: error.response.data.message,
    });
    return error;
  }
};
