/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable consistent-return */
import { format, isAfter, isBefore, isToday, subDays } from 'date-fns';
import { useFormik } from 'formik';
import html2canvas from 'html2canvas';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Col, Divider, FlexboxGrid, Loader, Row } from 'rsuite';
import EmojiPicker from 'emoji-picker-react';
import { TYPES_POSTS } from '../../actions/postsActions';
import DownloadIcon from '../../assets/icons/download.svg';
import UploadIcon from '../../assets/icons/upload.svg';
import { useAuth } from '../../context/AuthContext';
import useMedia from '../../hooks/useMedia';
import useTextarea from '../../hooks/useTextarea';
import useToggle from '../../hooks/useToggle';
import { dataURLtoFile } from '../../lib/encodeImg';
import { formatDate } from '../../lib/format';
import { POSITION, TYPES_OF_SOCIAL_NETWORKS } from '../../lib/globalValues';
import { isImageType } from '../../lib/mediaType';
import {
  notification,
  notificationError,
  notificationWithNodes,
} from '../../lib/notification';
import { selectImgStatus } from '../../lib/toogleImgStatus';
import { initialPostsState, postsReducer } from '../../reducers/postsReducer';
import { fileUpload } from '../../services/fileUpload';
import { getToolsCompany } from '../../services/getToolsCompany';
import { postsSocial } from '../../services/postsSocial';
import tagsPost from '../../services/tagsPost';
import { Container } from '../../styles/generalStyles';
import ColorsContent from '../colorsContent/ColorsContent';
import IconInput from '../inputs/IconInput/IconInput';
import ModalCropperImage from '../modals/modalCrop/ModalCropperImage';
import ModalSelectMedia from '../modals/modalSelectMedia/ModalSelectMedia';
import PositionContent from '../positionContent/PositionContent';
import UploaderImage from '../uploader/uploderImage/UploaderImage';
import {
  AiTextDisplayContainer,
  BigTitleImg,
  ButtonAdd,
  ButtonDashed,
  ButtonGroupCenter,
  ButtonNotification,
  ButtonTags,
  ButtonWrapper,
  ClickableTitleForm,
  ContainerEdit,
  ContainerPreview,
  CounterTextarea,
  DateInputContainer,
  DateTimeRounded,
  DivForm,
  DivImg,
  DivPosition,
  IconSmiley,
  ImgTools,
  LoaderCenter,
  ParagraphAdvert,
  Subtitle,
  SubtitleImg,
  TagsDisplayContainer,
  TagsDisplayWrapper,
  TextAreaWrapper,
  TextWrapper,
  Textarea,
  TitleButton,
  TitleForm,
  TopContainer,
  GlobalStyle,
  ButtonAdd2,
} from './CreatePost.styles';
// import GlobalStyle from './GlobalStyle.styles'; // Import the GlobalStyle component

import smileIcon from '../../assets/images/smile.png';
import { generateAiTextFromImage } from '../../services/generateAiTextFromImage';

interface PostForm {
  postText: string;
  title: string;
  subtitle: string;
}

const positionDesign = {
  alignTitle: POSITION.CENTER,
  positionTitle: POSITION.TOP,
  alignSubtitle: POSITION.CENTER,
  positionSubtitle: POSITION.CENTER,
};

const colorsDesign = {
  background: 'white',
  colorTitle: 'black',
  colorSubtitle: 'black',
};

interface TagDataItem {
  tag: string;
  score: number;
}

interface ImageSelectionState {
  imgFile: File | null;
  state: boolean;
  uploadedUrl?: string;
}

const ALLOWED_VIDEO_TYPES = ['video/mp4', 'video/quicktime'];
const MAX_CHARACTERS = 2200;
const MAX_CHARACTERS_TWITTER = 280;

const CreatePost = () => {
  const queryClient = useQueryClient();
  const { user, companySelected } = useAuth();
  const { isOpen: showCropImage, toggleChange: toggleShowCropImage } =
    useToggle();
  const { isOpen: showSelectMedia, toggleChange: toggleShowSelectMedia } =
    useToggle();
  const { counterTextLength, handleCounterTextLength } = useTextarea();

  // initialization states for storing values //
  const [isGenerating, setIsGenerating] = useState(false);
  const [postText, setPostText] = useState('');
  const [tagData, setTagData] = useState<any[]>([]);
  const [shouldGenerate, setShouldGenerate] = useState(false);

  const [generatedAiText, setGeneratedAiText] = useState('');
  const [isGeneratingAiText, setIsGeneratingAiText] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState<ImageSelectionState>({
    imgFile: null,
    state: false,
  });

  const [statePost, dispatchPost] = useReducer(postsReducer, initialPostsState);
  const [selectedDate, setSelectedDate] = useState<null | Date>(null);
  const [selectedTime, setSelectedTime] = useState<null | Date>(null);
  const [tools, setTools] = useState<ToolAPI[]>([]);
  const [showPersonaliseImage, setShowPersonaliseImage] = useState(false);
  const [maxCharacters, setMaxCharacters] = useState(MAX_CHARACTERS);

  const [socialNetworks, setSocialNetworks] = useState<string[]>([]);
  const [sendingImg, setSendingImg] = useState<boolean>(false);

  const [componentPosition, setComponentPosition] = useState(positionDesign);
  const [componentColor, setComponentColor] = useState(colorsDesign);
  const [loading, setLoading] = useState(false);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const emojiPickerRef = useRef<HTMLDivElement>(null);

  const [cropper, setCropper] = useState<Cropper | undefined>();

  const [displayPremiumPlan, setDisplayPremiumPlan] = useState<boolean>(false);

  const { data: dataMedia } = useMedia(companySelected?.id);
  const history = useHistory();

  const videoNotAcceptedByGoogle = () => {
    const newSocialMedia = socialNetworks.filter(
      socialNetwork => socialNetwork !== TYPES_OF_SOCIAL_NETWORKS.GOOGLE,
    );

    setSocialNetworks(newSocialMedia);
  };

  const toManyCharactersTwitter = () => {
    const newSocialMedia = socialNetworks.filter(
      socialNetwork => socialNetwork !== TYPES_OF_SOCIAL_NETWORKS.TWITTER,
    );

    setSocialNetworks(newSocialMedia);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      dispatchPost({
        type: TYPES_POSTS.CROP_IMAGE,
        payload: cropper.getCroppedCanvas().toDataURL(),
      });
    }
  };

  const rContainer = useRef<HTMLDivElement>(null);
  const personaliseImageRef = useRef<HTMLDivElement>(null);

  const getTools = async () => {
    const { data } = await getToolsCompany();
    if (data) {
      const toolsEnabled = data.filter((tool: ToolAPI) => {
        return tool.enabled && tool.type !== 'uberEats';
      });

      //  to avoid any duplicate items
      const uniqueData: ToolAPI[] = Array.from(toolsEnabled.reduce((map: Map<string, ToolAPI>, item: ToolAPI) => map.has(item.type) ? map : map.set(item.type, item), new Map<string, ToolAPI>()).values());

      setTools(uniqueData);
    }
  };

  useEffect(() => {
    if (companySelected) {
      getTools();
    }
  }, [companySelected]);

  useEffect(() => {
    const { subscriptionStatus, invoiceStatus } =
      user?.companies?.[0]?.payment ?? {};

    if (subscriptionStatus === 'active' && invoiceStatus === 'paid') {
      setDisplayPremiumPlan(true);
    } else {
      setDisplayPremiumPlan(false);
    }
  }, [user]);

  const { mutateAsync } = useMutation(
    (selectedFile: { selectedFile: File; type: string }) =>
      fileUpload(selectedFile),
    {
      onSuccess(response) {
        queryClient.setQueryData('MEDIA', (oldData: any) => {
          return {
            ...oldData,
            data: [
              {
                ...response.data,
              },
              ...oldData.data,
            ],
          };
        });
      },
    },
  );

  const uploadFile = async (selectedFile: File, type: string) => {
    const { data: dataMediaAdded } = await mutateAsync({ selectedFile, type });
    return dataMediaAdded;
  };
  const handleFileUpload = async (selectedFile: File) => {
    const urlFile = await URL.createObjectURL(selectedFile);

    if (selectedFile.type.includes('image')) {
      setIsImageSelected({ imgFile: selectedFile, state: true });
      dispatchPost({
        type: TYPES_POSTS.ADD_IMAGE_URL,
        payload: { urlFile, name: selectedFile.name },
      });
    } else if (ALLOWED_VIDEO_TYPES.includes(selectedFile.type)) {
      setIsImageSelected({ imgFile: null, state: false });
      dispatchPost({
        type: TYPES_POSTS.ADD_VIDEO_URL,
        payload: urlFile,
      });

      dispatchPost({
        type: TYPES_POSTS.ADD_FILE,
        payload: selectedFile,
      });
      videoNotAcceptedByGoogle();
    } else {
      notificationError({
        title: 'Cannot upload file',
        description: 'Only images or videos (.mov or .mp4) are allowed',
      });
    }
  };

  const saveImage = async () => {
    const node = await rContainer.current;

    if (!node) return;

    const generalDiv = node?.children;
    const takeImg = generalDiv[0]?.children;

    const tagName = takeImg[0]?.tagName;
    let originalHeight = 500;
    let originalWidth = 500;

    if (tagName === 'IMG') {
      originalHeight = takeImg[0]?.clientHeight || 500;
      originalWidth = takeImg[0]?.clientWidth || 500;

      if (originalWidth / originalHeight < 0.81) {
        originalWidth = (originalHeight * 81) / 100;
      }

      if (originalWidth / originalHeight > 1.89) {
        originalHeight = (originalWidth / 189) * 100;
      }
    }

    const htmltocanva = await html2canvas(node, {
      onclone: clonedNode => {
        if (clonedNode) {
          const containerPreview =
            clonedNode.getElementById('container-preview');

          if (containerPreview) {
            containerPreview.style.maxWidth = '1000px';
            containerPreview.style.maxHeight = '1000px';
            containerPreview.style.width = `${originalWidth}px`;
            containerPreview.style.height = `${originalHeight}px`;
          }
        }
      },
    });

    const dataURL = htmltocanva.toDataURL('image/jpeg');

    const encoded = await dataURLtoFile(dataURL, statePost.name);

    const img = new Image();
    const objectUrl = URL.createObjectURL(encoded);
    img.src = objectUrl;

    const uploaded = await uploadFile(encoded, 'posts');

    return uploaded;
  };

  const handlePost = async () => {
    try {
      setLoading(true);

      let responseId = statePost.id;
      const hasTextOnImage =
        postPublicationFormik.values.subtitle ||
        postPublicationFormik.values.title;

      // Check for empty postText
      if (!postText || postText.trim() === '') {
        throw new Error('Post text cannot be empty or whitespace only.');
      }

      // Check if only Twitter is selected
      const isOnlyTwitterSelected =
        socialNetworks.length === 1 &&
        socialNetworks.includes(TYPES_OF_SOCIAL_NETWORKS.TWITTER);

      // Check if Twitter is selected among other networks
      const isTwitterSelected = socialNetworks.includes(
        TYPES_OF_SOCIAL_NETWORKS.TWITTER,
      );

      if (!statePost.id || hasTextOnImage || isOnlyTwitterSelected) {
        if (
          !isImageType(statePost.type) &&
          !isOnlyTwitterSelected &&
          postText !== ''
        ) {
          const response = await uploadFile(statePost.file, 'posts');
          responseId = response?.id;
        } else if (
          (hasTextOnImage || statePost.previewUrl) &&
          !isOnlyTwitterSelected
        ) {
          setSendingImg(true);
          const response = await saveImage();
          responseId = response?.id;
        } else if (isOnlyTwitterSelected) {
          // Twitter is the only selected social network, no media is required
          // Generate a random mediaId between 1 and 100
          responseId = Math.floor(Math.random() * 100) + 1;
        } else if (isTwitterSelected && socialNetworks.length > 1) {
          throw new Error('Posts without image or video are not allowed.');
        } else {
          throw new Error('Posts without image or video are not allowed.');
        }
      }

      let scheduledAt: null | Date = null;

      if (
        selectedDate &&
        selectedTime &&
        isAfter(getSelectedDate(), new Date())
      ) {
        scheduledAt = getSelectedDate();
      }

      await postsSocial({
        title:
          postPublicationFormik.values.postText ||
          postPublicationFormik.initialValues.postText,
        mediaId: responseId,
        socialNetworks,
        scheduledAt,
      });

      history.push('/user-panel/posts');
    } catch (err) {
      notificationError({
        title: 'Error',
        description: err?.message || '',
      });
    } finally {
      setLoading(false);
      setSendingImg(false);
    }
  };
  
  const getSelectedDate = () => {
    if (selectedDate && selectedTime) {
      const date = selectedDate?.setHours(
        selectedTime?.getHours(),
        selectedTime?.getMinutes(),
        0,
        0,
      );

      return new Date(date);
    }

    return new Date();
  };

  const postPublicationFormik = useFormik<PostForm>({
    initialValues: {
      postText: '',
      title: '',
      subtitle: '',
    },
    onSubmit: async () => {
      if (!socialNetworks.length) {
        notificationError({
          title: 'Error',
          description: 'Select a social network',
        });
        return;
      }

      await handlePost();
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    const isTwitterSelected = socialNetworks.find(
      socialNetwork => socialNetwork === TYPES_OF_SOCIAL_NETWORKS.TWITTER,
    );

    if (isTwitterSelected) {
      setMaxCharacters(MAX_CHARACTERS_TWITTER);
    } else {
      setMaxCharacters(MAX_CHARACTERS);
    }
  }, [socialNetworks]);

  const handleGenerateTags = async () => {
    try {
      if (postText !== '') {
        if (displayPremiumPlan) {
          setIsGenerating(true);
          const apiResponse = await tagsPost.generateTags(postText);
          setIsGenerating(false);
          const dataWithIndex = apiResponse.map((item: any, index: any) => ({
            ...item,
            tag: item.tag.replace(/\s+/g, ''),
            index,
          }));
          setTagData(dataWithIndex);
        } else {
          notificationWithNodes({
            type: 'info',
            title: 'Upgrade your plan',
            description: (
              <>
                <p>
                  You&apos;ll need to upgrade to a premium plan to activate this
                  feature.
                </p>
                <ButtonNotification
                  onClick={() => history.push('/user-panel/account')}>
                  See plans
                </ButtonNotification>
              </>
            ),
            duration: 10000,
          });
        }
      } else {
        notificationError({
          title: 'Error',
          description: 'Please enter post text for hashtag suggestion.',
        });
        setIsGenerating(true);
      }
    } catch (error: any) {
      setIsGenerating(false);

      if (error && typeof error.message === 'string') {
        const { message } = error;

        switch (message) {
          case "You'll need to upgrade to a premium plan to activate this feature":
            notificationWithNodes({
              type: 'info',
              title: 'Upgrade your plan',
              description: (
                <>
                  <p>
                    You&apos;ll need to upgrade to a premium plan to activate
                    this feature.
                  </p>
                  <ButtonNotification
                    onClick={() => history.push('/user-panel/account')}>
                    See plans
                  </ButtonNotification>
                </>
              ),
              duration: 10000,
            });

            break;

          default:
            // Handle other errors
            break;
        }
      } else {
        // Handle cases where error is not in expected format
      }
    }
  };

  const handleGenerateAiTextPost = async () => {
    try {
      if (isImageSelected.state && isImageSelected.imgFile instanceof File) {
        if (displayPremiumPlan) {
          setIsGeneratingAiText(true);

          const apiResponse = await generateAiTextFromImage({
            selectedFile: isImageSelected.uploadedUrl ? undefined : isImageSelected.imgFile,
            url: isImageSelected.uploadedUrl,
          });
          if(apiResponse?.data?.sourceUrl) {
            setIsImageSelected({...isImageSelected, uploadedUrl: apiResponse?.data.sourceUrl })
          }
          setGeneratedAiText(apiResponse?.data.analysis);
          setIsGeneratingAiText(false);
        } else {
          notificationWithNodes({
            type: 'info',
            title: 'Upgrade your plan',
            description: (
              <>
                <p>
                  You&apos;ll need to upgrade to a premium plan to activate this
                  feature.
                </p>
                <ButtonNotification
                  onClick={() => history.push('/user-panel/account')}>
                  See plans
                </ButtonNotification>
              </>
            ),
            duration: 10000,
          });
        }
      } else {
        notificationError({
          title: 'Error',
          description: 'Please add image for Post text suggestion.',
        });
        setIsGeneratingAiText(true);
      }
    } catch (error: any) {
      setIsGeneratingAiText(false);

      if (error && typeof error.message === 'string') {
        const { message } = error;

        switch (message) {
          case "You'll need to upgrade to a premium plan to activate this feature":
            notificationWithNodes({
              type: 'info',
              title: 'Upgrade your plan',
              description: (
                <>
                  <p>
                    You&apos;ll need to upgrade to a premium plan to activate
                    this feature.
                  </p>
                  <ButtonNotification
                    onClick={() => history.push('/user-panel/account')}>
                    See plans
                  </ButtonNotification>
                </>
              ),
              duration: 10000,
            });

            break;

          default:
            // Handle other errors
            break;
        }
      } else {
        // Handle cases where error is not in expected format
      }
    }
  };

  // Using useEffect because we need to trigger the generateTags fuction base on user typing
  useEffect(() => {
    // Created variable to clear the setTimeout
    let typingTimer: any;

    // This function trigger the API base on typing
    const handleTyping = () => {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        if (shouldGenerate) {
          handleGenerateTags();
        }
      }, 1000); // Adjust the delay as needed (in milliseconds)
    };

    // Added if condition to run the funtion when user type something
    if (postText !== '' && postText.trim() !== '') {
      handleTyping(); // Trigger API call if postText is not empty and not just whitespace
    }

    return () => {
      clearTimeout(typingTimer);
    };
    // useEffect only be trigger when user type something
  }, [postText, shouldGenerate]);

  // If user click any hashTag, so just removed the objec from the array
  function removeTagFromArray(array: TagDataItem[], tag: string) {
    if (!Array.isArray(array)) {
      return array;
    }

    // filter out the item with the provided tag
    // ignore case and leading/trailing spaces
    return array.filter(
      item => item.tag.trim().toLowerCase() !== tag.trim().toLowerCase(),
    );
  }

  // this function will added the hastage before the each Word
  const handleTagButtonClick = (tag: string) => {
    const sanitizedTag = tag.replace(/\s+/g, ''); // This removes all spaces from the tag
    const updatedResult = removeTagFromArray(tagData, sanitizedTag);

    setTagData(updatedResult);

    const sampleTextWithoutHash = postText.replace(/#$/, '').trim();
    const newSampleText = `${sampleTextWithoutHash}${
      sampleTextWithoutHash ? ' ' : ''
    }#${sanitizedTag}`;

    // set postText state
    setPostText(newSampleText);

    // also update Formik's state
    postPublicationFormik.setFieldValue('postText', newSampleText);

    setShouldGenerate(false);
  };

  // This function only trigger the API when # found in the textarea and if # is remove from the textarea below res will gone
  const handleChange = (e: any) => {
    setPostText(e.target.value);

    postPublicationFormik.setFieldValue('postText', e.target.value); // set posttext value also incase tags button isnt pressed

    handleCounterTextLength(e.target.textLength || 0);

    if (e.target.value.includes('#')) {
      setShouldGenerate(true);
    } else {
      setShouldGenerate(false);
      setTagData([]);
    }
  };

  const toggleEmojiPicker = () => {
    if (!displayPremiumPlan) {
      notificationWithNodes({
        type: 'info',
        title: 'Upgrade your plan',
        description: (
          <>
            <p>
              You&apos;ll need to upgrade to a premium plan to activate this
              feature.
            </p>
            <ButtonNotification
              onClick={() => history.push('/user-panel/account')}>
              See plans
            </ButtonNotification>
          </>
        ),
        duration: 10000,
      });
    } else {
      setIsEmojiPickerOpen(!isEmojiPickerOpen);
    }
  };

  const closeEmojiPicker = () => {
    setIsEmojiPickerOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target as Node)
    ) {
      closeEmojiPicker();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleEmojiClick = (emojiObject: any) => {
    setPostText(postText + emojiObject.emoji);
    postPublicationFormik.setFieldValue('postText', postText);
  };

  const handleAiTextButtonClick = (generatedText: string) => {
    const generateText = postText
      ? `${postText} ${generatedText}`
      : generatedText;
    setPostText(generateText);

    const newTextLength = generateText.length || 0;
    const newCounterValue = counterTextLength + newTextLength;

    handleCounterTextLength(newCounterValue);

    postPublicationFormik.setFieldValue('postText', generateText);
  };

  if (!companySelected?.id) {
    return <Loader center content="loading" />;
  }
  const triggereduploadedfiles = () => {
    toggleShowSelectMedia();
    // setIsGeneratingAiText(true); 
    setIsGeneratingAiText(false); // Likewise, check if this is necessary
    // setIsImageSelected(prevState => ({
    //   ...prevState,
    //   state: true,
    // }));
    // Access the selected file here
   // setIsImageSelected({ imgFile: statePost, state: false });

  };

  const handleBackClick=()=>{
    history.push('/user-panel/posts');
  }
  return (
    <Container>
      <Row>
        <Col xs={24} lg={12}>
          <Subtitle>New Post</Subtitle>
          <ButtonAdd2 type="button" onClick={handleBackClick}>
            Back
          </ButtonAdd2>
          <TitleForm>Select Tools</TitleForm>
          {tools.map(tool => (
            <label htmlFor={tool.type} key={tool.accessToken}>
              <input
                style={{ opacity: 0 }}
                id={tool.type}
                type="checkbox"
                name={tool.type}
                onChange={e => {
                  if (e.target.checked) {
                    setSocialNetworks([...socialNetworks, tool.type]);
                  } else {
                    const newSocialMedia = socialNetworks.filter(
                      socialNetwork => socialNetwork !== tool.type,
                    );
                    setSocialNetworks(newSocialMedia);
                  }
                  if (
                    tool.type === TYPES_OF_SOCIAL_NETWORKS.GOOGLE &&
                    !isImageType(statePost.type)
                  ) {
                    videoNotAcceptedByGoogle();
                    notificationError({
                      title: 'Error',
                      description: 'Google does not allow you to post videos.',
                    });
                  }
                  if (
                    tool.type === TYPES_OF_SOCIAL_NETWORKS.TWITTER &&
                    postPublicationFormik.values.postText.length > 280
                  ) {
                    toManyCharactersTwitter();
                    notificationError({
                      title: 'Error',
                      description:
                        'Twitter accepts text of maximum 280 characters',
                    });
                  }
                  if (tool.type === TYPES_OF_SOCIAL_NETWORKS.TWITTER) {
                    notification({
                      type: 'info',
                      title: 'Twitter Media',
                      description:
                        'This post will be published on Twitter as text only',
                    });
                  }
                }}
              />
              <ImgTools
                src={
                  selectImgStatus(tool.type, socialNetworks.includes(tool.type))
                    .image
                }
                alt={tool.type}
              />
            </label>
          ))}
          <TitleForm>Text</TitleForm>
          <TextAreaWrapper ref={emojiPickerRef}>
            <Textarea
              onClick={closeEmojiPicker}
              placeholder="Enter Post Text"
              rows={4}
              // added the hashTag Value //
              value={postText}
              maxLength={maxCharacters}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                handleChange(e);
              }}
            />
            <IconSmiley
              src={smileIcon}
              alt="smiley icon"
              onClick={toggleEmojiPicker}
            />
            {isEmojiPickerOpen && (
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            )}
          </TextAreaWrapper>

          <CounterTextarea>
            <span>{counterTextLength}</span>
            <span>/{maxCharacters}</span>
          </CounterTextarea>

          <ButtonWrapper>
            <ButtonTags
              disabled={isGeneratingAiText || !isImageSelected.state}
              type="submit"
              onClick={handleGenerateAiTextPost}>
              {isGeneratingAiText ? (
                <Loader content="Loading..." />
              ) : (
                <span>Suggest Content 👉🏻</span>
              )}
            </ButtonTags>
            <ButtonTags
              disabled={isGenerating || postText === ''}
              type="submit"
              onClick={handleGenerateTags}>
              {isGenerating ? (
                <Loader content="Loading..." />
              ) : (
                <span>Suggest hashtags 👉🏻</span>
              )}
            </ButtonTags>
          </ButtonWrapper>
          {/* !isGeneratingAiText: This condition checks if isGeneratingAiText is false (or falsy). If isGeneratingAiText is true (or truthy), this block won't render. */}
          <TextWrapper>
            {/* Display Ai Text */}
            {!isGeneratingAiText && generatedAiText && (
              <AiTextDisplayContainer>
                <span>Suggested Post Content:</span>
                <button
                  type="submit"
                  onClick={() => handleAiTextButtonClick(generatedAiText)}>
                  <p>{generatedAiText}</p>
                </button>
              </AiTextDisplayContainer>
            )}
            {/* Display HashTags */}
            {!isGenerating && tagData.length > 0 && (
              <TagsDisplayContainer>
                <span className="span">Generated HashTags:</span>
                <TagsDisplayWrapper>
                  {Array.isArray(tagData) &&
                    tagData?.map(item => {
                      return (
                        <div key={item.id}>
                          <button
                            type="submit"
                            onClick={() => handleTagButtonClick(item?.tag)}>
                            <span>#{item?.tag.replace(/\s+/g, '')}</span>
                          </button>
                        </div>
                      );
                    })}
                </TagsDisplayWrapper>
              </TagsDisplayContainer>
            )}
          </TextWrapper>

          <TitleForm>IMAGE/VIDEO</TitleForm>
          <Row>
            <Col xs={24} md={12}>
              <UploaderImage
                handleFileUpload={handleFileUpload}
                describe="Upload your media files"
                image={UploadIcon}
              />
            </Col>
            {dataMedia?.data.length ? (
              <Col xs={24} md={12}>
                <ButtonDashed type="button" onClick={triggereduploadedfiles}>
                  {/* <ButtonDashed type="button" onClick={toggleShowSelectMedia}> */}
                  <img src={DownloadIcon} alt="Get your files" />
                  <p>Your uploaded files.</p>
                </ButtonDashed>
              </Col>
            ) : null}
          </Row>
        </Col>

        <Col xs={24} lg={12}>
          <Subtitle>Preview</Subtitle>
          {isImageType(statePost.type) && (
            <ContainerPreview id="container-preview">
              {sendingImg && (
                <TopContainer>
                  <LoaderCenter content="Resizing... " size="sm" />
                </TopContainer>
              )}
              <ContainerEdit
                ref={rContainer}
                bgColor={componentColor.background}
                hasImage={!!statePost.previewUrl}>
                <figure>
                  {statePost.previewUrl && (
                    <DivImg
                      src={statePost.previewUrl}
                      alt="edit background"
                      sendingImg={sendingImg}
                    />
                  )}

                  <DivPosition
                    position={componentPosition.positionTitle}
                    align={componentPosition.alignTitle}>
                    <BigTitleImg color={componentColor.colorTitle}>
                      {postPublicationFormik.values.title}
                    </BigTitleImg>
                  </DivPosition>
                  <DivPosition
                    position={componentPosition.positionSubtitle}
                    align={componentPosition.alignSubtitle}>
                    <SubtitleImg color={componentColor.colorSubtitle}>
                      {postPublicationFormik.values.subtitle}
                    </SubtitleImg>
                  </DivPosition>
                </figure>
              </ContainerEdit>
            </ContainerPreview>
          )}

          {!isImageType(statePost.type) && (
            <video
              controls
              autoPlay
              style={{ width: '100%' }}
              key={statePost.previewUrl}>
              <source src={statePost.previewUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}

          {isImageType(statePost.type) && (
            <ButtonGroupCenter vertical>
              {statePost.originalUrl && (
                <TitleButton onClick={toggleShowCropImage}>
                  <ClickableTitleForm>Crop picture</ClickableTitleForm>
                </TitleButton>
              )}
              <TitleButton
                onClick={async () => {
                  await setShowPersonaliseImage(() => !showPersonaliseImage);

                  if (!showPersonaliseImage) {
                    personaliseImageRef?.current?.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }
                }}>
                <ClickableTitleForm>Add text on image</ClickableTitleForm>
              </TitleButton>
            </ButtonGroupCenter>
          )}

          <Divider />
            <GlobalStyle />
          <FlexboxGrid justify="space-between" align="bottom">
            <FlexboxGrid.Item componentClass={Col} xs={24} md={12}>
              <span>Schedule post (optional)</span>
              <DateInputContainer>
                <DateTimeRounded
                  placeholder="Select Date"
                  format="DD.MM.YYYY"
                  disabledDate={(date: Date) =>
                    isBefore(date, subDays(new Date(), 1))
                  }
                  ranges={[]}
                  onChange={(date: Date) => setSelectedDate(date)}
                  onClean={() => setSelectedTime(null)}
                />

                <DateTimeRounded
                  apperance="default"
                  disabled={!selectedDate}
                  placeholder="Select Time"
                  format="HH:mm"
                  disabledHours={(hour: number) => {
                    if (selectedDate && isToday(new Date(selectedDate))) {
                      const currentHour = new Date().getHours();
                      return hour <= currentHour;
                    }
                    return false;
                  }}
                  ranges={[]}
                  onChange={(date: Date) => setSelectedTime(date)}
                  renderValue={(date: Date, formatPicker: string) => (
                    <span>
                      {selectedTime
                        ? format(date, formatPicker)
                        : 'Select Time'}
                    </span>
                  )}
                />
              </DateInputContainer>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item componentClass={Col} xs={24} md={10}>
              <ButtonAdd
                onClick={() => postPublicationFormik.submitForm()}
                disabled={loading || !!selectedDate !== !!selectedTime}>
                {loading ? (
                  <Loader content="Loading..." />
                ) : (
                  <span>
                    {selectedDate &&
                    selectedTime &&
                    isAfter(getSelectedDate(), new Date())
                      ? `Post on ${formatDate(getSelectedDate())}`
                      : 'Post Now'}
                  </span>
                )}
              </ButtonAdd>
            </FlexboxGrid.Item>
            <ParagraphAdvert>
              If the cropping tool is not used, white frames may appear on the
              published image.
            </ParagraphAdvert>
          </FlexboxGrid>
        </Col>
      </Row>

      {showPersonaliseImage && isImageType(statePost.type) && (
        <DivForm ref={personaliseImageRef}>
          <IconInput
            inputType="text"
            value={postPublicationFormik.values.title}
            onChange={postPublicationFormik.handleChange('title')}
            placeholder="Title"
            error={postPublicationFormik.errors.title}
          />
          <IconInput
            inputType="text"
            value={postPublicationFormik.values.subtitle}
            onChange={postPublicationFormik.handleChange('subtitle')}
            placeholder="Subtitle"
            error={postPublicationFormik.errors.subtitle}
          />
          {!statePost.previewUrl && (
            <FlexboxGrid align="middle">
              <Col md={6}>Background color</Col>
              <Col>
                <ColorsContent
                  componentColor={componentColor}
                  typePosition="background"
                  setComponentColor={setComponentColor}
                />
              </Col>
            </FlexboxGrid>
          )}
          <FlexboxGrid align="middle">
            <Col md={6}>Title colour</Col>
            <Col>
              <ColorsContent
                componentColor={componentColor}
                typePosition="colorTitle"
                setComponentColor={setComponentColor}
              />
            </Col>
          </FlexboxGrid>
          <FlexboxGrid align="middle">
            <Col md={6}>Subtitle colour</Col>
            <Col>
              <ColorsContent
                componentColor={componentColor}
                typePosition="colorSubtitle"
                setComponentColor={setComponentColor}
              />
            </Col>
          </FlexboxGrid>
          <FlexboxGrid align="middle">
            <Col md={6}>Title alignment</Col>
            <Col xs={24} md={18}>
              <PositionContent
                componentPosition={componentPosition}
                typePosition="alignTitle"
                setComponentPosition={setComponentPosition}
                alignText
              />
            </Col>
          </FlexboxGrid>
          <FlexboxGrid align="middle">
            <Col md={6}>Title position</Col>
            <Col xs={24} md={18}>
              <PositionContent
                componentPosition={componentPosition}
                typePosition="positionTitle"
                setComponentPosition={setComponentPosition}
              />
            </Col>
          </FlexboxGrid>
          <FlexboxGrid align="middle">
            <Col md={6}>Subtitle alignment</Col>
            <Col xs={24} md={18}>
              <PositionContent
                componentPosition={componentPosition}
                typePosition="alignSubtitle"
                setComponentPosition={setComponentPosition}
                alignText
              />
            </Col>
          </FlexboxGrid>
          <FlexboxGrid align="middle">
            <Col md={6}>Subtitle position</Col>
            <Col xs={24} md={18}>
              <PositionContent
                componentPosition={componentPosition}
                typePosition="positionSubtitle"
                setComponentPosition={setComponentPosition}
              />
            </Col>
          </FlexboxGrid>
        </DivForm>
      )}

      <ModalSelectMedia
        toggleShowSelectMedia={toggleShowSelectMedia}
        media={dataMedia?.data || []}
        showSelectMedia={showSelectMedia}
        dispatchPost={dispatchPost}
        videoNotAcceptedByGoogle={videoNotAcceptedByGoogle}
        setIsImageSelected={setIsImageSelected}
      />
      {showCropImage && isImageType(statePost.type) && (
        <ModalCropperImage
          showCropImage={showCropImage && isImageType(statePost.type)}
          toggleShowCropImage={toggleShowCropImage}
          originalUrl={statePost.originalUrl}
          setCropper={setCropper}
          cropper={cropper}
          getCropData={getCropData}
        />
      )}
    </Container>
  );
};

export default CreatePost;
