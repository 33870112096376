import { useEffect, useState } from 'react';
import axios from '../../lib/axios';
import {
  ButtonContainer,
  FlexContainer,
  Frame,
  FrameBehind,
  FrameFront,
  HeadingContainer,
  Rectangle,
  TriangleContainer,
  TriangleLeft,
  TriangleRight,
} from './BasicPlan.styles';
import CheckCircleIcon from '../../assets/icons/checkCircle.svg';
import RectangleButton from '../../components/rectangleButton/RectangleButton';
import { useAuth } from '../../context/AuthContext';

const BasicPlan = () => {
  const { user, companySelected } = useAuth();
  const [displayPremiumPlan, setDisplayPremiumPlan] = useState<boolean>(false);

  useEffect(() => {
    const { subscriptionStatus, invoiceStatus } =
      user?.companies?.[0]?.payment ?? {};
    if (subscriptionStatus === 'active' && invoiceStatus === 'paid') {
      setDisplayPremiumPlan(true);
    } else {
      setDisplayPremiumPlan(false);
    }
  }, [user]);

  const handleManageBillingSubmit = async () => {
    try {
      if (companySelected?.id) {
        const ManageBillingURL = await axios.post(
          `stripe/create-customer-portal-session`,
          { companyId: companySelected.id },
        );

        window.location.href = ManageBillingURL.data;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(
        'line 44 | BasicPlan.tsx | handleManageBillingSubmit | err: ',
        err,
      );
    }
  };
  return (
    <>
      <TriangleContainer>
        <TriangleLeft />
        <TriangleRight />
      </TriangleContainer>
      <Rectangle>
        <h3>Free </h3>
      </Rectangle>

      <FrameBehind />

      <Frame>
        <h2>Basic Plan</h2>
      </Frame>

      <FrameFront>
        <HeadingContainer>
          <p
            style={{
              fontWeight: 'bold',
              padding: '0 10px 10px 10px',
              marginTop: 47,
              marginBottom: 5,
            }}
          >
            Basic plan features:
          </p>
          <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p>View posts & cross post</p>
          </FlexContainer>

          <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p>View reels & cross post</p>
          </FlexContainer>

          <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p>View messages </p>
          </FlexContainer>

          <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p>View promotions</p>
          </FlexContainer>

          <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p>View & respond to reviews </p>
          </FlexContainer>
          <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p>Analytics </p>
          </FlexContainer>
          <FlexContainer>
            <img src={CheckCircleIcon} alt="circle with tick icon" />
            <p>Manage multiple locations</p>
          </FlexContainer>
        </HeadingContainer>
        {!displayPremiumPlan && (
          <ButtonContainer>
            <RectangleButton
              disabled
              title="Current Plan"
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={handleManageBillingSubmit}
            />
          </ButtonContainer>
        )}
        {displayPremiumPlan && (
          <ButtonContainer>
            <RectangleButton
              title="Downgrade"
              onClick={handleManageBillingSubmit}
            />
          </ButtonContainer>
        )}
      </FrameFront>
    </>
  );
};

export default BasicPlan;
