/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useGoogleLogin } from 'react-google-login';
import { Button, Col, FlexboxGrid } from 'rsuite';
import { useTheme } from 'styled-components';
import { TYPES_TOOLS } from '../../../actions/toolsActions';
import { TYPES_OF_SOCIAL_NETWORKS } from '../../../lib/globalValues';
import {
  CLIENT_ID,
  COOKIE_POLICY,
  SCOPE_BUSINESS,
} from '../../../lib/initGoogle';
import { notification, notificationError } from '../../../lib/notification';
import { assignTool } from '../../../services/assignTool';
import { disableTool } from '../../../services/disableTool';
import {
  getAccountsGoogleBusiness,
  getCompaniesOfAccountGoogleBusiness,
} from '../../../services/googleBusinessTools';
import { getToolsCompanyObj } from '../../../services/getToolsCompany';
import ModalTools from '../../modals/modalTools/ModalTools';
import {
  ButtonAdd,
  ButtonContainer,
  CardPage,
  Container,
} from '../Toogle.styles';

interface OptionsModal {
  locations: Location[];
  accounts: {
    accountName: string;
    name: string;
  }[];
}

interface Location {
  name: string;
  title: string;
  categories: Categories;
}

interface Categories {
  primaryCategory: PrimaryCategory;
}

interface PrimaryCategory {
  name: string;
  displayName: string;
  serviceTypes: ServiceType[];
  moreHoursTypes: MoreHoursType[];
}

interface MoreHoursType {
  hoursTypeId: string;
  displayName: string;
  localizedDisplayName: string;
}

interface ServiceType {
  serviceTypeId: string;
  displayName: string;
}

interface Props {
  companyId?: number;
  dispatchTool: any;
  stateTool: any;
  invalidateQuery?: () => void;
  signup?:boolean,
}

const GoogleBusinessToggle = ({
  companyId,
  dispatchTool,
  stateTool,
  invalidateQuery,
  signup=false,
}: Props) => {
  const theme = useTheme();

  const [dataModal, setDataModal] = useState<OptionsModal | null>(null);
  const [dataModalArray, setDataModalArray] = useState<string[]>([]);
  const [switchData, setSwitchData] = useState<[] | null>(null);

  const [googleTokenAndUserId, setGoogleTokenAndUserId] = useState({
    credentials: '',
    accountId: '',
  });

  const activated =
    stateTool[TYPES_OF_SOCIAL_NETWORKS.GOOGLE]?.enabled === true;

  const handleLoginGoogle = async ({ code }: any) => {
    if (!code) {
      notificationError({
        title: 'Error',
        description: 'Could not authenticate this account',
      });
    } else {
      const { data } = await getAccountsGoogleBusiness(code);

      if (data) {
        setGoogleTokenAndUserId({
          ...googleTokenAndUserId,
          credentials: data.credentials,
        });
        setDataModal(data.accounts);
      }
    }
  };

  const handleAccount = async (accountId: string) => {
    const { data } = await getCompaniesOfAccountGoogleBusiness({
      tokenId: accountId,
      credentials: googleTokenAndUserId.credentials,
    });

    setGoogleTokenAndUserId({
      ...googleTokenAndUserId,
      accountId,
    });

    if (data) {
      setDataModal(data);
    }
  };

  const handleShowLocations = async () => {
    const { data: dataTools } = await getToolsCompanyObj(companyId);
    setSwitchData(dataTools.google);
  };

  const handleSelectBusiness = async (page: any) => {
    const defaultSelectContent = [
      {
        type: TYPES_OF_SOCIAL_NETWORKS.GOOGLE,
        accessToken: page.accessToken,
        pageId: page.pageId,
        userId: page.userId,
        enabled: true,
        id: page.id,
      },
    ];

    if (companyId) {
      const { data } = await assignTool({
        companyId,
        tool: defaultSelectContent,
      });

      dispatchTool({
        type: TYPES_TOOLS.ACTIVATE_GOOGLE,
        payload: data[0],
      });
      setSwitchData(null);
    }
  };

  const assignAccount = async (locationId: string, location: any) => {
    setDataModalArray(prev => [...prev, locationId]);

    const defaultContent = [
      {
        type: TYPES_OF_SOCIAL_NETWORKS.GOOGLE,
        pageId: locationId,
        accessToken: googleTokenAndUserId.credentials,
        userId: googleTokenAndUserId.accountId,
        pageName: `${location.title} - ${location.categories.primaryCategory.displayName}`,
        enabled: dataModalArray.length === 0,
      },
    ];
    if (companyId) {
      const { data } = await assignTool({
        companyId,
        tool: defaultContent,
      });
      if (data && data[0] && dataModalArray.length === 0) {
        dispatchTool({
          type: TYPES_TOOLS.ACTIVATE_GOOGLE,
          payload: data[0],
        });
      }
      notification({
        type: 'success',
        title: 'Success',
        description:
          dataModalArray.length === 0
            ? 'Google business was enabled'
            : 'Google business was added',
      });
    } else {
      dispatchTool({
        type: TYPES_TOOLS.ACTIVATE_GOOGLE,
        payload: defaultContent[0],
      });
    }
    invalidateQuery?.();
  };

  const { signIn } = useGoogleLogin({
    cookiePolicy: COOKIE_POLICY,
    scope: SCOPE_BUSINESS,
    clientId: CLIENT_ID,
    onSuccess: handleLoginGoogle,
    onFailure: (err: any) =>
      notificationError({
        title: 'Error',
        description: err?.error || 'Could not get user info from Google',
      }),
    responseType: 'code',
  });

  return (
    <>
      {dataModal && (
        <ModalTools
          setDataModal={setDataModal}
          dataModal={dataModal}
          setDataModalArray={setDataModalArray}
          title="Connect Google Business"
          description={
            dataModal.accounts
              ? 'Select google account'
              : 'Select google businesses'
          }
        >
          {dataModal?.accounts && (
            <div>
              {dataModal.accounts.map(account => {
                const [accountId] = account.name.split('/').reverse();
                return (
                  <CardPage
                    align="middle"
                    justify="space-around"
                    key={accountId}
                  >
                    <FlexboxGrid.Item>
                      <span>{account.accountName}</span>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                      <Button onClick={() => handleAccount(accountId)}>
                        Add
                      </Button>
                    </FlexboxGrid.Item>
                  </CardPage>
                );
              })}
            </div>
          )}

          {dataModal?.locations && (
            <div>
              {dataModal.locations.map(location => {
                const [locationId] = location.name.split('/').reverse();
                const isDisabled = dataModalArray.includes(locationId);

                return (
                  <CardPage
                    align="middle"
                    justify="space-around"
                    key={locationId}
                  >
                    <FlexboxGrid.Item componentClass={Col} xs={20}>
                      <span>
                        {location.title} -{' '}
                        {location.categories.primaryCategory.displayName}
                      </span>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={4}>
                      <Button
                        disabled={isDisabled}
                        onClick={() => assignAccount(locationId, location)}
                      >
                        Add
                      </Button>
                    </FlexboxGrid.Item>
                  </CardPage>
                );
              })}
            </div>
          )}
        </ModalTools>
      )}

      {switchData && (
        <ModalTools
          setDataModal={setSwitchData}
          dataModal={switchData}
          title="Switch Google Business"
          description="Select the Google business to switch to"
        >
          <div>
            {switchData?.map((page: any) => {
              return (
                <CardPage align="middle" key={page.pageId}>
                  <FlexboxGrid.Item componentClass={Col} xs={20}>
                    <span>{page.pageName}</span>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item xs={4} componentClass={Col}>
                    <Button
                      disabled={page.enabled}
                      onClick={() => handleSelectBusiness(page)}
                    >
                      {page.enabled ? 'Activated' : 'Activate'}
                    </Button>
                  </FlexboxGrid.Item>
                </CardPage>
              );
            })}
          </div>
        </ModalTools>
      )}
      {signup?(
      <>
        {activated && (
          <Container
          style={{
            // display: 'flex',
            // flexDirection: signup ? 'column' : 'row', // Stack buttons vertically if signup is true
            justifyContent: signup ? 'end' : 'space-between',
            // alignItems: 'center',
            // padding: signup ? '20px' : '10px',
            // borderRadius: signup ? '10px' : '0',
            // width: signup ? '20%' : '100%', // Set width to 40% when signup is true
            // margin: '0 auto', // Center the container
            // gap: signup ? '10px' : '0', // Add gap between buttons in column layout
          }}>
            <ButtonAdd
            // bgColor={signup ? 'black' : theme.colors.crimson}
            onClick={handleShowLocations}
            >
              Switch
            </ButtonAdd>
            <ButtonAdd
              // bgColor={theme.colors.red}
              onClick={() => {
                disableTool(stateTool[TYPES_OF_SOCIAL_NETWORKS.GOOGLE].id).then(
                  () => {
                    dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_GOOGLE });
                    invalidateQuery?.();
                  },
                );
                // .catch(error => {
                //   console.log('Error:', error);
                // });
              }}
              style={{
                width: signup ? '120px' : 'auto',
                color: signup ? '#64E000' : 'inherit',
                fontFamily: signup ? "'Inter', sans-serif" : 'inherit', // Set the font family
               
              }}
            >
              Connected
            </ButtonAdd>
          </Container>
        )}

        {!activated && (
          <ButtonContainer
          signup={signup}
            
            >
            <ButtonAdd bgColor={theme.colors.red} onClick={signIn}
                signup={signup}
              >
              Connect
            </ButtonAdd>
          </ButtonContainer>
        )}
      </>
      ):(
        <>
        {activated && (
          <Container
          style={{
            display: 'flex',
            flexDirection: signup ? 'column' : 'row', // Stack buttons vertically if signup is true
            justifyContent: signup ? 'center' : 'space-between',
            alignItems: 'center',
            padding: signup ? '20px' : '10px',
            borderRadius: signup ? '10px' : '0',
            width: signup ? '20%' : '100%', // Set width to 40% when signup is true
            margin: '0 auto', // Center the container
            gap: signup ? '10px' : '0', // Add gap between buttons in column layout
          }}>
            <ButtonAdd
            bgColor={signup ? 'black' : theme.colors.crimson}
            onClick={handleShowLocations}
            >
              Switch
            </ButtonAdd>
            <ButtonAdd
              bgColor={theme.colors.red}
              onClick={() => {
                disableTool(stateTool[TYPES_OF_SOCIAL_NETWORKS.GOOGLE].id).then(
                  () => {
                    dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_GOOGLE });
                    invalidateQuery?.();
                  },
                );
                // .catch(error => {
                //   console.log('Error:', error);
                // });
              }}
              style={{
                width: signup ? '120px' : 'auto',
              }}
            >
              Disconnect
            </ButtonAdd>
          </Container>
        )}

        {!activated && (
          <ButtonContainer
          signup={signup}
          
            >
            <ButtonAdd bgColor={theme.colors.red} onClick={signIn}
                signup={signup}
              >
              Connect
            </ButtonAdd>
          </ButtonContainer>
        )}
      </>
      )}
      {/* <Toggle
        checked={activated}
        onChange={(check: boolean) =>
          check
            ? signIn()
            : disableTool(stateTool[TYPES_OF_SOCIAL_NETWORKS.GOOGLE].id).then(
                () => {
                  dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_GOOGLE });
                  invalidateQuery?.();
                },
              )
        }
      /> */}
    </>
  );
};

export default GoogleBusinessToggle;
