import { useEffect } from 'react';
import ReactDOM from 'react-dom';
// import axios from '../../lib/axios';

interface TwitterWindowProps {
  auth_url?: string;
}

// @ts-ignore // eslint-disable-next-line
const TwitterWindow: React.FC<TwitterWindowProps> = ({
  // @ts-ignore
  children,
  auth_url,
}) => {
  let externalWindow: Window | null = null;

  const containerEl: HTMLDivElement = document.createElement('div');

  externalWindow = window.open('', 'twitterWindow');

  const twitterLogin = async () => {
    /// try {
    ///   await axios.get('tools/twitter-login');
    /// } catch (err) {
    if (externalWindow) {
      externalWindow?.document.body.appendChild(containerEl);
      /// @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      externalWindow!.location.href = auth_url;
    }
  };

  useEffect(() => {
    twitterLogin();
  }, []);

  return ReactDOM.createPortal(children, containerEl);
};

export default TwitterWindow;
