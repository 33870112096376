import { useEffect, useState } from 'react';
import countries from '../../../../assets/data/countries.json';
import { ErrorMessage,  } from '../../Forms.styles';
import { InputContainer,  } from '../fieldInput/FieldInput.styles';
// import { HiddenGroup } from '../FormSignUp.styles';
import { CountrySelect, Placeholder } from './selectCountry.styles';
import { getCountryAndPhoneCode, getCurrentPosition } from '../../../../services/getUserCountry';

interface Props {
  menuPlacement: boolean;
  name: string;
  page: boolean;
  touched: any;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  onCountryChange: (country: string) => void; // New prop
}

const SelectCountry = ({
  menuPlacement = true,
  name,
  page = true,
  touched,
  errors,
  setFieldValue,
  setFieldTouched,
  onCountryChange // New prop
}: Props) => {
  const [defaultCountry, setDefaultCountry] = useState<string | null>(null);
  const [defaultPhoneCode, setDefaultPhoneCode] = useState('');

  useEffect(() => {
    const fetchCountryInfo = async () => {
      try {
        const position = await getCurrentPosition();
        const details = await getCountryAndPhoneCode(position.coords.latitude, position.coords.longitude);

        setDefaultCountry(details.countryName);
        setFieldValue(name, details.countryName, true);
        setDefaultPhoneCode(details.phoneCode);
        onCountryChange(details.countryName); // Pass country to parent
        
      } catch (error) {
        // setDefaultCountry('United Kingdom');
        // setFieldValue(name, 'United Kingdom', true);
        // onCountryChange('United Kingdom'); 
      }
    };

    fetchCountryInfo();
  }, [name, setFieldValue, onCountryChange]);

  return (
    <InputContainer>
      <CountrySelect
        value={countries.find(option => option.value === defaultCountry)}
        name={name}
        options={countries}
        onChange={(option: { value: string; label: string }) => {
          setDefaultCountry(option.value);
          setFieldValue(name, option.value, true);
          onCountryChange(option.value); // Pass country to parent
          
          // console.log("optionn values",option.value);
        }}
        menuPlacement={menuPlacement ? 'top' : 'bottom'}
        onBlur={() => setFieldTouched(name)}
        error={touched[name] && errors[name]}
        placeholder={<Placeholder>Country*</Placeholder>}
      />
      {touched[name] && errors[name] && (
        <ErrorMessage signUp>{errors[name]}</ErrorMessage>
      )}
    </InputContainer>
  );
};

export default SelectCountry;
