/* eslint-disable func-names */
import * as Yup from 'yup';
import { PASSWORD_ERROR_MESSAGE } from '../../../lib/globalValues';
import {
  capitalRegExp,
  emailRegExp,
  lowerCaseRegExp,
  numbersRegExp,
  // phoneRegExp,
} from '../../../lib/regExp';
import { findUser } from '../../../services/findUser';

const firstPageSchema = Yup.object().shape({
  firstName: Yup.string().required('Your name is required'),
  lastName: Yup.string().required('Your surname is required'),
  email: Yup.string()
    .email('Not valid email')
    .required('Email is required')
    .test(
      'checkUniqueEmail',
      'User with that email already exists',
      async value => {
        if (value && emailRegExp.test(value)) {
          try {
            const { data } = await findUser(value);
            if (data?.length) {
              return false;
            }
            return true;
          } catch (err) {
            return false;
          }
        }
        return false;
      },
    ),
  password: Yup.string()
    .matches(lowerCaseRegExp, PASSWORD_ERROR_MESSAGE)
    .matches(capitalRegExp, PASSWORD_ERROR_MESSAGE)
    .matches(numbersRegExp, PASSWORD_ERROR_MESSAGE)
    .min(8, PASSWORD_ERROR_MESSAGE)
    .max(32, PASSWORD_ERROR_MESSAGE)
    .required('Password is required'),
  terms: Yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
});

export const firstPageSocialSchema = Yup.object().shape({
  email: Yup.string()
    .email('Not valid email')
    .required('Email is required')
    .test(
      'checkUniqueEmail',
      'User with that email already exists',
      async value => {
        if (value && emailRegExp.test(value)) {
          try {
            const { data } = await findUser(value);
            if (data?.length) {
              return false;
            }
            return true;
          } catch (err) {
            return false;
          }
        }
        return false;
      },
    ),
  password: Yup.string()
    .matches(lowerCaseRegExp, PASSWORD_ERROR_MESSAGE)
    .matches(capitalRegExp, PASSWORD_ERROR_MESSAGE)
    .matches(numbersRegExp, PASSWORD_ERROR_MESSAGE)
    .min(8, PASSWORD_ERROR_MESSAGE)
    .max(32, PASSWORD_ERROR_MESSAGE)
    .required('Password is required'),
  terms: Yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
  confirmPassword: Yup.string()
  .oneOf([Yup.ref('password'), null], 'Passwords must match')
  .required('Confirm Password is required'),
});

export const secondPageSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required').min(2, 'First Name is too short'),
  lastName: Yup.string().required('Last Name is required').min(2, 'Last Name is too short'),
  name: Yup.string().required('Last Name is required').min(2, 'Company Name is too short'),
  // name: Yup.string().required('Company Name is required'),
  // country: Yup.string().required('Country is required')||'United Kingdom',
  country: Yup.string().nullable(), // Make sure this is not required

});
const PASSWORD_ERROR_MESSAGE2 = 'login Password must be at least 2 characters long'; // Define this or import it if defined elsewhere.

export const loginPageSchema = Yup.object().shape({
  email: Yup.string().email('Not valid email').required('Email is required'),
  password: Yup.string().required('Password is required').min(2, PASSWORD_ERROR_MESSAGE2)

});

export const validatePassword = (pass: string) => {
  let value = 0;

  if (lowerCaseRegExp.test(pass)) {
    value += 1;
  }
  if (capitalRegExp.test(pass)) {
    value += 1;
  }
  if (numbersRegExp.test(pass)) {
    value += 1;
  }

  if (pass.length >= 8) {
    value += 1;
  }

  return value;
};

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
  .matches(lowerCaseRegExp, PASSWORD_ERROR_MESSAGE)
  .matches(capitalRegExp, PASSWORD_ERROR_MESSAGE)
  .matches(numbersRegExp, PASSWORD_ERROR_MESSAGE)
  .min(8, PASSWORD_ERROR_MESSAGE)
  .max(32, PASSWORD_ERROR_MESSAGE)
  .required('Password is required'),

terms: Yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});
export const validationSchemas = [firstPageSchema, secondPageSchema];

export const validationSocialSchemas = [
  firstPageSocialSchema,
  secondPageSchema,
];
