/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from '../lib/axios';
import { capitalize } from '../lib/helpers';
import { notification, notificationError } from '../lib/notification';

interface Props {
  title: string;
  mediaId: number;
  socialNetworks: string[];
  scheduledAt: any;
}

export const postsSocial = async ({
  title,
  mediaId,
  socialNetworks,
  scheduledAt,
}: Props) => {
  try {
    const { data } = await axios.post('/posts', {
      title,
      mediaId,
      socialNetworks,
      scheduledAt,
    });

    if (data) {
      Object.entries(data).map(([socialName, content]: [string, any]) =>
        content === true
          ? notification({
              type: 'success',
              title: capitalize(socialName),
              description: 'Post was successfully published',
            })
          : notificationError({
              title: capitalize(socialName),
              description: content || 'The post could not be made.',
            }),
      );
    } else {
     // throw new Error('Something went wrong');
    }

    return data;
  } catch (error) {
    if(error.response && error.response.status === 403)
    {
      notificationError({
        title: 'Error',
        description: 'API Key not correct. Please provide a valid API key.',
      });    
      return error;  
    }
    

    notificationError({
      title: 'Error',
      description: 'Something went wrong',
    });
    return error;
  }
};
