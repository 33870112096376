import { useState } from 'react';
import theme from '../../styles/theme';
import { SocialIcon } from '../cards/socialCard/SocialCard.styles';
import ReplyMessageModal from '../modals/replyMessageModal/ReplyMessageModal';
import {
  // Button,
  Container,
  RoundedFlexbox,
  TextContainer,
  SpanLBlack,
  SpanBlack,
  DateP,
  ActiveDot
} from './Message.styles';
import { formatMessageDate } from '../../lib/format';


interface Props {
  messages:TMessage,
  handleUpdate: () => void
}

const colors = {
  facebook: theme.colors.facebook,
  twitter: theme.colors.twitter,
  instagram: theme.colors.instagram,
};

const Message = ({ handleUpdate ,messages }: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>           
      <ReplyMessageModal
        messages={messages}
        handleUpdate={handleUpdate}
        showModal={showModal}
        toggleShowModal={() => setShowModal(!showModal)}
      />
      <Container onClick={() => setShowModal(true)}>
        <RoundedFlexbox colspan={7} color={colors[messages.type]}>
          <SocialIcon size="1x" icon={messages.type} />
        </RoundedFlexbox>
        <TextContainer>
          {messages.messages.data.map((value, index, array) => (
            <>
              {index === 0 && 
              <SpanBlack> {messages.senderName}</SpanBlack>
              }
              {index === array.length - 1 && (
                <>
                  <DateP>
                    {formatMessageDate(value.created_time)}
                  </DateP>                  
                  <div>
                    {
                      (value?.from?.name || value?.from?.username) !== messages.senderName && <SpanLBlack>You: </SpanLBlack>
                    }
                    <SpanLBlack>{value.message} </SpanLBlack>
                    </div>
                </>
              )}
            </>
          ))}
        </TextContainer>
        {!messages.replied &&
          <ActiveDot />
        }
      </Container>
    </>
  );
};

export default Message;
