import { useQuery } from 'react-query';
import { getReviewsStatistics } from '../services/reviews';

const useReviewsStatisticsReviews = (dateRange: string) => {
  return useQuery(
    `REVIEWS-STATISTICS-${dateRange}`,
    () => getReviewsStatistics({ dateRange }),
    {
      staleTime: 10 * 60 * 1000,
      notifyOnChangePropsExclusions: ['isStale'],
      refetchOnWindowFocus: false,
    },
  );
};

export default useReviewsStatisticsReviews;
