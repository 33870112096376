/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */

import { format, isAfter, isBefore, isToday, subDays } from 'date-fns';
import { useFormik } from 'formik';
import html2canvas from 'html2canvas';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Col, Divider, FlexboxGrid, Loader, Row, RangeSlider } from 'rsuite';
import EmojiPicker from 'emoji-picker-react';
import { TYPES_POSTS } from '../../actions/postsActions';
import DownloadIcon from '../../assets/icons/download.svg';
import UploadIcon from '../../assets/icons/upload.svg';
// import playIcon from '../../assets/images/play.svg';
// import pauseIcon from '../../assets/images/pause.svg';
import { useAuth } from '../../context/AuthContext';
import useMedia from '../../hooks/useMedia';
import useTextarea from '../../hooks/useTextarea';
import useToggle from '../../hooks/useToggle';
import { dataURLtoFile } from '../../lib/encodeImg';
import { formatDate } from '../../lib/format';
import { POSITION, TYPES_OF_SOCIAL_NETWORKS } from '../../lib/globalValues';
import { isImageType } from '../../lib/mediaType';
import {
  notification,
  notificationError,
  notificationWithNodes,
} from '../../lib/notification';
import { selectImgStatus } from '../../lib/toogleImgStatus';
import { initialPostsState, postsReducer } from '../../reducers/postsReducer';
import { fileUpload } from '../../services/fileUpload';
import { getToolsCompany } from '../../services/getToolsCompany';
import { reelsSocial } from '../../services/reelsSocial';
import tagsPost from '../../services/tagsPost';
import { Container } from '../../styles/generalStyles';
import ColorsContent from '../colorsContent/ColorsContent';
import IconInput from '../inputs/IconInput/IconInput';
import ModalCropperImage from '../modals/modalCrop/ModalCropperImage';
import ModalSelectMedia from '../modals/modalSelectMedia/ModalSelectMedia';
import PositionContent from '../positionContent/PositionContent';
import {
  ButtonAdd,
  ButtonDashed,
  ButtonNotification,
  ButtonTags,
  CounterTextarea,
  DateInputContainer,
  DateTimeRounded,
  DivForm,
  ImgTools,
  ParagraphAdvert,
  PreviewBackground,
  PreviewInner,
  SimleIcon,
  SimplePreviewBackground,
  Subtitle,
  TagsDisplayContainer,
  TextAreaWrapper,
  Textarea,
  TitleForm,
  VideoContainer,
} from './CreateReel.styles';
import simleIcon from '../../assets/images/smile.png';
import VideoFrameExtractor from './VideoFrameExtractor/VideoFrameExtractor';

interface ReelForm {
  reelText: string;
  title: string;
  subtitle: string;
}

interface VideoDetails {
  startTime: number;
  endTime: number;
  width: number;
  height: number;
}

const positionDesign = {
  alignTitle: POSITION.CENTER,
  positionTitle: POSITION.TOP,
  alignSubtitle: POSITION.CENTER,
  positionSubtitle: POSITION.CENTER,
};

const colorsDesign = {
  background: 'white',
  colorTitle: 'black',
  colorSubtitle: 'black',
};

interface TagDataItem {
  tag: string;
  score: number;
}

const ALLOWED_VIDEO_TYPES = ['video/mp4', 'video/quicktime'];
const MAX_CHARACTERS = 2200;
const MAX_CHARACTERS_TWITTER = 280;

interface VideoData {
  dur: number;
  fra: string[];
}

interface DataMedia {
  data: any;
}
interface ImageSelectionState {
  imgFile: File | null;
  state: boolean;
}

const OUTER_WIDTH = 300;

const CreateReel = () => {
  const queryClient = useQueryClient();
  const { user, companySelected } = useAuth();
  const { isOpen: showCropImage, toggleChange: toggleShowCropImage } =
    useToggle();
  const { isOpen: showSelectMedia, toggleChange: toggleShowSelectMedia } =
    useToggle();
  const { counterTextLength, handleCounterTextLength } = useTextarea();

  // initialization states for storing values //
  const [isGenerating, setIsGenerating] = useState(false);
  const [sampleText, setSampleText] = useState('');
  const [tagData, setTagData] = useState<any[]>([]);
  const [shouldGenerate, setShouldGenerate] = useState(false);

  const [stateReel, dispatchPost] = useReducer(postsReducer, initialPostsState);

  const [displayPremiumPlan, setDisplayPremiumPlan] = useState<boolean>(false);

  const [selectedDate, setSelectedDate] = useState<null | Date>(null);
  const [selectedTime, setSelectedTime] = useState<null | Date>(null);
  const [tools, setTools] = useState<ToolAPI[]>([]);
  const [showPersonaliseImage, setShowPersonaliseImage] = useState(false);
  const [maxCharacters, setMaxCharacters] = useState(MAX_CHARACTERS);

  const [socialNetworks, setSocialNetworks] = useState<string[]>([]);
  const [sendingImg, setSendingImg] = useState<boolean>(false);

  const [componentPosition, setComponentPosition] = useState(positionDesign);
  const [componentColor, setComponentColor] = useState(colorsDesign);
  const [loading, setLoading] = useState(false);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const emojiPickerRef = useRef<HTMLDivElement>(null);

  const [cropper, setCropper] = useState<Cropper | undefined>();
  const [sliderValues, setSliderValues] = useState<[number, number]>([0, 0]);
  const [isSliderDisabled, setIsSliderDisabled] = useState<boolean>(false);
  const [updateVideoRef, setUpdateVideoRef] = useState(false);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [playing, setPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [vidData, setVidData] = useState<{ videoData: VideoData }>({
    videoData: { dur: 0, fra: [] },
  });
// hello
const [isImageSelected, setIsImageSelected] = useState<ImageSelectionState>({
  imgFile: null,
  state: false,
});
  const [videoWidth, setVideoWidth] = useState<number>(0);
  const [videoHeight, setVideoHeight] = useState<number>(0);

  const { dur = 0, fra = [] } = vidData?.videoData || {};

  useEffect(() => {
    if (videoRef.current) {
      const videoElement = videoRef.current;
      if (videoElement) {
        const handleMetadataLoad = () => {
          const { duration } = videoElement;
          const dura = Math.floor(duration);
          if (duration <= 20) {
            setIsSliderDisabled(false);
          } else {
            setIsSliderDisabled(false);
          }
        };

        // Add event listener for 'loadedmetadata' event
        videoElement.addEventListener('loadedmetadata', handleMetadataLoad);

        // Remove event listener on cleanup
        return () => {
          videoElement.removeEventListener(
            'loadedmetadata',
            handleMetadataLoad,
          );
        };
      }
    }
  }, [updateVideoRef]);

  const { data: dataMedia }: DataMedia = useMedia(companySelected?.id);

  const history = useHistory();

  const videoNotAcceptedByGoogle = () => {
    const newSocialMedia = socialNetworks.filter(
      socialNetwork => socialNetwork !== TYPES_OF_SOCIAL_NETWORKS.GOOGLE,
    );

    setSocialNetworks(newSocialMedia);
  };

  const toManyCharactersTwitter = () => {
    const newSocialMedia = socialNetworks.filter(
      socialNetwork => socialNetwork !== TYPES_OF_SOCIAL_NETWORKS.TWITTER,
    );

    setSocialNetworks(newSocialMedia);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      dispatchPost({
        type: TYPES_POSTS.CROP_IMAGE,
        payload: cropper.getCroppedCanvas().toDataURL(),
      });
    }
  };

  const rContainer = useRef<HTMLDivElement>(null);
  const personaliseImageRef = useRef<HTMLDivElement>(null);

  const getTools = async () => {
    const { data }: any = await getToolsCompany();
    if (data) {
      const toolsEnabled = data.filter((tool: ToolAPI) => {
        return tool.enabled && tool.type !== 'uberEats';
      });

      setTools(toolsEnabled);
    }
  };

  useEffect(() => {
    if (companySelected) {
      getTools();
    }
  }, [companySelected]);

  useEffect(() => {
    const { subscriptionStatus, invoiceStatus } =
      user?.companies?.[0]?.payment ?? {};

    if (subscriptionStatus === 'active' && invoiceStatus === 'paid') {
      setDisplayPremiumPlan(true);
    } else {
      setDisplayPremiumPlan(false);
    }
  }, [user]);

  const { mutateAsync } = useMutation(
    (selectedFile: {
      selectedFile: File;
      type: string;
      duration: number;
      trim: number[];
      video_width: number;
      video_height: number;
    }) => fileUpload(selectedFile),
    {
      onSuccess(response: any) {
        queryClient.setQueryData('MEDIA', (oldData: any) => {
          return {
            ...oldData,
            data: [
              {
                ...response.data,
              },
              ...oldData.data,
            ],
          };
        });
      },
    },
  );

  const uploadReel = async (
    selectedFile: File,
    type: string,
    duration?: number,
    trim?: number[],
    video_width?: number,
    video_height?: number,
  ) => {
    const payload: any = {
      selectedFile,
      type,
      trim,
      video_width,
      video_height,
    };

    if (duration !== undefined) {
      payload.duration = duration;
    }

    setTimeout(() => {
      notification({
        type: 'info',
        title: 'Reel Status',
        description:
          "Your reel is being processed. We'll let you know when it's ready to view.",
        duration: 8000,
      });
    }, 10000);

    const { data: dataMediaAdded }: any = await mutateAsync(payload);
    return dataMediaAdded.id;
  };

  const uploadFile = async (selectedFile: File, type: string) => {
    const { data: dataMediaAdded }: any = await mutateAsync({
      selectedFile,
      type,
      duration: 0,
      trim: [],
      video_width: 0,
      video_height: 0,
    });
    return dataMediaAdded.id;
  };

  const handleFileUpload = async (selectedFile: File) => {
    const urlFile = await URL.createObjectURL(selectedFile);

    if (selectedFile.type.includes('image')) {
      dispatchPost({
        type: TYPES_POSTS.ADD_IMAGE_URL,
        payload: { urlFile, name: selectedFile.name },
      });
    } else if (ALLOWED_VIDEO_TYPES.includes(selectedFile.type)) {
      dispatchPost({
        type: TYPES_POSTS.ADD_VIDEO_URL,
        payload: urlFile,
      });

      dispatchPost({
        type: TYPES_POSTS.ADD_FILE,
        payload: selectedFile,
      });
      videoNotAcceptedByGoogle();
      setUpdateVideoRef(true);
    } else {
      notificationError({
        title: 'Cannot upload file',
        description: 'Only images or videos (.mov or .mp4) are allowed',
      });
    }
  };

  const saveImage = async () => {
    const node = await rContainer.current;

    if (!node) return;

    const generalDiv = node?.children;
    const takeImg = generalDiv[0]?.children;

    const tagName = takeImg[0]?.tagName;
    let originalHeight = 500;
    let originalWidth = 500;

    if (tagName === 'IMG') {
      originalHeight = takeImg[0]?.clientHeight || 500;
      originalWidth = takeImg[0]?.clientWidth || 500;

      if (originalWidth / originalHeight < 0.81) {
        originalWidth = (originalHeight * 81) / 100;
      }

      if (originalWidth / originalHeight > 1.89) {
        originalHeight = (originalWidth / 189) * 100;
      }
    }

    const htmltocanva = await html2canvas(node, {
      onclone: clonedNode => {
        if (clonedNode) {
          const containerPreview =
            clonedNode.getElementById('container-preview');

          if (containerPreview) {
            containerPreview.style.maxWidth = '1000px';
            containerPreview.style.maxHeight = '1000px';
            containerPreview.style.width = `${originalWidth}px`;
            containerPreview.style.height = `${originalHeight}px`;
          }
        }
      },
    });

    const dataURL = htmltocanva.toDataURL('image/jpeg');

    const encoded = await dataURLtoFile(dataURL, stateReel.name);

    const img = new Image();
    const objectUrl = URL.createObjectURL(encoded);
    img.src = objectUrl;

    const uploaded = await uploadFile(encoded, 'reels');

    return uploaded;
  };

  const handleReel = async () => {
    try {
      setLoading(true);

      if (
        socialNetworks.includes(TYPES_OF_SOCIAL_NETWORKS.FACEBOOK) ||
        socialNetworks.includes(TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM)
      ) {
        if (videoWidth < 540)
          // minimum reel width
          throw new Error('Reel width should be greater than 540px');
        if (videoHeight < 960)
          // minimum reel height
          throw new Error('Reel height should be greater than 960px');
      }

      const trimVal = sliderValues[1] - sliderValues[0];

      let response = stateReel.id;
      const hasTextOnImage =
        reelPublicationFormik.values.subtitle ||
        reelPublicationFormik.values.title;

      // Check for empty sampleText
      if (!sampleText || sampleText.trim() === '') {
        throw new Error('Sample text cannot be empty or whitespace only.');
      }

      // Check if only Twitter is selected
      const isOnlyTwitterSelected =
        socialNetworks.length === 1 &&
        socialNetworks.includes(TYPES_OF_SOCIAL_NETWORKS.TWITTER);

      // Check if Twitter is selected among other networks
      const isTwitterSelected = socialNetworks.includes(
        TYPES_OF_SOCIAL_NETWORKS.TWITTER,
      );

      if (!stateReel.id || hasTextOnImage || isOnlyTwitterSelected) {
        if (
          !isImageType(stateReel.type) &&
          !isOnlyTwitterSelected &&
          sampleText !== '' &&
          trimVal <= 60
        ) {
          response = await uploadReel(
            stateReel.file,
            'reels',
            videoDuration,
            sliderValues,
            videoWidth,
            videoHeight,
          );
        } else if (
          (hasTextOnImage || stateReel.previewUrl) &&
          !isOnlyTwitterSelected
        ) {
          setSendingImg(true);
          response = await saveImage();
        } else if (isOnlyTwitterSelected) {
          // Twitter is the only selected social network, no media is required
          // Generate a random mediaId between 1 and 100
          response = Math.floor(Math.random() * 100) + 1;
        } else if (isTwitterSelected && socialNetworks.length > 1) {
          throw new Error('Reels without image or video are not allowed.');
        } else {
          throw new Error('Reels without image or video are not allowed.');
        }
      }

      let scheduledAt: null | Date = null;

      if (
        selectedDate &&
        selectedTime &&
        isAfter(getSelectedDate(), new Date())
      ) {
        scheduledAt = getSelectedDate();
      }

      if (trimVal > 60) {
        notificationError({
          title: 'Cannot upload reel',
          description: 'Trim the video to  maximum duration of 60 sec.',
        });
        return;
      }

      await reelsSocial({
        title:
          reelPublicationFormik.values.reelText ||
          reelPublicationFormik.initialValues.reelText,
        mediaId: response,
        socialNetworks,
        scheduledAt,
      });

      history.push('/user-panel/reels');
    } catch (err: any) {
      notificationError({
        title: 'Error',
        description: err?.message || '',
      });
    } finally {
      setLoading(false);
      setSendingImg(false);
    }
  };

  const getSelectedDate = () => {
    if (selectedDate && selectedTime) {
      const date = selectedDate?.setHours(
        selectedTime?.getHours(),
        selectedTime?.getMinutes(),
        0,
        0,
      );

      return new Date(date);
    }

    return new Date();
  };

  const reelPublicationFormik = useFormik<ReelForm>({
    initialValues: {
      reelText: '',
      title: '',
      subtitle: '',
    },
    onSubmit: async () => {
      if (!socialNetworks.length) {
        notificationError({
          title: 'Error',
          description: 'Select a social network',
        });
        return;
      }

      await handleReel();
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    const isTwitterSelected = socialNetworks.find(
      socialNetwork => socialNetwork === TYPES_OF_SOCIAL_NETWORKS.TWITTER,
    );

    if (isTwitterSelected) {
      setMaxCharacters(MAX_CHARACTERS_TWITTER);
    } else {
      setMaxCharacters(MAX_CHARACTERS);
    }
  }, [socialNetworks]);

  // Base on the onClick this below function will trigger the API
  const handleGenerateTags = async () => {
    if (sampleText !== '') {
      if (displayPremiumPlan) {
        setIsGenerating(true);
        const apiResponse = await tagsPost.generateTags(sampleText);
        setIsGenerating(false);
        const dataWithIndex = apiResponse.map((item: any, index: any) => ({
          ...item,
          tag: item.tag.replace(/\s+/g, ''),
          index,
        }));
        setTagData(dataWithIndex);
      } else {
        notificationWithNodes({
          type: 'info',
          title: 'Upgrade your plan',
          description: (
            <>
              <p>
                You&apos;ll need to upgrade to a premium plan to activate this
                feature.
              </p>
              <ButtonNotification
                onClick={() => history.push('/user-panel/account')}
              >
                See plans
              </ButtonNotification>
            </>
          ),
          duration: 10000,
        });
      }
    } else {
      notificationError({
        title: 'Error',
        description: 'Please enter post text for hashtag suggestion.',
      });
      setIsGenerating(true);
    }
  };

  // Using useEffect because we need to trigger the generateTags fuction base on user typing
  useEffect(() => {
    // Created variable to clear the setTimeout
    let typingTimer: any;

    // This function trigger the API base on typing
    const handleTyping = () => {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        if (shouldGenerate) {
          handleGenerateTags();
        }
      }, 1000); // Adjust the delay as needed (in milliseconds)
    };

    // Added if condition to run the funtion when user type something
    if (sampleText !== '' && sampleText.trim() !== '') {
      handleTyping(); // Trigger API call if sampleText is not empty and not just whitespace
    }

    return () => {
      clearTimeout(typingTimer);
    };
    // useEffect only be trigger when user type something
  }, [sampleText, shouldGenerate]);

  // If user click any hashTag, so just removed the objec from the array
  function removeTagFromArray(array: TagDataItem[], tag: string) {
    if (!Array.isArray(array)) {
      return array;
    }

    // filter out the item with the provided tag
    // ignore case and leading/trailing spaces
    return array.filter(
      item => item.tag.trim().toLowerCase() !== tag.trim().toLowerCase(),
    );
  }

  // this function will added the hastage before the each Word
  const handleTagButtonClick = (tag: string) => {
    const sanitizedTag = tag.replace(/\s+/g, ''); // This removes all spaces from the tag
    const updatedResult = removeTagFromArray(tagData, sanitizedTag);

    setTagData(updatedResult);

    const sampleTextWithoutHash = sampleText.replace(/#$/, '').trim();
    const newSampleText = `${sampleTextWithoutHash}${
      sampleTextWithoutHash ? ' ' : ''
    }#${sanitizedTag}`;

    // set SampleText state
    setSampleText(newSampleText);

    // also update Formik's state
    reelPublicationFormik.setFieldValue('reelText', newSampleText);

    setShouldGenerate(false);
  };

  // This function only trigger the API when # found in the textarea and if # is remove from the textarea below res will gone
  const handleChange = (e: any) => {
    setSampleText(e.target.value);

    reelPublicationFormik.setFieldValue('reelText', e.target.value); // set reelText value also incase tags button isnt pressed

    handleCounterTextLength(e.target.textLength || 0);

    if (e.target.value.includes('#')) {
      setShouldGenerate(true);
    } else {
      setShouldGenerate(false);
      setTagData([]);
    }
  };

  const toggleEmojiPicker = () => {
    if (!displayPremiumPlan) {
      notificationWithNodes({
        type: 'info',
        title: 'Upgrade your plan',
        description: (
          <>
            <p>
              You&apos;ll need to upgrade to a premium plan to activate this
              feature.
            </p>
            <ButtonNotification
              onClick={() => history.push('/user-panel/account')}
            >
              See plans
            </ButtonNotification>
          </>
        ),
        duration: 10000,
      });
    } else {
      setIsEmojiPickerOpen(!isEmojiPickerOpen);
    }
  };

  const closeEmojiPicker = () => {
    setIsEmojiPickerOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target as Node)
    ) {
      closeEmojiPicker();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleEmojiClick = (emojiObject: any) => {
    setSampleText(sampleText + emojiObject.emoji);
    reelPublicationFormik.setFieldValue(
      'reelText',
      sampleText + emojiObject.emoji,
    );
  };

  useEffect(() => {
    let intervalId: any;
    if (playing) {
      intervalId = setInterval(() => {
        setCurrentTime(prev => (prev >= dur * 1000 ? 0 : prev + 100));
      }, 100);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [playing, dur]);

  const handleVideoUrl = (videoData: any) => {
    setVidData({ videoData });
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      const duration = Math.floor(videoRef.current.duration);
      const width = videoRef.current.videoWidth;
      const height = videoRef.current.videoHeight;

      setVideoWidth(width); // Assuming you have a setter for this
      setVideoHeight(height);
      setVideoDuration(duration);
      setSliderValues([0, duration]);
      if (duration > 60) {
        notification({
          type: 'info',
          title: 'Reel Status',
          description: 'Trim the video to  maximum duration of 60 sec.',
        });
      } else if (duration <= 60) {
        notification({
          type: 'info',
          title: 'Reel Status',
          description: 'No Need to Trim the video duration',
        });
      }
    }
  };

  if (!companySelected?.id) {
    return <Loader center content="loading" />;
  }

  return (
    <Container>
      <Row>
        <Col xs={24} lg={12}>
          <Subtitle>New Reel</Subtitle>
          <TitleForm>Select Tools</TitleForm>
          {tools
            .filter(
              item =>
                item.type !== TYPES_OF_SOCIAL_NETWORKS.TWITTER &&
                item.type !== TYPES_OF_SOCIAL_NETWORKS.GOOGLE,
            )
            .map(tool => (
              <label htmlFor={tool.type} key={tool.accessToken}>
                <input
                  style={{ opacity: 0 }}
                  id={tool.type}
                  type="checkbox"
                  name={tool.type}
                  onChange={e => {
                    if (e.target.checked) {
                      setSocialNetworks([...socialNetworks, tool.type]);
                    } else {
                      const newSocialMedia = socialNetworks.filter(
                        socialNetwork => socialNetwork !== tool.type,
                      );
                      setSocialNetworks(newSocialMedia);
                    }
                    if (
                      tool.type === TYPES_OF_SOCIAL_NETWORKS.TWITTER &&
                      reelPublicationFormik.values.reelText.length > 280
                    ) {
                      toManyCharactersTwitter();
                      notificationError({
                        title: 'Error',
                        description:
                          'Twitter accepts text of maximum 280 characters',
                      });
                    }
                    if (tool.type === TYPES_OF_SOCIAL_NETWORKS.TWITTER) {
                      notification({
                        type: 'info',
                        title: 'Twitter Media',
                        description:
                          'This post will be published on Twitter as text only',
                      });
                    }
                  }}
                />
                <ImgTools
                  src={
                    selectImgStatus(
                      tool.type,
                      socialNetworks.includes(tool.type),
                    ).image
                  }
                  alt={tool.type}
                />
              </label>
            ))}
          <TitleForm>Text</TitleForm>
          <TextAreaWrapper ref={emojiPickerRef}>
            <Textarea
              onClick={closeEmojiPicker}
              placeholder="Enter Reel Text"
              rows={4}
              // added the hashTag Value //
              value={sampleText}
              maxLength={maxCharacters}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                handleChange(e);
              }}
            />
            <SimleIcon
              className="smiley-icon"
              src={simleIcon}
              alt="simle"
              onClick={toggleEmojiPicker}
            />
            {isEmojiPickerOpen && (
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            )}
          </TextAreaWrapper>

          <CounterTextarea>
            <span>{counterTextLength}</span>
            <span>/{maxCharacters}</span>
          </CounterTextarea>

          <ButtonTags
            disabled={isGenerating || sampleText === ''}
            type="submit"
            onClick={handleGenerateTags}
          >
            {isGenerating ? (
              <Loader content="Loading..." />
            ) : (
              <span>Suggest hashtags 👉🏻</span>
            )}
          </ButtonTags>

          {/* Display HashTags */}
          {!isGenerating && (
            <TagsDisplayContainer>
              {Array.isArray(tagData) &&
                tagData?.map(item => {
                  return (
                    <div key={item.id}>
                      <button
                        type="submit"
                        onClick={() => handleTagButtonClick(item?.tag)}
                      >
                        <span>#{item?.tag.replace(/\s+/g, '')}</span>
                      </button>
                    </div>
                  );
                })}
            </TagsDisplayContainer>
          )}

          <TitleForm>Reel Video</TitleForm>
          <Row>
            <Col xs={24} md={12}>
              <VideoFrameExtractor
                handleVideoUrl={handleVideoUrl}
                handleFile={handleFileUpload}
              />
            </Col>
            {dataMedia?.data?.length ? (
              <Col xs={24} md={12}>
                <ButtonDashed type="button" onClick={toggleShowSelectMedia}>
                  <img src={DownloadIcon} alt="Get your files" />
                  <p>Your uploaded files.</p>
                </ButtonDashed>
              </Col>
            ) : null}
          </Row>
        </Col>

        <Col xs={24} lg={12}>
          <Subtitle>Preview</Subtitle>

          {!isImageType(stateReel.type) ? (
            <>
              <PreviewBackground>
                <PreviewInner>
                  <VideoContainer>
                    <video
                      ref={videoRef}
                      autoPlay
                      controls
                      onLoadedMetadata={handleLoadedMetadata}
                      style={{ width: '100%', height: 'auto' }}
                      key={stateReel.previewUrl}
                    >
                      <source src={stateReel.previewUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </VideoContainer>
                </PreviewInner>
              </PreviewBackground>
              <Divider />
              {videoDuration > 60 ? (
                <>
                  <TitleForm>No Need to Trim the video duration</TitleForm>
                  <RangeSlider
                    value={sliderValues}
                    min={0}
                    max={videoDuration}
                    onChange={(values: [number, number]) =>
                      setSliderValues(values)
                    }
                  />
                </>
              ) : (
                <TitleForm>No Need to Trim the video duration</TitleForm>
              )}
            </>
          ) : (
            <SimplePreviewBackground>
              <span>Your video preview</span>
              <span>Upload your video in order to see a preview here.</span>
            </SimplePreviewBackground>
          )}
          <Divider />
          <FlexboxGrid justify="end" align="bottom">
            {/* <FlexboxGrid.Item componentClass={Col} xs={24} md={12}>
              <span>Schedule reel (optional)</span>
              <DateInputContainer>
                <DateTimeRounded
                  placeholder="Select Date"
                  format="DD.MM.YYYY"
                  disabledDate={(date: Date) =>
                    isBefore(date, subDays(new Date(), 1))
                  }
                  ranges={[]}
                  onChange={(date: Date) => setSelectedDate(date)}
                  onClean={() => setSelectedTime(null)}
                />

                <DateTimeRounded
                  apperance="default"
                  disabled={!selectedDate}
                  placeholder="Select Time"
                  format="HH:mm"
                  disabledHours={(hour: number) => {
                    if (selectedDate && isToday(new Date(selectedDate))) {
                      const currentHour = new Date().getHours();
                      return hour <= currentHour;
                    }
                    return false;
                  }}
                  ranges={[]}
                  onChange={(date: Date) => setSelectedTime(date)}
                  renderValue={(date: Date, formatPicker: string) => (
                    <span>
                      {selectedTime
                        ? format(date, formatPicker)
                        : 'Select Time'}
                    </span>
                  )}
                />
              </DateInputContainer>
            </FlexboxGrid.Item> */}
            <FlexboxGrid.Item componentClass={Col} xs={24} md={10}>
              <ButtonAdd
                onClick={() => reelPublicationFormik.submitForm()}
                disabled={loading || !!selectedDate !== !!selectedTime}
              >
                {loading ? (
                  <Loader content="Loading..." />
                ) : (
                  <span>
                    {selectedDate &&
                    selectedTime &&
                    isAfter(getSelectedDate(), new Date())
                      ? `Reel on ${formatDate(getSelectedDate())}`
                      : 'Reel Now'}
                  </span>
                )}
              </ButtonAdd>
            </FlexboxGrid.Item>
            {videoDuration > 60 ? (
              <ParagraphAdvert>
                If video trim tool is not used, reel may not published.
                successfully.
              </ParagraphAdvert>
            ) : (
              ''
            )}
          </FlexboxGrid>
        </Col>
      </Row>

      {showPersonaliseImage && isImageType(stateReel.type) && (
        <DivForm ref={personaliseImageRef}>
          <IconInput
            inputType="text"
            value={reelPublicationFormik.values.title}
            onChange={reelPublicationFormik.handleChange('title')}
            placeholder="Title"
            error={reelPublicationFormik.errors.title}
          />
          <IconInput
            inputType="text"
            value={reelPublicationFormik.values.subtitle}
            onChange={reelPublicationFormik.handleChange('subtitle')}
            placeholder="Subtitle"
            error={reelPublicationFormik.errors.subtitle}
          />
          {!stateReel.previewUrl && (
            <FlexboxGrid align="middle">
              <Col md={6}>Background color</Col>
              <Col>
                <ColorsContent
                  componentColor={componentColor}
                  typePosition="background"
                  setComponentColor={setComponentColor}
                />
              </Col>
            </FlexboxGrid>
          )}
          <FlexboxGrid align="middle">
            <Col md={6}>Title colour</Col>
            <Col>
              <ColorsContent
                componentColor={componentColor}
                typePosition="colorTitle"
                setComponentColor={setComponentColor}
              />
            </Col>
          </FlexboxGrid>
          <FlexboxGrid align="middle">
            <Col md={6}>Subtitle colour</Col>
            <Col>
              <ColorsContent
                componentColor={componentColor}
                typePosition="colorSubtitle"
                setComponentColor={setComponentColor}
              />
            </Col>
          </FlexboxGrid>
          <FlexboxGrid align="middle">
            <Col md={6}>Title alignment</Col>
            <Col xs={24} md={18}>
              <PositionContent
                componentPosition={componentPosition}
                typePosition="alignTitle"
                setComponentPosition={setComponentPosition}
                alignText
              />
            </Col>
          </FlexboxGrid>
          <FlexboxGrid align="middle">
            <Col md={6}>Title position</Col>
            <Col xs={24} md={18}>
              <PositionContent
                componentPosition={componentPosition}
                typePosition="positionTitle"
                setComponentPosition={setComponentPosition}
              />
            </Col>
          </FlexboxGrid>
          <FlexboxGrid align="middle">
            <Col md={6}>Subtitle alignment</Col>
            <Col xs={24} md={18}>
              <PositionContent
                componentPosition={componentPosition}
                typePosition="alignSubtitle"
                setComponentPosition={setComponentPosition}
                alignText
              />
            </Col>
          </FlexboxGrid>
          <FlexboxGrid align="middle">
            <Col md={6}>Subtitle position</Col>
            <Col xs={24} md={18}>
              <PositionContent
                componentPosition={componentPosition}
                typePosition="positionSubtitle"
                setComponentPosition={setComponentPosition}
              />
            </Col>
          </FlexboxGrid>
        </DivForm>
      )}

      <ModalSelectMedia
        toggleShowSelectMedia={toggleShowSelectMedia}
        media={dataMedia?.data || []}
        showSelectMedia={showSelectMedia}
        dispatchPost={dispatchPost}
        videoNotAcceptedByGoogle={videoNotAcceptedByGoogle}
        setIsImageSelected={setIsImageSelected}
      />
      {showCropImage && isImageType(stateReel.type) && (
        <ModalCropperImage
          showCropImage={showCropImage && isImageType(stateReel.type)}
          toggleShowCropImage={toggleShowCropImage}
          originalUrl={stateReel.originalUrl}
          setCropper={setCropper}
          cropper={cropper}
          getCropData={getCropData}
        />
      )}
    </Container>
  );
};

export default CreateReel;
