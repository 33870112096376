import CreatePost from '../components/createPost/CreatePost';
import CreateReel from '../components/createReel/CreateReel';
import Account from '../views/account/Account';
import Analytics from '../views/analytics/Analytics';
import CreatePromotion from '../views/createPromotion/CreatePromotion';
import Dashboard from '../views/dashboard/Dashboard';
import Delivery from '../views/delivery/Delivery';
import Error404 from '../views/error404/Error404';
import Help from '../views/help/Help';
import PaymentCancel from '../views/paymentCancel/PaymentCancel';
import PaymentSuccess from '../views/paymentSuccess/PaymentSuccess';
import Posts from '../views/posts/Posts';
import Promotions from '../views/promotions/Promotions';
import Reels from '../views/reels/Reels';

import RegistrationComplete from '../views/registrationComplete/RegistrationComplete';
import Reviews from '../views/reviews/Reviews';
import Tools from '../views/tools/Tools';

const routes = [
  {
    path: '/dashboard',
    component: Dashboard,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/welcome',
    component: RegistrationComplete,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/tools',
    component: Tools,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/tools/media',
    component: Tools,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/delivery',
    component: Delivery,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/delivery/menu',
    component: Delivery,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/posts',
    component: Posts,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/posts/new',
    component: CreatePost,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/reels',
    component: Reels,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/reels/new',
    component: CreateReel,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/promotions',
    component: Promotions,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/promotions/new/:id?',
    component: CreatePromotion,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/order/success',
    component: PaymentSuccess,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/order/canceled',
    component: PaymentCancel,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/reviews',
    component: Reviews,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/analytics',
    component: Analytics,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/account',
    component: Account,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/help',
    component: Help,
    isPrivate: true,
    exact: true,
  },
  {
    path: '*',
    component: Error404,
    isPrivate: false,
    exact: false,
  },
];

export default routes;
