import styled from 'styled-components';
import { Input } from 'rsuite';

// Container for the input field
export const InputContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width:95%;
  // margin:0 10px;
    @media (max-width: 780px) {
    width:95%;
  }
    @media (max-width: 380px) {
    width:90%;
      margin-bottom: 40px;

  }

`;
export const InputContainer2 = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
        padding: 0px 7px;

  width:80%;
    @media (max-width: 780px) {
    width:100%;
  }
    @media (max-width: 380px) {
    width:100%;
      // margin-bottom: 40px;

  }

`;

// Label for the input field
export const Label = styled.label`
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 14px;
  color: #333;
`;

// Container for the label and additional elements like the "View" link
export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

// Styling for the actual input field
export const StyledInput = styled(Input)<{ error?: any }>`
  border-color: ${(props) => (props.error ? '#dd2256' : 'initial')};
  font-family: 'Inter', sans-serif;
  width: 100%;
  padding: 1.2rem 2rem;
  margin: 1rem auto;
  border-radius: 15px;
  border: none;
  background-color: ${({ error }) => (error ? '#ffe6e6' : '#ffd6df')};
  font-size: 1rem;
  color: #dd2256;
  outline: none;

  &::placeholder {
    color: #dd2256;
  }

  &:focus {
    border-color: #f857a6;
  }

  @media (max-width: 380px) {
    font-size: 14px;
  }
`;

// Clickable span to toggle password visibility
export const EyeSpan = styled.span`
  cursor: pointer;
  font-size: 14px;
  color: #007bff;

  &:hover {
    text-decoration: underline;
  }
`;

// Error message styling
export const ErrorMessage = styled.div`
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 5px;
`;

// Hint message for password requirements
export const HintMessage = styled.div`
  font-size: 12px;
  color: #8c8c8c;
  margin-top: 8px;
  line-height: 1.5;
`;
