import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import {
  ContentContainer,
  Title,
  // StyledInput,
  Titlembile,
  StyledErrorMessage,
  Subtitlemobilebottom,
  BackButtondiv,
  BackButtonnext,
  DesktopBackButtonContainer,
  NextButtonContainer,
  BackCancelContainer,
  DesktopBackButton,
  Button,
  // PasswordContainer,
  Subtitlemobile,
  Subtitle,
} from './step1.style';
import {
  StyledInput,
  // Subtitlemobile,
} from '../DinabiteSignupPanel/globaldinabite.style';
import { firstPageSocialSchema } from '../../components/forms/formSignUp/validation';
import PasswordInput from '../../components/inputs/PasswordInput/PasswordInput';

interface FormValues {
  email: string;
  password: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  country?: string;
  name?: string;
  accountType?: string;
}

interface Step1Props {
  onNext: (values: any) => void;
  onBack: () => void;
  onCancel: () => void;
  initialValues: FormValues;
}

const Step1: React.FC<Step1Props> = ({
  onNext,
  onBack,
  onCancel,
  initialValues,
}: Step1Props) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={firstPageSocialSchema}
      enableReinitialize
      validateOnMount
      onSubmit={(values, { setSubmitting, validateForm }) => {
        setIsLoading(true);
        validateForm().then(errors => {
          if (Object.keys(errors).length === 0) {
            onNext(values);
          } else {
            setIsLoading(false);
          }
          setSubmitting(false);
        });
      }}
    >
      {({ isSubmitting, touched, errors, handleChange, handleBlur }) => (
        <Form>
          <ContentContainer>
            <Title>Welcome to the Future of Marketing</Title>
            <Subtitle>
              Hey there! Ready to embark on a marketing adventure with Dinabite?
              Let’s kick things off with your email and a secure password.
            </Subtitle>
            <Titlembile>Welcome to the Future of Marketing</Titlembile>
            <Subtitlemobile>
              Ready to embark on a marketing adventure with Dinabite.ai? Let’s
              kick things off with your email and a secure password.
            </Subtitlemobile>

            {/* Email Field */}
            <Field
              name="email"
              type="email"
              placeholder="Email"
              as={StyledInput}
            />
            <ErrorMessage name="email" component={StyledErrorMessage} />

            {/* Password Field using PasswordInput component */}
            <PasswordInput
              signUp
              placeholder="Password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              />
            <StyledErrorMessage>
              {touched.password && errors.password ? errors.password : undefined}
            </StyledErrorMessage>

            {/* Confirm Password Field */}
            <PasswordInput
              signUp
              placeholder="Confirm Password"
              name="confirmPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              // isError={!!(touched.confirmPassword && errors.confirmPassword)}
            />
            <StyledErrorMessage>
              {touched.confirmPassword && errors.confirmPassword
                ? errors.confirmPassword
                : undefined}
            </StyledErrorMessage>

            <Subtitlemobilebottom>
              Exciting times ahead! With Dinabite, you’ll be posting on
              Facebook, Instagram, Twitter/X, and Google My Business like a pro.
              Let’s get started!
            </Subtitlemobilebottom>

            <DesktopBackButtonContainer>
              <NextButtonContainer>
                <Button type="submit" disabled={isLoading || isSubmitting}>
                  {isLoading ? 'Loading...' : 'Next Step'}
                </Button>
              </NextButtonContainer>
              <BackCancelContainer>
                <DesktopBackButton onClick={onBack}>Back</DesktopBackButton>
              </BackCancelContainer>
            </DesktopBackButtonContainer>
            <BackButtondiv>
            <BackButtonnext type="submit" disabled={isLoading || isSubmitting}>
              {isLoading ? 'Loading...' : 'Next Step'}
            </BackButtonnext>
          </BackButtondiv>
          </ContentContainer>
        </Form>
      )}
    </Formik>  );
};

export default Step1;
