import React,{ useEffect, useState }  from 'react';
// import { useHistory } from 'react-router-dom';
import {
  BackButtondiv,
  ContentContainer,
  NextButtonContainer,
  Button,
  BackButtonnext,
} from './step0.style';
import {
  Title,
    Subtitle,
    SocialButton,
    SocialText,
    Titlembile,
  Subtitlemobile,
} from '../DinabiteSignupPanel/globaldinabite.style';
import RegisterButtons from '../../components/registerButtons/RegisterButtons';
import iconemail from '../../assets/icons/username.png';
import { useAuth } from '../../context/AuthContext';
import useUser from '../../hooks/useUser';

interface Step0Props {
  onNext: (values: any) => void;
  onBack: () => void;
  onCancel: () => void;
  setCurrentStep: (step: number) => void; // Add this prop
}

const Step0: React.FC<Step0Props> = ({ onNext, onBack, onCancel, setCurrentStep }:Step0Props) => {
//   const history = useHistory();
const [isLoading, setIsLoading] = useState(false);
const { loginSocial } = useUser();
const { loginProvider, setLoginProvider } = useAuth();
// const [currentStep, setCurrentStep] = useState(2);

  const handleNextStep = () => {
    onNext({});
    // Optional: You can also use history.push('/sign-up'); if navigation is required
  };

  return (
    <>
        <Title>Create an account</Title>

      <ContentContainer>
        <Subtitle>
          Dinabite is your ultimate marketing sidekick, combining
          cutting-edge AI with essential tools to help you achieve your
          marketing goals.
        </Subtitle>
        <Titlembile>Sign in Sign up</Titlembile>
        <Subtitlemobile>
          Dinabite is your ultimate marketing sidekick, combining
          cutting-edge AI with essential tools to help you achieve your
          marketing goals.
        </Subtitlemobile>
        <SocialButton onClick={handleNextStep}>
          <img
            src={iconemail}
            alt="Sign Up with Username"
            style={{ width: '24px', height: '24px' }} // Adjust as needed
          />
          <SocialText>Sign up with Email</SocialText>
        </SocialButton>

        <RegisterButtons
          googleButtonText="Sign Up with Google"
          facebookButtonText="Sign Up with Facebook"
          signUP
          setCurrentStep={setCurrentStep} // Passing setCurrentStep function as a prop
        />
      <NextButtonContainer>
        <Button onClick={onCancel}>Back</Button>
      </NextButtonContainer>
      </ContentContainer>

      <BackButtondiv>
        <BackButtonnext onClick={onCancel}>Back</BackButtonnext>
      </BackButtondiv>
    </>
  );
};

export default Step0;
