/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from '../lib/axios';
import { INITIAL_VALUES_PROVIDER, TOKEN } from '../lib/globalValues';

interface Provider {
  provider: string;
  tokenID: string;
  userID: string | null;
  socialProvider?: string;
  data?: {
    email: string;
    name: string;
    socialId: string;
    register?: boolean;
  };
}

type authContextType = {
  companySelected: Company | null;
  user: User | null;
  loginProvider: Provider;
  setLoginProvider: React.Dispatch<React.SetStateAction<Provider>>;
  setCompanySelected: React.Dispatch<React.SetStateAction<Company | null>>;
  setUser: any;
  loggedInCheck: () => Promise<void>;
};

type Props = {
  children: ReactNode;
};

const AuthContext = createContext<authContextType>({} as authContextType);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: Props) {
  const [user, setUser] = useState<User | null>(null);
  const [companySelected, setCompanySelected] = useState<Company | null>(null);

  const [loginProvider, setLoginProvider] = useState<Provider>(
    INITIAL_VALUES_PROVIDER,
  );

  const loggedInCheck = async () => {
    if (localStorage.getItem(TOKEN)) {
       console.log("checked user")
      try {
        const { data } = await axios.get('/users/me');
        setUser(data);

        setCompanySelected(data.companies[0]);
      } catch (err) {
        localStorage.removeItem(TOKEN);
      }
    }
  };

  useEffect(() => {
    loggedInCheck();
  }, []);

  const value = {
    companySelected,
    user,
    loginProvider,
    setLoginProvider,
    setCompanySelected,
    setUser,
    loggedInCheck,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
