import styled from 'styled-components';

// interface StepCircleProps {
//     active: boolean;
//   }

export const Title = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: 35x;
  color: #dd2256;
  font-family: 'Inter', sans-serif;
  text-align: center; 
  width: 80%;
  
  margin: 0 auto;
  margin-bottom: 20px;

  @media (max-width: 1480px) {
      width: 80%;
    margin-bottom: 10px;
    margin: 0 auto;
    font-size: 30px;
      margin-bottom: 20px;

  }
  @media (max-width: 1280px) {
      width: 90%;
    margin-bottom: 10px;
    margin: 0 auto;
    font-size: 22px;
      margin-bottom: 20px;

  }
  @media (max-width: 1024px) {
  
    font-size: 20px;
      margin-bottom: 20px;

  }
  @media (max-width: 780px) {
    display: none;
  }
`;
export const Titlembile = styled.h2`
display:none;

@media (max-width: 780px) {
    font-family: 'Inter', sans-serif;
    width: 90%;
    margin:0 auto;

    display: block; /* Show on mobile */
    font-size: 20px;
    color: #dd2256;
    font-family: 'Inter', sans-serif;
    text-align: left;

    @media (max-width: 480px) {
      width: 90%;
      margin:0 auto;
      font-size: 18px;
      text-align:center;
    }
    @media (max-width: 390px) {
      width: 90%;
      font-size: 18px;
      line-height:30px;
      text-align:center;

    }
  }
`;
export const Subtitle = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  width: 70%;
  margin: 0 auto;
    margin-bottom: 20px;
  text-align:center;
  @media (max-width: 1480px) {
    width: 70%;
      margin: 0 auto;
    font-size: 14px;
    color: #666;
  }
     @media (max-width: 1280px) {
    font-size: 12px;
    width: 75%;
  }
  @media (max-width: 1024px) {
    font-size: 12px;
    width: 75%;

  }

 
  @media (max-width: 780px) {
    display: none;
  }
`;

export const Subtitlemobile = styled.p`
  display: none;
  text-align: center;
  @media (max-width: 780px) {

    width: 90%;
    font-family: 'Inter', sans-serif;
    display: block;
    font-size: 18px;
    color: #666;
    text-align: left; /* Center the text */
    font-size: 12px;
      text-align: left;
    
  }

  @media (max-width: 380px) {
    font-size: 12px;
      text-align: left;

  }
`;
export const Subtitlemobilebottom = styled.p`
  display: none;

  @media (max-width: 780px) {
    display: block;
    width: 95%;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: #666;
    text-align: left;
    margin: 0;
    // margin-top:5px;

  }
  @media (max-width: 480px) {
    display: block;
    width: 95%;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: #666;
    text-align: left;
    margin: 0;
    // margin-top:5px;

  }
`;

export const BackButtondiv = styled.div`
  display: none;
  width: 100%;
  background-color: #fff;
  @media (max-width: 780px) {
    display: flex;
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background: #fff;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    min-height: 60px;
  }

  @media (max-width: 480px) {
    dispaly: block;
    padding: 0;
    bottom: 0;
    background-color: #fff;
    height: 100px;
  }
  @media (max-width: 380px) {
    padding: 0;
    bottom: 0px;
    background-color: #fff;
    height: 70px;
  }
`;

export const BackButtonnext = styled.button`
  color: #fff;
  width: 60%;
  border: 1px solid #dd2256;
  border-radius: 12px;
  font-size: 14px;
  cursor: pointer;
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #c51e4a;
    color: #fff;
  }
  @media (max-width: 780px) {
    width: 75%;
    height: 2.5rem;
    bottom: 30px;
  }

  @media (max-width: 480px) {
    width: 78%;
    height: 3rem;
    font-size: 18px;
    border-radius: 15px;
    position: absolute;
    bottom: 30px;
  }

  @media (max-width: 380px) {
    width: 80%;
    height: 3rem;
    font-size: 18px;
    border-radius: 15px;
    bottom: 10px;
  }
`;

export const StyledInput = styled.input<{ isError?: boolean }>`
  // border-color: ${props => (props.isError ? 'red' : 'initial')};
  font-family: 'Inter', sans-serif;
  width: 67%;
  padding: 1.2rem 2rem;
  margin: 1rem auto;
  border-radius: 15px;
  border: none;
  // background-color: ${({ isError }) => (isError ? '#ffe6e6' : '#ffd6df')};
  border-color: #ffd6df;
  background-color: #ffd6df;
  font-size: 1rem;
  color: #dd2256;
  outline: none;

  &::placeholder {
    color: #dd2256;
  }
  ::-ms-input-placeholder {
    color: #dd2256;
  }
  &:focus {
    border-color: #f857a6;
  }
  @media (max-width: 1480px) {
    padding: 0.9rem 2rem;

    width: 72%;
  }
  @media (max-width: 1020px) {
    padding: 0.9rem 2rem;

    width: 71%;
  }
  @media (max-width: 780px) {
    width: 75%;
  }
  @media (max-width: 480px) {
    width: 95%;
  }
  @media (max-width: 390px) {
    width: 95%;
    font-size: 14px;
  }
`;
export const ContentContainer = styled.div`
  // flex: 1;
  width: 90%;
  margin:0 auto;
  overflow-y: auto; /* Allow scrolling for content */
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  
  @media (max-width: 780px) {
  width:80%;
    padding-bottom: 120px;
  }
`;
export const DesktopBackButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center; 
  align-items: center; /* Centers the buttons vertically */
  width: 68%;
  background: #fff;
  z-index: 1000;
  border-radius: 15px 15px 0 0;
  font-family: 'Inter', sans-serif;
  margin-top:40px;
  gap:30px;
  @media (max-width: 1480px) {
    width: 72%;
  }
  @media (max-width: 780px) {
    display: none;
  }
`;
export const NextButtonContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: start; 
  align-items: center; /* Ensures the button is centered vertically */
  
`;

export const BackCancelContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: end; 
  align-items: center; /* Ensures the button is centered vertically */
  border: 0.1em solid #FFFFFF;
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none; 
  font-weight: 300;
  color: #FFFFFF;
  text-align: center;
  transition: all 0.2s;

  @media (max-width: 780px) {
  display:none;
  }
`;
export const Button = styled.button`
  width: 100%; /* Makes the button fill its container */
  background: linear-gradient(180deg, #DD2256 0%, #E43C39 100%);
  color: white;
  padding: 10px 0; /* Standardizes padding to ensure the same height */
  border: none;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 0; /* Removes top margin to prevent height differences */
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c51e4a;
  }

  @media (max-width: 1480px) {
    font-size: 14px;
    border-radius: 10px;
      padding: 9px 0; /* Standardizes padding to ensure the same height */

  }
  @media (max-width: 1020px) {
    font-size: 12px;
    border-radius: 10px;
      padding: 8px 0; /* Standardizes padding to ensure the same height */

  }

  @media (max-width: 780px) {
    display: none;
  }
`;


// Styled button for desktop back action with hover and responsive styling
export const DesktopBackButton = styled.button`
  width: 100%; /* Makes the button fill its container */
  font-family: 'Inter', sans-serif;
  background-color: #fff;
  color: #dd2256;
  padding: 10px 0; /* Standardizes padding to ensure the same height */
  border: 1px solid #dd2256;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 0; /* Removes top margin to prevent height differences */
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;

  &:hover {
    background: linear-gradient(180deg, #DD2256 0%, #E43C39 100%);
    color: #fff;
  }

  @media (max-width: 1480px) {
    font-size: 14px;
    border-radius: 10px;
          padding: 8px 0; /* Standardizes padding to ensure the same height */

  }
  @media (max-width: 1020px) {
    font-size: 12px;
    border-radius: 10px;
      padding: 8px 0; /* Standardizes padding to ensure the same height */

  }
  @media (max-width: 780px) {
    display: none;
  }
`;

export const StyledErrorMessage = styled.div`
  color: red;
  font-size: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  width:65%;
  color:red;
  @media (max-width: 1480px) {
    margin-top: none;
    // margin-bottom: none;
    // line-height:0px;

  }
  @media (max-width: 780px) {
    width:90%;

  }
`;


export const PasswordContainer = styled.div`
  display: flex;
  width:1%;
  margin:0 auto;
  flex-direction: column;
  gap: 16px; /* Adjust the gap between inputs as needed */
  margin-bottom: 20px; /* Add some margin to separate it from other elements */
  @media (max-width: 780px) {

    width:70%;

  }
`;
