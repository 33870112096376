import { Row } from 'rsuite';
import styled from 'styled-components';
import backgroundImage from '../../assets/images/coctel.png';


export const Title = styled.p`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.bigSubtitle};
  font-weight: 550;

  @media (max-width: 768px) {
    font-size: ${props => props.theme.fonts.subtitle}; // Use an existing property
    text-align: center; // Center text for small screens
  }

  @media (min-width: 769px) {
    font-size: ${props => props.theme.fonts.bigSubtitle};
  }
`;



export const Card = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 50px;
  padding: 20px;
  height: 100%;
`;

export const RecentImageContent = styled.div<{ urlImage: string }>`
  background-image: url(${props => props.urlImage}), url(${backgroundImage});
  background-color: url(${props => props.theme.colors.red});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;

  img {
    padding: 50px 45%;
  }
`;

export const SpaceDiv = styled.div`
  margin: 25px 0px;
`;

export const SpaceRow = styled(Row)`
  margin: 25px 0px;
`;

export const PreviewVideo = styled.div`
  width: 100%;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  video {
    width: 100%;
    border-radius: 20px;
  }
`;
