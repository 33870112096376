/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useReducer, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Divider, FlexboxGrid, Loader } from 'rsuite';
import { TYPES_TOOLS } from '../../actions/toolsActions';
import ImageIcon from '../../assets/icons/image.svg';
import UploadIcon from '../../assets/icons/upload.svg';
import VideoIcon from '../../assets/icons/video.svg';
import SizeCard from '../../components/cards/sizeCard/SizeCard';
import SelectButtons from '../../components/selectButtons/SelectButtons';
import TableFiles from '../../components/tables/tableFiles/TableFiles';
import ToogleTools from '../../components/toogleTools/ToogleTools';
import UploaderImage from '../../components/uploader/uploderImage/UploaderImage';
import { useAuth } from '../../context/AuthContext';
import useMedia from '../../hooks/useMedia';
import useMediaSizes from '../../hooks/useMediaSizes';
import { notificationWithNodes } from '../../lib/notification';
import { objectCleaner } from '../../lib/objects';
import { initialToolsState, toolsReducer } from '../../reducers/toolsReducer';
import { fileUpload } from '../../services/fileUpload';
import { getToolsCompanyObj } from '../../services/getToolsCompany';
import { Container } from '../../styles/generalStyles';
import {
  Card,
  PreviewVideo,
  RecentImageContent,
  SpaceDiv,
  SpaceRow,
  Title,
} from './tools.styles';
// import { TYPES_OF_SOCIAL_NETWORKS } from '../../lib/globalValues';

const CONTENT_SELECT_BUTTONS = [
  {
    value: '/user-panel/tools',
    content: 'Tools',
  },
  {
    value: '/user-panel/tools/media',
    content: 'Media Files',
  },
];

interface DataTools {
  [key: string]: DataToolItem[];
}

interface DataToolItem {
  enabled: boolean;
}

const Tools = () => {
  const queryClient = useQueryClient();
  const { companySelected } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const [showSecondButton, setShowSecondButton] = useState(false);

  const [stateTool, dispatchTool] = useReducer(toolsReducer, initialToolsState);

  const { mutate, isLoading } = useMutation(
    (selectedFile: { selectedFile: File }) => fileUpload(selectedFile),
    {
      onSuccess(response) {
        queryClient.setQueryData('MEDIA', (oldData: any) => {
          return {
            ...oldData,
            data: [
              {
                ...response.data,
              },
              ...oldData.data,
            ],
          };
        });
      },
    },
  );

  const { data: dataMedia } = useMedia(companySelected?.id);
  const { data: dataMediaSizes } = useMediaSizes(companySelected?.id);

  useEffect(() => {
    if (dataMedia?.data?.length > 0) {
      setShowSecondButton(true);
    } else {
      setShowSecondButton(false);
      history.push('/user-panel/tools');
    }
  }, [dataMedia?.data, showSecondButton]);

  useEffect(() => {
    const updateData = async () => {
      if (companySelected?.id) {
        const { data: dataTools } = await getToolsCompanyObj(
          companySelected.id,
        );

        const cleanedStateTool = objectCleaner(dataTools);

        const isAnyToolConnected = Object.keys(cleanedStateTool).length > 0;

        if (!isAnyToolConnected) {
          notificationWithNodes({
            type: 'success',
            title: '',
            description: (
              <>
                <p>Welcome to dinabite!</p>
                <p>Please connect your tools</p>
              </>
            ),
          });
        }

        const filteredDataTools = Object.keys(dataTools).reduce<
          Record<string, DataToolItem>
        >((acc, key) => {
          // Ensure dataTools is treated as DataTools type
          const tools: DataTools = dataTools;

          if (Array.isArray(tools[key])) {
            const enabledObject = tools[key].find(
              (item: DataToolItem) => item.enabled,
            );
            if (enabledObject) {
              acc[key] = enabledObject;
            }
          }
          return acc;
        }, {});

        //     dispatchTool({ type: TYPES_TOOLS.INIT_TOOLS, payload: dataTools });
        dispatchTool({
          type: TYPES_TOOLS.INIT_TOOLS,
          payload: filteredDataTools,
        });
      }
    };
    updateData();
  }, [companySelected]);

  if (!companySelected?.id) {
    return <Loader center content="loading" />;
  }

  const handleFileUpload = async (selectedFile: File) => {
    mutate({ selectedFile });
  };

  return (
    <Container>
      <FlexboxGrid align="middle" justify="space-between">
        <FlexboxGrid.Item componentClass={Col} xs={24} md={7}>
          <Title>Tools</Title>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} xs={24} md={10}>
          <SelectButtons
            content={
              showSecondButton
                ? CONTENT_SELECT_BUTTONS
                : [CONTENT_SELECT_BUTTONS[0]]
            }
            defaultValue={location.pathname}
            setPage={value => history.push(value as string)}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} xsHidden smHidden md={7} />
      </FlexboxGrid>

      {location.pathname === CONTENT_SELECT_BUTTONS[0].value && (
        <ToogleTools
          card
          stateTool={stateTool}
          dispatchTool={dispatchTool}
          invalidateQuery={() => {
            queryClient.invalidateQueries(['MESSAGES']);
            queryClient.invalidateQueries(['TOOLS']);
          }}
          companyId={companySelected.id}
        />
      )}

      {location.pathname === CONTENT_SELECT_BUTTONS[1].value && (
        <SpaceRow>
          <Col xs={24} md={17}>
            <FlexboxGrid align="middle">
              <Col xs={24} md={7}>
                <UploaderImage
                  handleFileUpload={handleFileUpload}
                  describe="Upload your media files"
                  image={UploadIcon}
                />
              </Col>

              <Col xsHidden sm={24} md={17}>
                <SpaceDiv>
                  <Card>
                    <p>Recently Added</p>
                    <FlexboxGrid align="middle">
                      {isLoading && <Loader />}
                      {!isLoading &&
                        dataMedia?.data.slice(0, 3).map((file: Media) => (
                          <Col sm={8} key={file.name}>
                            <>
                              {file.type === 'image' ? (
                                <RecentImageContent urlImage={file.url}>
                                  <img src={ImageIcon} alt={file.name} />
                                </RecentImageContent>
                              ) : (
                                <PreviewVideo>
                                  <img src={VideoIcon} alt={file.name} />
                                  <video>
                                    <source src={file.url} type="video/mp4" />
                                  </video>
                                </PreviewVideo>
                              )}
                            </>
                          </Col>
                        ))}
                    </FlexboxGrid>
                  </Card>
                </SpaceDiv>
              </Col>
            </FlexboxGrid>
            <SpaceDiv>
              <Title>Your media files</Title>
            </SpaceDiv>
            <TableFiles
              getDataFiles={dataMedia?.data || []}
              isLoading={isLoading}
            />
          </Col>
          <Col xs={24} md={7}>
            <SpaceDiv>
              <Title>Storage</Title>
            </SpaceDiv>
            {!dataMediaSizes?.data ? (
              <Loader content="loading" />
            ) : (
              Object.entries(dataMediaSizes.data).map(([sizeName, size]) => (
                <div key={`${sizeName}${size}`}>
                  <Divider />
                  <SizeCard key={sizeName} sizeName={sizeName} size={size} />
                </div>
              ))
            )}
          </Col>
        </SpaceRow>
      )}
    </Container>
  );
};

export default Tools;
