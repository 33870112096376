import { Divider, FlexboxGrid, IconButton } from 'rsuite';
import styled from 'styled-components';

export const HeaderStyled = styled(FlexboxGrid)`
  > div {
    padding: 2em 0em;
  }

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    padding: 0 10% 1em;
    width: 100%;
    position:fixed;
    z-index: 1 ;
    background-color: #FDFDFD !important;
    > div {
      padding-bottom: 0em;
    }
  }
`;

export const Title = styled.p`
  color: ${props => props.theme.colors.greyLetter};
  font-size: ${props => props.theme.fonts.bigSubtitle};
  font-weight: 550;
  text-transform: capitalize;
`;

export const DividerSpace = styled(Divider)`
  margin: 0px 10% 20px;
@media (max-width: 1169px) {
  margin: 80px 0rem 20px;
  // padding-left: 20px;

  }

`;


export const FullDivider = styled.div`
  height: 1px;
  margin-bottom: 20px;
  background-color: ${props => props.theme.colors.grey};
`;

export const MenuButton = styled(IconButton)`
  background: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
`;

export const SpaceMenu = styled(FlexboxGrid.Item)`
  padding: 0px;
`;

export const CenterFlex = styled(FlexboxGrid.Item)`
  text-align: center;
`;

export const OptionsHeader = styled(FlexboxGrid.Item)`
  background: ${props => props.theme.colors.pinkRed};
  border-radius: 35px 0px 0px 0px;
  border-left: 2px solid ${props => props.theme.colors.white};

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    background-color: transparent;
    border-left: none;
  }
`;

export const Wrapper = styled(FlexboxGrid.Item)`
  background: ${props => props.theme.colors.pinkRed};
  backdrop-filter: blur(13px);
  border-left: 2px solid ${props => props.theme.colors.white};
  border-radius: 0px 0px 0px 35px;
  margin: 0px;
  min-height: 100vh;
  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    // margin-top: 5%;
    border-radius: 35px 35px 0px 0px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-top: 2px solid ${props => props.theme.colors.white};
    border-left: none;
     @media (max-width: 991px) {
    margin-top: 10%;
    }
     @media (max-width: 768px) {
    margin-top: 15%;
    }
   

  }
`;

export const OptionsMobile = styled(FlexboxGrid.Item)<{ display: boolean }>`
  width: 100%;
  display: ${props => (props.display ? 'block' : 'none')};
`;

export const ImgLogo = styled.img`
  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    width: 100%;
  }
`;
