import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
// import { useHistory } from 'react-router-dom';
import { Loader, FlexboxGrid } from 'rsuite';

import {
  Button,
  BackButton,
  BackButtondiv,
  BackButtonnext,
  ContentContainer,
  DesktopBackButtonContainer,
  NextButtonContainer,
  BackCancelContainer,
  DesktopBackButton,
  // DesktopCancelButton,
  StyledErrorMessage,
  // Titlembile,
  Title,
  Subtitle,
  // Subtitlemobile,
  Subtitlemobilebottom,
  Titlembile,
  StyledInput,
  Subtitlemobile,
} from './step2.style';

// import {

// } from "../DinabiteSignupPanel/globaldinabite.style";
// import { useAuth } from '../../context/AuthContext';
// import useUser from '../../hooks/useUser';
import SelectCountry from '../../components/forms/formSignUp/selectCountry/SelectCountry';
import { secondPageSchema } from '../../components/forms/formSignUp/validation';

// interface SelectCountryProps {
//   menuPlacement: boolean;  // `true` for "top", `false` for "bottom"
//   [key: string]: any;      // Allow other props to be passed in
// }

interface FormValues {
  email: string;
  password: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  country?: string;
  name?: string;
  accountType?: string;
}

interface Step2Props {
  onNext: (values: any) => Promise<void>; // Ensure onNext returns a Promise
  onBack: () => void;
  onCancel: () => void;
  initialValues: FormValues;
  loginProvider: any; // Add this line
  onCountryChange: (country: string) => void; // Add this line
}
const Step2: React.FC<Step2Props> = ({
  onNext,
  onBack,
  onCancel,
  initialValues,
  loginProvider,
  onCountryChange,
}: Step2Props) => {
  // const { loginProvider, setLoginProvider } = useAuth();
  // const { loginSocial } = useUser();
  // const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  // const [country2, setCountryy] = useState("");

  // const [field,setField]=useState("");
  const [formValues, setFormValues] = useState<FormValues>(initialValues);

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is still mounted

    if (loginProvider && loginProvider.data) {
      const { name, email, company, country } = loginProvider.data;
      const [firstName = '', lastName = ''] = name ? name.split(' ') : [];

      if (isMounted) {
        // Check if the component is still mounted before updating state
        setFormValues({
          email: email || '',
          firstName: firstName || '',
          lastName: lastName || '',
          companyName: company || 'company',
          country: country || '',
          password: '',
        });
      }
    }

    // Cleanup function
    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
    };
  }, [loginProvider]);

  const handleCountryChange = (country: string) => {
    // console.log("Country selected is ", country);
    setFormValues(prevValues => ({
      ...prevValues,
      country,
    }));
    // setCountryy(country);
    onCountryChange(country); // Call the parent callback
  };
  return (
    <Formik
      // initialValues={{ ...initialValues, country: 'japan' }}  // Set a default temporary country
      //  initialValues={formValues}
      initialValues={
        loginProvider && loginProvider.data?.email
          ? formValues
          : { ...initialValues }
      }
      validationSchema={secondPageSchema} // Automatic validation using validation schema
      enableReinitialize
      onSubmit={(values, { setTouched,resetForm }) => {
        setTouched({
          email: true,
          firstName: true,
          lastName: true,
          companyName: true,
          country: true,
          password: true,
        });
        setIsLoading(true); // Set loading state to true before submission
        onNext(values)
          .then(() => {
            // console.log("values",values)
            resetForm(); // Reset form after successful submission
            setIsLoading(false); // Reset loading state to false after submission
          })
          .catch(error => {
            // console.error("Error during form submission:", error);
            setIsLoading(false); // Reset loading state to false if an error occurs
          });
      }}>
    {({ setFieldValue, setFieldTouched, touched, errors, isValid, dirty }) => (
        <>
          <Form>
            <ContentContainer>
              <Title>Welcome to the Future of Marketing</Title>
              <Subtitle>
                Hey there! Ready to embark on a marketing adventure with
                Dinabite? Let’s kick things off with your email and a secure
                password.
              </Subtitle>
              <Titlembile>Let’s get to know each other.</Titlembile>
              <Subtitlemobile>
                Awesome. Lets go to know you a bit better. Tell us your
                name, company, and where you’re based.
              </Subtitlemobile>
              
              <Field
                name="firstName"
                type="text"
                placeholder="First Name"
                as={StyledInput}
              />
              <ErrorMessage name="firstName" component={StyledErrorMessage} />

              <Field
                name="lastName"
                type="text"
                placeholder="Last Name"
                as={StyledInput}
              />
              <ErrorMessage name="lastName" component={StyledErrorMessage} />

              <Field
                name="name"
                type="text"
                placeholder="Company Name"
                as={StyledInput}
              />
              <ErrorMessage name="name" component={StyledErrorMessage} />

              <Field
                name="country"
                component={SelectCountry}
                menuPlacement="top"
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                touched={touched}
                errors={errors}
                onCountryChange={handleCountryChange}
              />
              <StyledErrorMessage>
                {touched.country && errors.country && errors.country}
              </StyledErrorMessage>

              <Subtitlemobilebottom>
                Dinabite is your personal assistant for growing your online
                presence with powerful analytics and easy post scheduling.
                <br />
                Ready to rock?
              </Subtitlemobilebottom>
              
              <DesktopBackButtonContainer>
                <NextButtonContainer>
                  <Button
                    type="submit"
                    disabled={!isValid || !dirty || isLoading}
                  >
                    {isLoading ? 'Signing Up...' : 'Next Step'}
                  </Button>
                </NextButtonContainer>
                <BackCancelContainer>
                  {loginProvider.data?.email ? (
                    <DesktopBackButton onClick={onCancel}>
                      Back
                    </DesktopBackButton>
                  ) : (
                    <DesktopBackButton onClick={onBack}>Back</DesktopBackButton>
                  )}
                </BackCancelContainer>
              </DesktopBackButtonContainer>
            </ContentContainer>

            <BackButtondiv>
              {loginProvider.data?.email ? (
                <BackButton onClick={onCancel}>Cancel</BackButton>
              ) : (
                <BackButton onClick={onBack}>Back</BackButton>
              )}
              <BackButtonnext type="submit" disabled={!isValid || !dirty || isLoading}>
                {isLoading ? 'Signing Up...' : 'Next Step'}
              </BackButtonnext>
            </BackButtondiv>
          </Form>

          {isLoading && (
            <FlexboxGrid
              justify="center"
              align="middle"
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
              }}>
              <FlexboxGrid.Item>
                <Loader size="lg" content="Signing Up..." />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          )}
        </>
      )}
    </Formik>  );
};

export default Step2;
