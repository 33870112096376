import styled from 'styled-components';

export const Frame = styled.div`
  width: 400px;
  height: 685px;
  border-radius: 20px 20px 20px 20px;
  position: relative; /* Stack order: below the Rectangle */
  z-index: 1; /* Ensure Frame is below Rectangle */
  cursor: default;

  h2 {
    text-transform: uppercase;
    text-align: center;
    color: ${props => props.theme.colors.crimson};
    font-weight: bold;
    margin-top: 35px;
    font-family: 'Poppins';
    font-weight: 600;
  }
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(180deg, #d9d9d9, rgba(217, 217, 217, 0));

  @media ${props => props.theme.breakpoints.xs} {
    width: 90%;
    height: 735px;
  }
`;

export const FrameTest = styled.div`
  width: 300px;
  height: 500px;
  border-radius: 20px 20px 20px 20px;
  position: relative;
  z-index: -1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to bottom, darkblue, darkorchid) border-box;
  border: 4px solid transparent;
`;

export const FrameBehind = styled.div`
  width: 350px;
  height: 555px;
  background: linear-gradient(180deg, #db1d5a, #ea4435);
  border-radius: 20px 20px 20px 20px;
  position: absolute; /* Use absolute positioning */
  top: calc(33% - 108px);
  z-index: 0; /* Lower than Frame's z-index */
  @media ${props => props.theme.breakpoints.xs} {
    width: 80%;
    height: 613px;
    top: calc(33% - 130px);
  }
`;

export const FrameBehindBorder = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background: linear-gradient(to right, #db1d5a 30%, transparent 30%);
    z-index: -1;
    border-radius: inherit;
    box-shadow: 0 0 0 10px inset;
  }
`;

export const FrameFront = styled.div`
  width: 300px;
  height: 508px;
  background-color: rgba(255, 255, 255, 0.66);
  box-shadow: -8px 8px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px 10px 10px 10px;
  position: absolute; /* Use absolute positioning */
  top: calc(30% - 68px);
  z-index: 1; /* Lower than Frame's z-index */
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: ${props => props.theme.fonts.subtitle};
    color: ${props => props.theme.colors.crimson};
    margin-bottom: 8px;
  }
  @media ${props => props.theme.breakpoints.xs} {
    width: 68%;
    top: calc(30% - 100px);
    height: 582px;
  }
`;

export const CircleWithTick = styled.div`
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid ${props => props.theme.colors.crimson};

  &::after {
    content: '';
    position: absolute;
    width: 7px;
    height: 12.5px;
    border-bottom: 2px solid ${props => props.theme.colors.crimson}; /* Adjusted thickness of the bottom part of the tick */
    border-right: 2px solid ${props => props.theme.colors.crimson}; /* Adjusted thickness of the right part of the tick */
    transform: rotate(45deg);
    bottom: 7.5px; /* Adjusted positioning from the bottom */
    left: 9px; /* Adjusted positioning from the left */
  }
`;

export const TriangleLeft = styled.div`
  position: absolute; /* Positioning it absolutely */
  left: 0; /* Align to the left edge of TriangleContainer */
  width: 27px; /* Adjusted to make the triangle smaller */
  height: 27px; /* Adjusted to make the triangle smaller */
  background-color: #db1d5a;
  clip-path: polygon(100% 100%, 0% 100%, 100% 0);
  justify-content: flex-start;
  z-index: 1; /* Higher than Frame's z-index */
  top: 86px;
`;

export const TriangleRight = styled.div`
  position: absolute; /* Positioning it absolutely */
  right: 0; /* Align to the right edge of TriangleContainer */
  width: 27px; /* Adjusted to make the triangle smaller */
  height: 27px; /* Adjusted to make the triangle smaller */
  background-color: #db1d5a;
  clip-path: polygon(0 100%, 100% 100%, 0 0);
  z-index: 1; /* Higher than Frame's z-index */
  top: 86px;
`;

export const TriangleContainer = styled.div`
  width: 450px;
  position: relative; /* Add this to position the triangles absolutely within this container */
  height: 20px;
  display: flex;
  @media ${props => props.theme.breakpoints.xs} {
    width: 100%;
  }
`;

export const Rectangle = styled.div`
  border-radius: 0 0 10px 10px;
  width: 450px;
  background: linear-gradient(180deg, #db1d5a, #ea4435);
  position: absolute;
  top: calc(29% - 91px);
  z-index: 2;
  cursor: default;
  h3 {
    text-align: center;
    color: white;
  }
  @media ${props => props.theme.breakpoints.xs} {
    width: 100%;
    top: calc(29% - 106px);
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center; /* Align the tick and text vertically */
  gap: 2px; /* Space between the tick and the text */
  padding: 0 10px 10px 10px;
  img {
    align-self: flex-start; /* Align the image to the top of the FlexContainer */
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 55px;
  margin-bottom: 20px;

  @media ${props => props.theme.breakpoints.xs} {
    margin-top: 30px;
  }

  @media ${props => props.theme.breakpoints.xss} {
    margin-top: 5px;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: default;

  @media ${props => props.theme.breakpoints.xs} {
    margin-top: 20px;
  }
`;
