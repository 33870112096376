import FacebookIcon from '../../assets/icons/facebook.svg';
import GoogleIcon from '../../assets/icons/google.svg';
import InstagramIcon from '../../assets/icons/instagram.png';
import TwitterIcon from '../../assets/icons/x.svg';
import VideoIcon from '../../assets/icons/video.svg';
import DefaultImage from '../../assets/images/default.jpg';
import { formatDate } from '../../lib/format';
import {
  Container,
  ContentContainer,
  DateContainer,
  ImageContainer,
  ImageTextContainer,
  NetworkIcon,
  ReelDate,
  ReelImage,
  ReelText,
  TextContainer,
  VideoImage,
} from './Reel.styles';

interface Props {
  reel: Reel;
  setDataReelSelected?: React.Dispatch<React.SetStateAction<Reel | null>>;
}

const iconMapper = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  google: GoogleIcon,
};

const Reel = ({ reel, setDataReelSelected }: Props) => {
  const handleClickDescription = (clickedReel: Reel) =>
    setDataReelSelected?.(clickedReel);

  return (
    <Container>
      <NetworkIcon src={iconMapper[reel.network]} alt="Network" />
      <ContentContainer>
        <ImageTextContainer>
          {reel?.media.image ? (
            <ImageContainer onClick={() => handleClickDescription(reel)}>
              <ReelImage src={reel.media.image} alt="Reel" />
              {reel.media.type === 'reel' && (
                <VideoImage
                  src={VideoIcon}
                  alt="reel"
                  style={{ position: 'absolute' }}
                />
              )}
            </ImageContainer>
          ) : (
            <ReelImage
              src={DefaultImage}
              alt="Reel"
              onClick={() => handleClickDescription(reel)}
            />
          )}
          <TextContainer>
            <ReelText>{reel.text}</ReelText>
          </TextContainer>
        </ImageTextContainer>
        <DateContainer>
          <ReelDate>{formatDate(reel.publishedAt)}</ReelDate>
        </DateContainer>
      </ContentContainer>
    </Container>
  );
};

export default Reel;
