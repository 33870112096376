/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Col, FlexboxGrid } from 'rsuite';
import FacebookIcon from '../../assets/icons/facebook.svg';
import GoogleIcon from '../../assets/icons/google.svg';
import InstagramIcon from '../../assets/icons/instagram.png';
import TwitterIcon from '../../assets/icons/x.svg';
import { formatDate } from '../../lib/format';
import { MEDIA_TYPE } from '../../lib/globalValues';
import {
  ButtonSpaces,
  Container,
  ImageContainer,
  NetworkIcon,
  PostDate,
  PostImage,
  PostText,
  PostVideo,
  TextContainer,
} from './ScheduledPost.styles';

interface Props {
  post: ScheduledPosts;
  handleRemoveScheduledPost: (scheduledPostId: number) => void;
}

const iconMapper = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  google: GoogleIcon,
};

const ScheduledPost = ({ post, handleRemoveScheduledPost }: Props) => {
  return (
    <Container align="middle">
      <FlexboxGrid.Item componentClass={Col} xs={12} md={5}>
        {React.Children.toArray(
          post?.socialNetworks?.map(network => (
            <NetworkIcon src={iconMapper[network]} alt="Network" />
          )),
        )}
      </FlexboxGrid.Item>

      <FlexboxGrid.Item componentClass={Col} xs={12} md={5}>
        <ImageContainer>
          {post.media.type === MEDIA_TYPE.VIDEO ? (
            <PostVideo src={post.media.url} />
          ) : (
            <PostImage src={post.media.url} alt="Post" />
          )}
        </ImageContainer>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item componentClass={Col} xs={24} md={5}>
        <TextContainer>
          <PostText>{post.title}</PostText>
        </TextContainer>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item componentClass={Col} xs={12} md={6}>
        <PostDate>{formatDate(post.postedAt)}</PostDate>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item componentClass={Col} xs={12} md={3}>
        <ButtonSpaces
          type="button"
          onClick={() => handleRemoveScheduledPost(post.id)}>
          Cancel
        </ButtonSpaces>
      </FlexboxGrid.Item>
    </Container>
  );
};

export default ScheduledPost;
