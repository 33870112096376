import { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { Loader } from 'rsuite';
import axios from '../../lib/axios';
import Message from '../message/Message';

const fetchMessages = async (): Promise<TMessage[]> => {
  try {
    const { data } = await axios.get(`/tools/messages`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

const Messages = () => {
  const {
    isLoading,
    isError,
    isFetching,
    refetch,
    data: messages,
  } = useQuery(`MESSAGES`, fetchMessages, {
    staleTime: 10 * 60 * 1000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
    retry: false,
  });

  const handleUpdate = () => {
    /**
     * Perform any necessary updates to the data
     * & Refetch the messages
     *  */
    refetch();
  };

  // used to keep a check of initial component render
  const initialData = useRef(messages);

  useEffect(() => {
    initialData.current = messages;
  }, [messages]);

  useEffect(() => {
    refetch();
  }, [messages]);

  if (!initialData.current && (isLoading || isFetching)) {
    return <Loader content="loading" />;
  }

  setTimeout(refetch, 600 * 1000);

  return (
    <div>
      {!isError &&
        messages?.map(message => (
          <Message
            handleUpdate={handleUpdate}
            key={message.senderId}
            messages={message}
          />
        ))}
    </div>
  );
};

export default Messages;
