import DefaultImage from '../assets/images/default.jpg';

export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'GBP':
      return '£';
    default:
      return '$';
  }
};

export const calculateSpending = (totalBudget: number, budgetLeft: number) => {
  return ((totalBudget - budgetLeft) * 100) / totalBudget;
};

export const stringToFloat = (value: string) => parseFloat(value) / 100;

export const formatFloat = (value: number) => value.toFixed(2);

export const getImage = (imageUrl: string | undefined) => {
  if (imageUrl) {
    return imageUrl;
  }
  return DefaultImage;
};

export const formatNumber = (value: number | string, decimalNumbers = 0) => {
  if (typeof value === 'number') {
    return new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: decimalNumbers,
      maximumFractionDigits: decimalNumbers,
    }).format(value);
  }

  return new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: decimalNumbers,
    maximumFractionDigits: decimalNumbers,
  }).format(parseFloat(value));
};

export const capitalize = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const percentageText = (
  oldNumber: number,
  number: number,
  type: 'negative' | 'positive' | 'neutral',
) => {
  if (oldNumber === 0 && type === 'positive') return 'N/A';
  if (type === 'negative') return `-${number}%`;
  if (type === 'positive') return `+${number}%`;

  return `${number}%`;
};
