import { useState } from 'react';
import useCountDown from 'react-countdown-hook';
import { useIdleTimer } from 'react-idle-timer';
import {
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { Col, FlexboxGrid } from 'rsuite';
import CloseMenu from '../../assets/icons/closeMenu.svg';
import IconMenu from '../../assets/icons/iconMenu.svg';
import logo from '../../assets/images/logo.svg';
import AppRoute from '../../components/AppRoute';
import IdleModal from '../../components/modals/idleModal/IdleModal';
import routes from '../../config/userRoutes';
import useUser from '../../hooks/useUser';
import NavUser from '../../layout/nav/NavUser';
import { useMobileStore } from '../../store';
import {
  CenterFlex,
  DividerSpace,
  FullDivider,
  HeaderStyled,
  ImgLogo,
  MenuButton,
  OptionsHeader,
  OptionsMobile,
  SpaceMenu,
  Title,
  Wrapper,
} from './UserPanel.styles.';

const UserPanel = () => {
  const idleTime = 15 * 60 * 1000;
  const logoutTime = 60 * 1000;
  const { handleLogout } = useUser();
  const history = useHistory();
  const { showMobileMenu, toggleMobileMenu } = useMobileStore(state => state);

  const [idle, setIdle] = useState(false);
  const [timeLeft, { start: startCountDown, reset: resetCountDown }] =
    useCountDown(logoutTime);

  if (idle && timeLeft === 0) {
    handleLogout();
    history.push('/');
  }

  const handleOnIdle = () => {
    startCountDown();
    setIdle(true);
    pause();
  };

  const handleOnAction = () => {
    resetCountDown();
    setIdle(false);
    reset();
  };

  const { pause, reset } = useIdleTimer({
    timeout: idleTime,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
  });

  const { url } = useRouteMatch();
  const location = useLocation();
  const path = location.pathname.split('/');

  return (
    <>
      <IdleModal show={idle} onClose={handleOnAction} />
      <>
        <HeaderStyled align="middle">
          <CenterFlex componentClass={Col} xs={8} md={4}>
            <ImgLogo src={logo} alt="dinabite logo" />
          </CenterFlex>
          <OptionsHeader componentClass={Col} xs={16} md={20}>
            <FlexboxGrid justify="space-around" align="middle">
              <FlexboxGrid.Item componentClass={Col} xs={13} xsHidden smHidden>
                <Title>{path[2]}</Title>
              </FlexboxGrid.Item>

              <FlexboxGrid.Item componentClass={Col} xs={24} md={8}>
                <FlexboxGrid justify="end">
                  <CenterFlex
                    componentClass={Col}
                    xs={8}
                    md={6}
                    lgHidden
                    mdHidden>
                    <MenuButton
                      onClick={toggleMobileMenu}
                      icon={
                        showMobileMenu ? (
                          <img src={CloseMenu} alt="close menu" />
                        ) : (
                          <img src={IconMenu} alt="open menu" />
                        )
                      }
                      circle
                      componentClass="div"
                    />
                  </CenterFlex>
                </FlexboxGrid>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </OptionsHeader>
          <OptionsMobile
            display={showMobileMenu ? 1 : 0}
            componentClass={Col}
            xs={24}
            lgHidden
            mdHidden>
            <FullDivider />
            <NavUser />
          </OptionsMobile>
        </HeaderStyled>

        <FlexboxGrid>
          <SpaceMenu componentClass={Col} md={4} xsHidden smHidden>
            <DividerSpace />
            <NavUser />
          </SpaceMenu>
          <Wrapper componentClass={Col} xs={24} md={20}>
            <DividerSpace componentClass={Col} xsHidden smHidden />
            <Switch>
              {routes.map(route => (
                <AppRoute
                  key={`${url}${route.path}`}
                  path={`${url}${route.path}`}
                  component={route.component}
                  isPrivate={route.isPrivate}
                  exact={route.exact}
                />
              ))}
            </Switch>
          </Wrapper>
        </FlexboxGrid>
      </>
    </>
  );
};

export default UserPanel;
