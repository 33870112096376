import { NavLink } from 'react-router-dom';
import { Nav, Sidenav } from 'rsuite';
import styled from 'styled-components';

export const SidenavStyled = styled(Sidenav)`
  background-color: ${props => props.theme.colors.white};
  @media ${props => props.theme.breakpoints.md},
    ${props => props.theme.breakpoints.lg} {
    padding-left: 20px;
  }
  @media ${props => props.theme.breakpoints.sm},
    ${props => props.theme.breakpoints.md} {
    padding-left: 20px;
  }
`;

export const Menu = styled(Nav.Item)`
  @supports (
    (-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))
  ) {
    overflow: visible !important;
  }
`;

export const StyledNavLink = styled(NavLink)`
  &.${props => props.activeClassName} {
    color: ${props => props.theme.colors.red};
    font-weight: bold;
    svg g line,
    path,
    rect,
    circle {
      stroke: ${props => props.theme.colors.red};
    }

    @media ${props => props.theme.breakpoints.md},
      ${props => props.theme.breakpoints.lg} {
      &:after {
        content: '';
        position: absolute;
        width: 32%;
        height: 100%;
        background-color: ${props => props.theme.colors.red};
        border-radius: 100px;
        right: -13%;
        bottom: 0;
      }
    }
  }
`;

export const DividerSpace = styled.div`
  height: 1px;
  background-color: ${props => props.theme.colors.grey};

  @media ${props => props.theme.breakpoints.md},
    ${props => props.theme.breakpoints.lg} {
    margin: 10px 10% 10px;
  }
`;
