import axios from '../lib/axios';

export const findUser = async (email: string) => {
  try {
    const response = await axios.post('/users/find', { email });
    return response;
  } catch (error) {
    return error;
  }
};
