import axios from '../lib/axios';
import { substractDateInMs } from '../lib/format';

export const getReviewsStatistics = async ({
  dateRange,
}: {
  dateRange: string;
}) => {
  const dateMs = substractDateInMs(dateRange);
  try {
    const { data } = await axios.get(
      `/tools/statistics/reviews?date=${dateMs}`,
    );

    return data;
  } catch (error) {
    return error;
  }
};
