import React from "react";
import { useHistory } from 'react-router-dom';

import {
  Title,
  Subtitle,
  Button,
  DesktopBackButtonContainer,
  NextButtonContainer,
  BackCancelContainer,
  // DesktopBackButton,
  BackButtondiv,
  // BackButton,
  BackButtonnext,
  ContentContainer
} from "./step4.styes";

// interface Step4Props {
//   onNext: (values: any) => void;
//   onBack: () => void;
//   onCancel: () => void;
// }

// const Step4: React.FC<Step4Props> = ({ onNext, onBack, onCancel }: Step4Props) => {
const Step4 =()=> {
  const history = useHistory();

  const onSubmit = () => {
    // console.log("step 4 completed and going to dashboard ")
    // const token = localStorage.getItem('TOKEN');
    // const userId = localStorage.getItem('USER_ID');

    // Log the retrieved values to the console
    // console.log("Step 4 completed and going to dashboard.");
    // console.log("TOKEN:", token);
    // console.log("USER_ID:", userId);
    // Navigate directly to the dashboard and prevent any further rendering of this component
    history.push('/user-panel/dashboard');
  };

  return (
    <>
      <ContentContainer>

      <Title>Welcome aboard!</Title>
      <Subtitle>
        You are now ready to explore Dinabite and take your social media game to the next level. Let&apos;s start this exciting journey together.
      </Subtitle>

      <DesktopBackButtonContainer>
        <NextButtonContainer>
          <Button onClick={onSubmit}>Go to Dashboard</Button>
        </NextButtonContainer>
        <BackCancelContainer>
          {/* <DesktopBackButton onClick={onCancel}>Cancel</DesktopBackButton> */}
        </BackCancelContainer>
      </DesktopBackButtonContainer>
      <BackButtondiv>
        {/* <BackButton onClick={onCancel}>Cancel</BackButton> */}
        <BackButtonnext onClick={onSubmit}>Go to Dashboard</BackButtonnext>
      </BackButtondiv>
    </ContentContainer>
    </>
  );
};

export default Step4;
