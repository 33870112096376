/* eslint-disable @typescript-eslint/no-explicit-any */
import { TYPES_TOOLS } from '../actions/toolsActions';
import { TYPES_OF_SOCIAL_NETWORKS } from '../lib/globalValues';

export const initialToolsState = {
  [TYPES_OF_SOCIAL_NETWORKS.FACEBOOK]: null,
  [TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM]: null,
  [TYPES_OF_SOCIAL_NETWORKS.GOOGLE]: null,
  [TYPES_OF_SOCIAL_NETWORKS.TWITTER]: null,
  [TYPES_OF_SOCIAL_NETWORKS.TIKTOK]: null,
  // [TYPES_OF_SOCIAL_NETWORKS.UBER_EATS]: null,
  [TYPES_OF_SOCIAL_NETWORKS.TRIPADVISOR]: null,
  [TYPES_OF_SOCIAL_NETWORKS.JUST_EAT]: null,
};

export function toolsReducer(state: any, action: any) {
  switch (action.type) {
    case TYPES_TOOLS.ACTIVATE_FACEBOOK:
      return { ...state, [TYPES_OF_SOCIAL_NETWORKS.FACEBOOK]: action.payload };

    case TYPES_TOOLS.DEACTIVATE_FACEBOOK:
      return {
        ...state,
        [TYPES_OF_SOCIAL_NETWORKS.FACEBOOK]: {
          ...state[TYPES_OF_SOCIAL_NETWORKS.FACEBOOK],
          enabled: false,
        },
      };

    case TYPES_TOOLS.CONNECT_AD_ACCOUNT: {
      return {
        ...state,
        [TYPES_OF_SOCIAL_NETWORKS.FACEBOOK]: {
          ...state[TYPES_OF_SOCIAL_NETWORKS.FACEBOOK],
          adAccountId: action.payload.adAccountId,
        },
      };
    }

    case TYPES_TOOLS.ACTIVATE_GOOGLE:
      return { ...state, [TYPES_OF_SOCIAL_NETWORKS.GOOGLE]: action.payload };

    case TYPES_TOOLS.DEACTIVATE_GOOGLE:
      return {
        ...state,
        [TYPES_OF_SOCIAL_NETWORKS.GOOGLE]: {
          ...state[TYPES_OF_SOCIAL_NETWORKS.GOOGLE],
          enabled: false,
        },
      };

    case TYPES_TOOLS.ACTIVATE_INSTAGRAM:
      return { ...state, [TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM]: action.payload };

    case TYPES_TOOLS.DEACTIVATE_INSTAGRAM:
      return {
        ...state,
        [TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM]: {
          ...state[TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM],
          enabled: false,
        },
      };

    case TYPES_TOOLS.ACTIVATE_TWITTER:
      return { ...state, [TYPES_OF_SOCIAL_NETWORKS.TWITTER]: action.payload };

    case TYPES_TOOLS.DEACTIVATE_TWITTER:
      return {
        ...state,
        [TYPES_OF_SOCIAL_NETWORKS.TWITTER]: {
          ...state[TYPES_OF_SOCIAL_NETWORKS.TWITTER],
          enabled: false,
        },
      };

    // case TYPES_TOOLS.ACTIVATE_UBER_EATS:
    //   return { ...state, [TYPES_OF_SOCIAL_NETWORKS.UBER_EATS]: action.payload };

    // case TYPES_TOOLS.DEACTIVATE_UBER_EATS:
    //   return {
    //     ...state,
    //     [TYPES_OF_SOCIAL_NETWORKS.UBER_EATS]: {
    //       ...state[TYPES_OF_SOCIAL_NETWORKS.UBER_EATS],
    //       enabled: false,
    //     },
    //   };

    case TYPES_TOOLS.INIT_TOOLS:
      return { ...state, ...action.payload };

    case TYPES_TOOLS.RESET_TOOLS:
      return initialToolsState;
    default:
      return state;
  }
}
