import { Button } from 'rsuite';
import styled from 'styled-components';

export const Container = styled(Button)`
  background-color: ${props => props.theme.colors.crimson}; // #DC2058;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32);
  width: 168px;
  height: 43px;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  &:disabled {
    background-color: ${props => props.theme.colors.grey} !important;
    color: ${props => props.theme.colors.darkGrey};
  }
`;
