/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactNode } from 'react';
import { Col, FlexboxGrid, Row } from 'rsuite';
import { selectImgStatus } from '../../../lib/toogleImgStatus';
import {
  CardTool,
  //  Paragraph,
  ParagraphColor,
  ParagraphColor2,
  SocialNetworkIcon,
  TooltipWrapper,
  TooltipText,
} from './toolToogleCard.styles';

interface Props {
  signup?: boolean;
  children: ReactNode;
  type: string;
  content: any;
  noText?: boolean;
}

const ToolToogleCard: React.FunctionComponent<Props> = ({
  signup = false, // Default value added
  children,
  type,
  content,
  noText = false,
}: Props) => {
  const flexboxGridStyle = {
    // display: 'flex',
    justifyContent: signup ? 'center' : 'space-between',
  };

  return (
    <CardTool align="middle" signup={signup}>
      <FlexboxGrid.Item componentClass={Col} xs={5} lg={2}>
        <SocialNetworkIcon
          src={selectImgStatus(type, content?.enabled).image}
          alt="Social Network Icon"
          signup={signup} // Pass the signup prop here
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item componentClass={Col} xs={8} lg={14}>
        <FlexboxGrid style={flexboxGridStyle}>
          <Row>
            {/* <Col xs={24}>
              <Paragraph>{type}</Paragraph>
            </Col> */}
            <Col xs={24}>
              <span hidden={!noText}>
                {signup ? null : content?.enabled ? (
                  <ParagraphColor2 status={1}>
                    {content.pageName}
                  </ParagraphColor2>
                ) : selectImgStatus(type, content?.enabled).disabled ? (
                  <ParagraphColor>Coming Soon</ParagraphColor>
                ) : (
                  <ParagraphColor status={0} signup={signup}>
                    Disconnected
                  </ParagraphColor>
                )}
              </span>
            </Col>
          </Row>
        </FlexboxGrid>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item componentClass={Col} xs={10} lg={8}>
        {children}
      </FlexboxGrid.Item>
    </CardTool>
  );
};

export default ToolToogleCard;

// {content?.enabled ? (
//   <ParagraphColor2 status={1}>
//       {content.pageName}
//     </ParagraphColor2>
  // <TooltipWrapper>
  //   <ParagraphColor2 status={1}>
  //     {content.pageName}
  //   </ParagraphColor2>
  //   <TooltipText>{content.pageName}</TooltipText>
  // </TooltipWrapper>